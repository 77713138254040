import { New, Promotion, PROMOTIONS_COLLECTION, Update } from "@vaultinum/vaultinum-api";
import {
    collection,
    DataEventSource,
    doc,
    DocumentData,
    DocumentSnapshot,
    doPost,
    doPut,
    getDoc,
    getFirestore,
    onSnapshot,
    Timestamp
} from "@vaultinum/vaultinum-sdk";
import { doDelete } from "../services/apiService";

export const PROMOTION_ENDPOINT = "promotion";

type DatabaseDate = { creationDate: Timestamp; startDate: Timestamp; endDate: Timestamp };

const convertDocToPromotion = (document: DocumentSnapshot<DocumentData>): Promotion => {
    const data = document.data() as Omit<Promotion, "id" | "creationDate" | "startDate" | "endDate"> & DatabaseDate;
    return {
        ...data,
        id: document.id,
        creationDate: data.creationDate.toDate(),
        startDate: data.startDate.toDate(),
        endDate: data.endDate.toDate(),
        emails: Object.fromEntries(
            Object.entries(data.emails).map(([key, value]) => [
                key,
                // To avoid double caste, we need to create a method to convert all timestamp from firebase object to Date
                { ...value, joinDate: value.joinDate ? (value.joinDate as unknown as Timestamp).toDate() : undefined }
            ])
        )
    };
};

const promotionListener = new DataEventSource<Promotion[]>(onUpdate =>
    onSnapshot(collection(getFirestore(), PROMOTIONS_COLLECTION), querySnapshot => onUpdate(querySnapshot.docs.map(convertDocToPromotion)))
);

export async function getPromotion(promotionId: string): Promise<Promotion> {
    const promotionDoc = await getDoc(doc(collection(getFirestore(), PROMOTIONS_COLLECTION), promotionId));
    return convertDocToPromotion(promotionDoc);
}

export function getPromotions(onUpdate: (promotions: Promotion[]) => void): () => void {
    return promotionListener.addListener(onUpdate);
}

export function createPromotion(promotion: New<Promotion>): Promise<string> {
    return doPost(PROMOTION_ENDPOINT, promotion, process.env.REACT_APP_API_HOST);
}

export function updatePromotion(promotionId: string, promotion: Update<Promotion>): Promise<string> {
    return doPut(`${PROMOTION_ENDPOINT}/${promotionId}`, promotion, process.env.REACT_APP_API_HOST);
}

export function deletePromotion(promotionId: string): Promise<string> {
    return doDelete(`${PROMOTION_ENDPOINT}/${promotionId}`, process.env.REACT_APP_API_HOST);
}

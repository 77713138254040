import { json, jsonLanguage, jsonParseLinter } from "@codemirror/lang-json";
import { bracketMatching, indentOnInput } from "@codemirror/language";
import { lintGutter, linter } from "@codemirror/lint";
import { ViewUpdate, hoverTooltip } from "@codemirror/view";
import CodeMirror, { drawSelection, gutter, highlightActiveLineGutter, lineNumbers } from "@uiw/react-codemirror";
import { handleRefresh, jsonCompletion, jsonSchemaHover, jsonSchemaLinter, stateExtensions } from "codemirror-json-schema";
import { JSONSchema7 } from "json-schema";

export function JsonEditor({
    value,
    schema,
    onChange,
    onUpdate,
    isLight,
    isLoading,
    isDisabled
}: {
    value: string;
    schema: JSONSchema7;
    onChange?: (val: string) => void;
    onUpdate?: (viewUpdate: ViewUpdate) => void;
    isLight?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
}): JSX.Element {
    return (
        <CodeMirror
            value={value}
            height="65vh"
            readOnly={isLoading}
            editable={!isDisabled}
            onChange={onChange}
            onUpdate={onUpdate}
            extensions={[
                json(),
                lineNumbers(),
                stateExtensions(schema),
                ...(!isLight
                    ? [
                          gutter({ class: "CodeMirror-lint-markers" }),
                          bracketMatching(),
                          highlightActiveLineGutter(),
                          linter(jsonParseLinter()),
                          linter(jsonSchemaLinter(), {
                              needsRefresh: handleRefresh
                          }),
                          jsonLanguage.data.of({
                              autocomplete: jsonCompletion()
                          }),
                          hoverTooltip(jsonSchemaHover()),
                          lintGutter(),
                          indentOnInput(),
                          drawSelection()
                      ]
                    : [])
            ]}
        />
    );
}

import { Event, FullAudit } from "@vaultinum/vaultinum-api";
import { Spin, TableStorageKey } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { getTagLevel, getUpperCaseTag } from "../../../common/TagTools";
import { getFullAuditEvents } from "../../../services/fullAuditService";
import { StandardModelListView } from "../components";

function MetaDataList({ keys, metaData }: { keys: string[]; metaData: Record<string, unknown> }): JSX.Element {
    return (
        <ul>
            {keys.map(key => (
                <li key={key}>
                    {key} → {JSON.stringify(metaData[key])}
                </li>
            ))}
        </ul>
    );
}

export function FullAuditEventsList(): JSX.Element {
    const [fullAuditEvents, setFullAuditEvents] = useState<FullAudit.Event[]>();

    useEffect(() => getFullAuditEvents(setFullAuditEvents), []);

    if (!fullAuditEvents) {
        return <Spin />;
    }

    return (
        <StandardModelListView<FullAudit.Event>
            getModelItems={onUpdate => onUpdate(fullAuditEvents || [])}
            name="Events"
            searchOptions={{ hideIgnored: true }}
            creationDateIndex="insertDate"
            columns={[
                {
                    header: "Full Audit",
                    accessorKey: "fullAuditId"
                },
                {
                    header: "Level",
                    accessorKey: "level",
                    cell: cell => getTagLevel(cell.getValue<Event.Level>())
                },
                { header: "Message", accessorKey: "messageKey" },
                {
                    header: "Source",
                    accessorKey: "source",
                    cell: cell => getUpperCaseTag(cell.getValue<FullAudit.Event.Source>())
                },
                {
                    header: "Metadata",
                    enableColumnFilter: false,
                    accessorFn: row => Object.keys(row?.metaData).sort((a, b) => a.localeCompare(b)),
                    cell: ({ getValue, row }) => {
                        return <MetaDataList keys={getValue<string[]>()} metaData={row.original.metaData} />;
                    }
                }
            ]}
            actionColumnSize={130}
            storageKey={TableStorageKey.FULL_AUDIT_EVENT}
        />
    );
}

import { SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { LANGS } from "../constants";

export default function Flag({ countryCode }: { countryCode?: SupportedLanguageCode }) {
    if (countryCode) {
        return (
            <div
                title={LANGS.find(lang => countryCode === lang.code)?.name}
                className="mr-2 h-3 w-4 bg-cover bg-no-repeat"
                style={{ backgroundImage: `url("/img/flags/${countryCode}.svg")` }}
            />
        );
    }
    return null;
}

import { Account, Deposit, DepositStore } from "@vaultinum/vaultinum-api";

export function filterDepositsWithoutEscrow(deposits: Deposit[], depositStores: DepositStore[]): Deposit[] {
    const depositStoreIdsWithoutEscrow = depositStores.filter(depositStore => !depositStore.escrowId).map(depositStore => depositStore.id);
    return deposits.filter(deposit => depositStoreIdsWithoutEscrow.includes(deposit.depositStoreId));
}

export function isAccountValidForRightsTransfer(account?: Account) {
    return (
        account &&
        account.companyRegistrationNumber &&
        account.companyNationality &&
        account.companyName &&
        account.companyAddress &&
        account.companyAddress.country &&
        account.companyAddress.city &&
        account.companyAddress.line1
    );
}

import { EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import { Alert, BaseLang, Controller, Form, Input, Modal, useForm, useLang, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import * as EmailValidator from "email-validator";
import { useState } from "react";
import { addStaffUser, getStaffUserByEmail } from "../../../services/staffUserService";

export default function AddStaffUserModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
    const lang = useLang<BaseLang>();
    const [error, setError] = useState("");
    const [working, setWorking] = useState(false);

    const schema = yup.object({
        email: useRequiredString().trim().matches(EMAIL_REGEX, "Invalid email address")
    });

    const { handleSubmit, control, watch, formState } = useForm<{ email: string }>({
        schema,
        mode: "onChange"
    });

    const doAddUser = async () => {
        const { email } = watch();
        if (EmailValidator.validate(email)) {
            setWorking(true);
            try {
                if (await getStaffUserByEmail(email)) {
                    throw new Error("This email already exists");
                }
                await addStaffUser(email);
                setError("");
                onClose();
            } finally {
                setWorking(false);
            }
        } else {
            setError("Invalid email");
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={handleSubmit(doAddUser)}
            title="Invite staff user"
            okText="Add User"
            isLoading={working}
            lang={lang}
            isDisabled={!formState.isValid}
        >
            <Form onSubmit={handleSubmit(doAddUser)}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <Input.Email
                            {...field}
                            label="Email"
                            placeholder="Email..."
                            disabled={working}
                            errorMessage={formState.errors.email?.message}
                            required
                        />
                    )}
                />
            </Form>
            {error && <Alert.Danger message={error} />}
        </Modal>
    );
}

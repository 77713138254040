import { LANG_EN, Survey } from "@vaultinum/vaultinum-api";
import { Color, formatDateFromNow, RowCard } from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { groupBy, isObject, isString, partition } from "lodash";
import { useState } from "react";
import { Flag, StaffUserName, StringDiffDisplay } from "../../../../components";
import { CHANGE_SIZE_LIMIT, OPERATION_TEXT, OPERATIONS_TO_GROUP } from "../../../../constants";

function isArray<T>(value: string | T[]): value is T[] {
    return isString(value) ? value.startsWith("[") : Array.isArray(value);
}

function valueIsValid(value?: unknown): value is string | number | object {
    return (value !== "" && value !== undefined) || (typeof value === "object" && !!Object.keys(value).length);
}

function canValueBeDisplayed(value: unknown): boolean {
    if (!valueIsValid(value)) {
        return false;
    }
    if (isString(value)) {
        return value.length <= CHANGE_SIZE_LIMIT;
    }
    return JSON.stringify(value).length <= CHANGE_SIZE_LIMIT;
}

function toSingular(value = ""): string {
    if (value.endsWith("s")) {
        return value.slice(0, -1);
    }
    return value;
}

function getIndexFromPath(path: string): string | undefined {
    return path
        .match(/\d/g)
        ?.map(index => Number(index) + 1)
        .join(".");
}

function geNodeTypeFromPath(path: string): string | undefined {
    if (path === "/") {
        return "language";
    } else {
        const words = path.split("/").filter(word => /^[a-zA-Z]+$/.test(word));
        return words?.pop();
    }
}

function getOpBorderColor(op: string): Color | undefined {
    switch (op) {
        case Survey.Version.Revision.Operation.Op.add:
            return "green";
        case Survey.Version.Revision.Operation.Op.replace:
            return "orange";
        case Survey.Version.Revision.Operation.Op.remove:
            return "red";
        default:
            return undefined;
    }
}

function RevisionValue({
    newValue,
    previousValue,
    onClick
}: {
    newValue: string | number | Record<string, unknown>;
    previousValue?: string;
    onClick?: () => void;
}): JSX.Element {
    return (
        <div className={classNames({ "cursor-pointer": !!onClick })} onClick={onClick} title={onClick ? "Hide change" : undefined}>
            {isString(newValue) && newValue ? (
                <StringDiffDisplay value={previousValue || ""} other={newValue} useWebWorker />
            ) : (
                <span>{JSON.stringify(newValue)}</span>
            )}
        </div>
    );
}

function renderStructureRevision(revision: Survey.Version.Revision, index: number): JSX.Element[] {
    return revision.operations.map((operation, i) => {
        const isWholeStructureValue = operation.path.startsWith("/sections") && isArray(operation.value);
        return (
            <tr key={`${index}${i}`}>
                <td className="flex w-max pr-2">
                    {toSingular(geNodeTypeFromPath(operation.path))} {getIndexFromPath(operation.path)} {OPERATION_TEXT[operation.op]}
                </td>
                <td className="w-full">
                    {!isWholeStructureValue && canValueBeDisplayed(operation.value) && (
                        <RevisionValue newValue={operation.value} previousValue={operation.previousValue} />
                    )}
                </td>
            </tr>
        );
    });
}

function ActionableRevision({ value, previousValue }: { value: string | number | Record<string, unknown>; previousValue?: string }): JSX.Element {
    const [forceDisplay, setForceDisplay] = useState(false);
    function showMore() {
        setForceDisplay(true);
    }

    function hideMore() {
        setForceDisplay(false);
    }

    if (!valueIsValid(value)) {
        return <span className="text-xs text-neutral-400">Empty change</span>;
    }

    if (!canValueBeDisplayed(value) && !forceDisplay) {
        return (
            <span
                onClick={showMore}
                className="cursor-pointer text-xs text-neutral-400 hover:text-neutral-500"
                title={`This change has a length greater than ${CHANGE_SIZE_LIMIT} characters`}
            >
                Show more...
            </span>
        );
    }

    return <RevisionValue newValue={value} previousValue={previousValue} onClick={forceDisplay ? hideMore : undefined} />;
}

function renderLangRevision(revision: Survey.Version.Revision, index: number): JSX.Element[] {
    return (
        Object.entries(groupBy(revision.operations, operation => operation.op)) as [Survey.Version.Revision.Operation.Op, Survey.Version.Revision.Operation[]][]
    ).flatMap(([op, operations], i) => {
        if (OPERATIONS_TO_GROUP.includes(op)) {
            return (
                <tr key={`${index}${i}`}>
                    <td colSpan={2} className="flex w-max items-center">
                        {revision.lang && <Flag countryCode={revision.lang} />}
                        {Object.entries(groupBy(operations, operation => geNodeTypeFromPath(operation.path)))
                            .map(([nodeType, operations]) => `${operations.length} ${operations.length > 1 ? nodeType : toSingular(nodeType)}`)
                            .join(", ")}{" "}
                        {OPERATION_TEXT[op]}
                    </td>
                </tr>
            );
        }
        return operations.map((operation, j) => {
            const [key, value] =
                isObject(operation.value) && Object.keys(operation.value).length <= 1 ? Object.entries(operation.value)?.[0] || [] : ["", operation.value];
            return (
                <tr key={`${index}${i}${j}${key}`}>
                    <td className="flex w-max items-center pr-2">
                        {revision.lang && <Flag countryCode={revision.lang} />}
                        {toSingular(geNodeTypeFromPath(operation.path))} {OPERATION_TEXT[operation.op]}
                        <span>:</span>
                    </td>
                    <td className="w-full">
                        <ActionableRevision value={value} previousValue={operation.previousValue} />
                    </td>
                </tr>
            );
        });
    });
}

export default function SurveyHistoryEvent({ changedDate, revisions }: { changedDate: string; revisions: Survey.Version.Revision[] }): JSX.Element {
    const [structureRevisions, langRevisions] = partition(revisions, revision => revision.type === "version");
    const baseRevision = langRevisions[0] || structureRevisions[0];
    return (
        <RowCard
            color={getOpBorderColor(baseRevision.operations[0]?.op || "")}
            children={
                <div className="flex items-center gap-3">
                    <div className="min-w-fit space-y-1">
                        <StaffUserName uid={baseRevision.changedByUID} />
                        <div className="text-xs text-neutral-400" title={changedDate?.toString()}>
                            {formatDateFromNow(new Date(changedDate), LANG_EN)}
                        </div>
                    </div>
                    <table>
                        <tbody>
                            {structureRevisions.map(renderStructureRevision)}
                            {langRevisions.map(renderLangRevision)}
                        </tbody>
                    </table>
                </div>
            }
        />
    );
}

import { LockIcon } from "@vaultinum/vaultinum-sdk";

export default function UnauthorizedPage() {
    return (
        <div className="flex flex-col items-center justify-center text-center">
            <div className="flex items-center justify-center gap-2 p-4">
                <LockIcon />
                <h2>Restricted access</h2>
            </div>
            <p>Your user does not have the required access to view this page.</p>
        </div>
    );
}

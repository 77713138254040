import { AccountUser, AnalysedCodeRepository, FullAudit, isDefined, StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { CodeAnalysisReport } from "@vaultinum/vaultinum-kys-api";
import {
    BaseLang,
    Column,
    DeleteIcon,
    formatDisplayName,
    getFullAudit,
    getFullAuditsByExpert,
    IconTag,
    ModalButton,
    openNotificationWithIcon,
    Spin,
    Tag,
    TagInterface,
    Tooltip,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getActiveFullAuditCodeAnalysisReports, getFullAuditCodeAnalysisReport } from "../../../services/codeAnalysisReportService";
import { getCodeAnalysisReportCodeRepositories } from "../../../services/codeRepositoryService";
import { getFullAuditReviewers } from "../../../services/fullAuditService";
import { deleteReport } from "../../../services/reportService";
import { getKYS2CodeAnalysisReportLink } from "../../../services/routing.service";
import { StandardModelListView } from "../components";

function reportStatusToType(stepStatus: CodeAnalysisReport.StepStatus): TagInterface["type"] {
    if (!CodeAnalysisReport.hasStatus(stepStatus)) {
        return "default";
    }
    if (stepStatus.status === CodeAnalysisReport.Status.PENDING) {
        return "warning";
    }
    if (stepStatus.status === CodeAnalysisReport.Status.ERROR) {
        return "danger";
    }
    return "info";
}

function CodeAnalysisProduct({ report }: { report: CodeAnalysisReport }): JSX.Element {
    const [fullAudit, setFullAudit] = useState<FullAudit | null>();
    useEffect(() => getFullAudit(report.fullAuditId, setFullAudit), [report]);
    if (fullAudit === undefined) {
        return <Spin />;
    }
    if (fullAudit === null) {
        return <>No full audit</>;
    }
    return <>{fullAudit.product.name}</>;
}

function CodeAnalysisReportReviewers({ report }: { report: CodeAnalysisReport }): JSX.Element {
    const [reviewers, setReviewers] = useState<(AccountUser | StaffUser)[] | undefined>();
    useEffect(() => {
        void (async function () {
            const fullAudit = await getFullAudit(report.fullAuditId);
            if (fullAudit) {
                setReviewers(await getFullAuditReviewers(fullAudit));
            }
        })();
    }, [report]);

    if (reviewers === undefined) {
        return <Spin />;
    }
    if (!reviewers.length) {
        return <>No reviewer</>;
    }

    return <ul>{reviewers?.map(user => <li key={user.id}>{formatDisplayName(user)}</li>)}</ul>;
}

function CodeAnalysisReportRepositories({ report }: { report: CodeAnalysisReport }): JSX.Element {
    const [codeRepositories, setCodeRepositories] = useState<AnalysedCodeRepository[] | undefined | null>();
    useEffect(() => {
        void (async function () {
            try {
                setCodeRepositories(await getCodeAnalysisReportCodeRepositories(report));
            } catch {
                setCodeRepositories(null);
            }
        })();
    }, [report]);

    if (codeRepositories === undefined) {
        return <Spin />;
    }
    if (codeRepositories === null || !codeRepositories.length) {
        return <>No repository</>;
    }

    const MAX_REPOSITORIES = 5;

    return (
        <ul>
            {codeRepositories.slice(0, MAX_REPOSITORIES)?.map(({ id, name, linesOfCode }) => (
                <li key={id}>
                    {name} <i>[{linesOfCode} lines]</i>
                </li>
            ))}
            {codeRepositories.length > MAX_REPOSITORIES && (
                <Tooltip
                    title={codeRepositories.map(({ id, name }) => (
                        <div key={id} children={name} />
                    ))}
                >
                    <li className="text-xs italic">{codeRepositories.length - MAX_REPOSITORIES} more...</li>
                </Tooltip>
            )}
        </ul>
    );
}

function getColumns(): Column<CodeAnalysisReport>[] {
    return [
        { header: "Product", accessorFn: row => row, cell: cell => <CodeAnalysisProduct report={cell.getValue<CodeAnalysisReport>()} /> },
        {
            header: "Code repositories",
            accessorFn: row => row,
            enableColumnFilter: false,
            enableSorting: false,
            cell: cell => <CodeAnalysisReportRepositories report={cell.getValue<CodeAnalysisReport>()} />
        },
        {
            header: "Assigned Reviewers",
            accessorFn: row => row,
            enableColumnFilter: false,
            enableSorting: false,
            cell: cell => <CodeAnalysisReportReviewers report={cell.getValue<CodeAnalysisReport>()} />
        },
        {
            header: "Setp",
            accessorKey: "stepStatus.step",
            cell: cell => {
                return cell.row.original.stepStatus.step;
            },
            size: 250,
            defaultFilteredValues: Object.values(CodeAnalysisReport.Step).filter(step => step !== CodeAnalysisReport.Step.DONE)
        },
        {
            header: "Status",
            accessorKey: "stepStatus.status",
            cell: cell => {
                const stepStatus = cell.row.original.stepStatus;
                if (CodeAnalysisReport.hasStatus(stepStatus)) {
                    return <Tag type={reportStatusToType(stepStatus)} message={stepStatus.status} />;
                }
                return null;
            },
            size: 250
        },
        {
            header: "Archived",
            accessorKey: "isArchived",
            cell: cell =>
                cell.getValue<boolean>() && (
                    <div className="flex justify-center">
                        <IconTag.Archived size="sm" />
                    </div>
                ),
            size: 105,
            defaultFilteredValues: [false]
        }
    ];
}

export function CodeAnalysisReportList({ staffUser }: { staffUser: StaffUser }) {
    const lang = useLang<BaseLang>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchCodeAnalysisRequests = useMemo(() => {
        if (staffUser.roles.includes(StaffUserRole.ADMIN)) {
            return getActiveFullAuditCodeAnalysisReports;
        } else if (staffUser.roles.includes(StaffUserRole.KYS2_REVIEWER)) {
            return (onUpdate: (values: CodeAnalysisReport[]) => void) =>
                getFullAuditsByExpert(staffUser.id, undefined).then(fullAudits =>
                    Promise.all(
                        fullAudits
                            .filter(fullAudit => fullAudit.reportIds.codeAuditReportId)
                            .map(fullAudit => getFullAuditCodeAnalysisReport(fullAudit.id, fullAudit.reportIds.codeAuditReportId))
                    ).then(reports => onUpdate(reports.filter(isDefined)))
                );
        }
        return null;
    }, [staffUser]);

    async function doDelete(report: CodeAnalysisReport) {
        setIsLoading(true);
        try {
            await deleteReport(report.fullAuditId, report.id);
        } catch {
            openNotificationWithIcon({ type: "error", description: "Failed to delete report" });
        } finally {
            setIsLoading(false);
        }
    }

    const deleteReportModal = useCallback(
        (report: CodeAnalysisReport) => {
            if (staffUser.roles.includes(StaffUserRole.ADMIN)) {
                return (
                    <ModalButton.Confirm
                        icon={DeleteIcon}
                        title="Delete"
                        children="This request will be permanently deleted."
                        onConfirm={() => doDelete(report)}
                        buttonProps={{
                            type: "default",
                            title: "Delete",
                            icon: DeleteIcon,
                            isLoading: false,
                            isDisabled: isLoading
                        }}
                        lang={lang}
                    />
                );
            }

            return undefined;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading, staffUser.roles]
    );

    const columns = useMemo(() => getColumns(), []);

    return (
        <>
            <StandardModelListView<CodeAnalysisReport>
                name="Code analysis requests"
                getModelItems={fetchCodeAnalysisRequests}
                searchOptions={{ hideIgnored: true }}
                modelPageLink={getKYS2CodeAnalysisReportLink}
                columns={columns}
                extraActions={deleteReportModal}
            />
        </>
    );
}

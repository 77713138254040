import { AnalysedCodeRepository, LANG_EN } from "@vaultinum/vaultinum-api";
import { CodeAnalysisReport } from "@vaultinum/vaultinum-kys-api";
import { BreadcrumbItems, formatDateFullText, Spin } from "@vaultinum/vaultinum-sdk";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ModelItemPageHeader } from "../../../components";
import { CodeAuditContext } from "../../../contexts/CodeAuditContext";
import { useFullAudit } from "../../../hooks/useFullAudit";
import { getFullAuditCodeAnalysisReport } from "../../../services/codeAnalysisReportService";
import { getCodeAnalysisReportCodeRepositories } from "../../../services/codeRepositoryService";
import { KYS2_PAGE } from "../../../services/routing.service";
import { AnalyserLauncher, DeleteCodeAnalysisUploadedFilesModal, ReOpenCodeUploadModal } from "../components";

export default function CodeAuditPage(): JSX.Element {
    const { fullAuditId, reportId } = useParams<{ fullAuditId: string; reportId: string }>();
    const fullAudit = useFullAudit(fullAuditId);
    const [report, setReport] = useState<CodeAnalysisReport | null>();
    const [codeRepositories, setCodeRepositories] = useState<AnalysedCodeRepository[]>([]);

    useEffect(() => {
        if (fullAudit?.reportIds.codeAuditReportId) {
            return getFullAuditCodeAnalysisReport(fullAudit.id, reportId, setReport);
        }
        return () => {};
    }, [fullAudit, reportId]);

    useEffect(() => {
        if (report) {
            return getCodeAnalysisReportCodeRepositories(report, setCodeRepositories);
        }
        return () => {};
    }, [report]);

    const contextValue = useMemo(() => {
        if (!fullAudit) {
            return null;
        }
        return { report, codeRepositories, fullAudit };
    }, [codeRepositories, fullAudit, report]);

    if (fullAudit === undefined || report === undefined || !contextValue) {
        return <Spin />;
    }
    if (fullAudit === null || report === null) {
        return <h1>FullAudit or report not found</h1>;
    }

    const auditedAccount = fullAudit.auditedAccount.organisation;
    const breadcrumbItems: BreadcrumbItems = [
        {
            label: "Code analysis requests",
            href: KYS2_PAGE
        },
        {
            label: `${auditedAccount?.companyName} - ${contextValue.fullAudit.product.name} (${formatDateFullText(report.creationDate, LANG_EN)})`
        }
    ];

    return (
        <CodeAuditContext.Provider value={contextValue}>
            <div className="flex flex-col gap-6">
                <div className="flex items-center justify-between">
                    <ModelItemPageHeader breadcrumbItems={breadcrumbItems} />
                    <div className="flex gap-2">
                        {codeRepositories?.some(codeRepository => codeRepository.hasData) && <DeleteCodeAnalysisUploadedFilesModal report={report} />}
                        {!report.isArchived && <ReOpenCodeUploadModal />}
                    </div>
                </div>
                <AnalyserLauncher />
            </div>
        </CodeAuditContext.Provider>
    );
}

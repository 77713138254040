import { Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, Button, Buttons, Column, DeleteIcon, EditIcon, ModalHelper, Table, Tag, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { AddOrEditSurveyVariantModal } from "./AddOrEditSurveyVariantModal";
import { removeVariantFromSections } from "./variant.helper";

function getColumns(onEdit: (variantKey: string) => void, onDelete: (variantKey: string) => void): Column<Survey.Variant>[] {
    return [
        {
            header: "Key",
            accessorKey: "key",
            cell: cell => <Tag type="info" message={cell.getValue<string>()} />
        },
        {
            header: "Requirement",
            accessorKey: "defaultValue",
            cell: cell => {
                if (cell.getValue<string>()) {
                    return <Tag type="danger" message="Always required" />;
                }
                return <Tag type="info" message="Only required when set" />;
            }
        },
        {
            header: "Values",
            accessorKey: "values",
            cell: cell => {
                const values = cell.getValue<string[]>();
                const defaultValue = cell.row.original.defaultValue;
                return (
                    <div className="flex gap-2">
                        {values.map(value => (
                            <Tag
                                key={value}
                                type={value === defaultValue ? "danger" : "info"}
                                message={`${value} ${value === defaultValue ? "[default]" : ""}`.trim()}
                            />
                        ))}
                    </div>
                );
            }
        },
        {
            header: "Actions",
            enableSorting: false,
            enableColumnFilter: false,
            cell: cell => {
                const variant = cell.row.original;
                return (
                    <div className="flex gap-2">
                        <Buttons.Icon isLoading={false} type="default" icon={EditIcon} onClick={() => onEdit(variant.key)} title="Edit variant" />
                        <Buttons.Icon isLoading={false} type="default" icon={DeleteIcon} onClick={() => onDelete(variant.key)} title="Delete variant" />
                    </div>
                );
            }
        }
    ];
}

export default function SurveyVariantView({
    surveyVersion,
    setSurveyVersion
}: {
    surveyVersion: Survey.Version;
    setSurveyVersion: (surveyVersion: Survey.Version) => void;
}) {
    const lang = useLang<BaseLang>();
    const [editVariantKey, setEditVariantKey] = useState<string | null>(null);

    function onDeleteVariant(keyToDelete: string): void {
        ModalHelper.Confirm({
            title: "Delete this variant key?",
            children: `key=${keyToDelete}`,
            onConfirm: () => {
                setSurveyVersion({
                    ...surveyVersion,
                    ...(surveyVersion.variants && { variants: surveyVersion.variants.filter(variant => variant.key !== keyToDelete) }),
                    sections: removeVariantFromSections(surveyVersion.sections, keyToDelete)
                });
            },
            lang
        });
    }

    const onEdit = (variantKey: string) => setEditVariantKey(variantKey);

    const columns = getColumns(onEdit, onDeleteVariant);

    return (
        <>
            <Button isLoading={false} onClick={() => setEditVariantKey("")} children="Add variant" />
            {editVariantKey !== null && (
                <AddOrEditSurveyVariantModal
                    onClose={() => setEditVariantKey(null)}
                    variantKey={editVariantKey}
                    surveyVersion={surveyVersion}
                    setSurveyVersion={setSurveyVersion}
                />
            )}
            <Table<Survey.Variant> data={surveyVersion.variants || []} columns={columns} />
        </>
    );
}

import { StaffUser, StaffUserRole, STAFF_USER_COLLECTION } from "@vaultinum/vaultinum-api";
import {
    addDoc,
    collection,
    DataEventSource,
    deleteDoc,
    doc,
    DocumentData,
    DocumentSnapshot,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    serverTimestamp,
    updateDoc,
    where
} from "@vaultinum/vaultinum-sdk";
import { extractFirstLastNameFromEmail } from "../common/UserTools";
import { deleteSurveysAccessForStaffUserId } from "./surveyAccessService";

export function addStaffUser(email: string) {
    const [firstName, lastName] = extractFirstLastNameFromEmail(email);
    const staffUser = {
        uid: "",
        email,
        roles: [],
        creationDate: serverTimestamp(),
        firstName,
        lastName
    } as Omit<StaffUser, "id" | "creationDate">;
    return addDoc(collection(getFirestore(), STAFF_USER_COLLECTION), staffUser);
}

export function updateStaffUserRoles(staffUserId: string, roles: StaffUserRole[]) {
    return updateDoc(doc(collection(getFirestore(), STAFF_USER_COLLECTION), staffUserId), { roles });
}

export function updateStaffUserName(staffUserId: string, firstName: string, lastName: string) {
    return updateDoc(doc(collection(getFirestore(), STAFF_USER_COLLECTION), staffUserId), { firstName, lastName });
}

export async function deleteStaffUser(staffUserId: string): Promise<void> {
    await Promise.all([deleteSurveysAccessForStaffUserId(staffUserId), deleteDoc(doc(collection(getFirestore(), STAFF_USER_COLLECTION), staffUserId))]);
}

const convertDocToStaffUser = (document: DocumentSnapshot<DocumentData>): StaffUser => {
    const data = document.data() as Omit<StaffUser, "id" | "creationDate">;
    return {
        ...data,
        id: document.id,
        creationDate: document.data()?.creationDate?.toDate()
    };
};

const staffUsersListener = new DataEventSource<StaffUser[]>(onUpdate =>
    onSnapshot(collection(getFirestore(), STAFF_USER_COLLECTION), querySnapshot => onUpdate(querySnapshot.docs.map(convertDocToStaffUser)))
);

export function getStaffUsers(onUpdate: (users: StaffUser[]) => void): () => void {
    return staffUsersListener.addListener(onUpdate);
}

export function getStaffUser(uid: string, onUpdate: (user: StaffUser | null) => void): () => void {
    return staffUsersListener.addListener(staffUsers => {
        onUpdate(staffUsers.find(staffUser => staffUser.uid === uid) ?? null);
    });
}

export function getStaffUserById(id: string): Promise<StaffUser | null>;
export function getStaffUserById(id: string, onUpdate: (user: StaffUser | null) => void): () => void;
export function getStaffUserById(id: string, onUpdate?: (user: StaffUser | null) => void): Promise<StaffUser | null> | (() => void) {
    if (onUpdate) {
        return staffUsersListener.addListener(staffUsers => {
            onUpdate(staffUsers.find(staffUser => staffUser.id === id) ?? null);
        });
    }
    const staffUserDoc = getDoc(doc(collection(getFirestore(), STAFF_USER_COLLECTION), id));
    return staffUserDoc.then(convertDocToStaffUser);
}

export async function getStaffUserByEmail(email: string): Promise<StaffUser | null> {
    const staffUsers = await getDocs(query(collection(getFirestore(), STAFF_USER_COLLECTION), where("email", "==", email)));
    if (staffUsers.size !== 1) {
        return null;
    }
    return convertDocToStaffUser(staffUsers.docs[0]);
}

import { Question, Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, Input, ModalHelper, Tag } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";

const QuestionVariantSelector = ({
    question,
    setQuestion,
    surveyVersion,
    readOnly
}: {
    question: Question;
    setQuestion: (question: Question) => void;
    surveyVersion: Survey.Version;
    readOnly: boolean;
}) => {
    const [updatedQuestion, setUpdatedQuestion] = useState(question);
    const updateQuestionVariant = (variantKey: string, values: string[]) => {
        const newQuestion = {
            ...updatedQuestion,
            variants: {
                ...(updatedQuestion.variants || {}),
                [variantKey]: values
            }
        };
        if (!values.length) {
            delete newQuestion.variants[variantKey];
        }
        setUpdatedQuestion(newQuestion);
        setQuestion(newQuestion);
    };
    return (
        <>
            <p>Select what variant is required for this question to be displayed</p>
            {surveyVersion.variants?.map(variant => {
                let selectedQuestionVariants = updatedQuestion.variants?.[variant.key] || [];
                if (!selectedQuestionVariants.length && variant.defaultValue) {
                    selectedQuestionVariants = [variant.defaultValue];
                }
                return (
                    <div key={variant.key} className="mt-2 space-y-2 border p-2">
                        <Tag message={variant.key} />
                        <div className="flex space-x-2">
                            {variant.values.map(value => {
                                return (
                                    <Input.Checkbox
                                        key={value}
                                        checked={selectedQuestionVariants.includes(value)}
                                        onClick={() => {
                                            if (selectedQuestionVariants.includes(value)) {
                                                updateQuestionVariant(
                                                    variant.key,
                                                    selectedQuestionVariants.filter(v => v !== value)
                                                );
                                            } else {
                                                updateQuestionVariant(variant.key, [...selectedQuestionVariants, value]);
                                            }
                                        }}
                                        label={variant.defaultValue === value ? `${value} (default)` : value}
                                        disabled={readOnly}
                                    />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

const openQuestionVariantModal = (
    lang: BaseLang,
    question: Question,
    surveyVersion: Survey.Version,
    setQuestion: (question: Question) => void,
    readOnly: boolean
) => {
    ModalHelper.Modal({
        title: "Set variant",
        children: <QuestionVariantSelector question={question} surveyVersion={surveyVersion} setQuestion={setQuestion} readOnly={readOnly} />,
        okText: "Close",
        lang
    });
};

export default openQuestionVariantModal;

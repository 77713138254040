import { getNodeTargetValue, query, Question, Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, CheckSquareTwoToneIcon, Input, message, PositiveTwoToneIcon, Select, Tag, User } from "@vaultinum/vaultinum-sdk";
import { omit } from "lodash";
import { CSSProperties, ReactNode } from "react";
import { sameTarget } from "../../../../../../common/AccountTools";
import { isSurveyPublished, recursiveDeleteQuestion } from "../../../../../../common/SurveyTools";
import { updateLang } from "../../../../../../services/surveyLangService";
import { EditableCellActionType } from "../../../components/EditableCell";
import SurveyIcons from "../../../components/SurveyIcons";
import SurveyNodeEditableCell from "../../../components/SurveyNodeEditableCell";
import { getIconFromEvaluation } from "../../SurveyBuilderTools";
import openOptionAttributeModal from "../SetOptionAttributeModal";
import { questionToTreeNode } from "./QuestionTreeNode";
import { getVariantColumns, TreeNodeEditableCell } from "./SurveyTreeNodeBuilder";
import { newEmptyFix, newEmptyQuestion } from "./SurveyTreeNodeTools";
import { SurveyTreeNode } from "./TreeNode";

function getSelectStyles(menuWidth: string) {
    return {
        menu: (provided: unknown) => ({
            ...(provided as CSSProperties),
            width: menuWidth
        }),
        control: (provided: unknown) => ({
            ...(provided as CSSProperties),
            border: 0,
            boxShadow: "none",
            padding: "0 !important",
            height: "1.5rem !important",
            backgroundColor: "transparent !important"
        }),
        dropdownIndicator: (provided: unknown) => ({
            ...(provided as CSSProperties),
            display: "none"
        })
    };
}

const OptionTypeSelect = ({ option, setOption, disabled }: { option: Question.Option; setOption: (option: Question.Option) => void; disabled?: boolean }) => {
    let optionValue = "";
    if (option.modifiers?.fileUpload) {
        optionValue = "fileUpload";
    }
    if (option.modifiers?.textInput) {
        optionValue = "textInput";
    }
    if (option.modifiers?.fileUpload && option.modifiers?.textInput) {
        optionValue = "textInputOrFileUpload";
    }
    if (option.modifiers?.countryList) {
        optionValue = "countryList";
    }
    if (option.modifiers?.extensionList) {
        optionValue = "extensionList";
    }
    if (option.modifiers?.trademarkClassList) {
        optionValue = "trademarkClassList";
    }

    const selectStyles = getSelectStyles("300px");

    return (
        <Select.Basic
            isDisabled={disabled}
            key={`${option.id}-option`}
            value={optionValue}
            styles={selectStyles}
            onChange={value =>
                value !== undefined &&
                setOption({
                    ...option,
                    modifiers: value === "textInputOrFileUpload" ? { textInput: true, fileUpload: true } : { [value]: true }
                })
            }
            options={[
                { value: "textInput", label: "✍ Text Input required" },
                { value: "fileUpload", label: "📤 File upload required" },
                { value: "textInputOrFileUpload", label: "✍ / 📤 Text Input or File upload required" },
                { value: "countryList", label: "🌍 Country List input" },
                { value: "extensionList", label: "🌐 Domain extension list" },
                { value: "trademarkClassList", label: "⚖️ Trademark class list" },
                { value: "", label: "None" }
            ]}
        />
    );
};

export const optionToTreeNode = (
    lang: BaseLang,
    surveyVersion: Survey.Version,
    selectedSurveyLang: Survey.Lang,
    defaultSurveyLang: Survey.Lang | undefined,
    surveyLangs: Survey.Lang[],
    parentKey: string,
    parentQuestion: Question,
    key: string,
    optionType: Question.OptionType,
    option: Question.Option,
    setOption: (option: Question.Option) => void,
    deleteOption: () => Promise<void>,
    user: User,
    comments: Survey.Comment[]
): SurveyTreeNode => {
    const selectStyles = getSelectStyles("100px");
    const isLangPublished = isSurveyPublished(surveyVersion);
    // Question nodes
    const children =
        option.questions?.map((question, i) =>
            questionToTreeNode(
                lang,
                surveyVersion,
                selectedSurveyLang,
                defaultSurveyLang,
                surveyLangs,
                key,
                `${key}.${i + 1}`,
                question,
                question => setOption(query(option).updateQuestion(question)),
                async () => {
                    try {
                        await recursiveDeleteQuestion(surveyLangs, question, user.uid);
                        setOption(query(option).deleteQuestion(question.id));
                    } catch (err) {
                        void message.error(err.message);
                    }
                },
                user,
                comments
            )
        ) || [];
    // Context why node
    if (option.context?.why) {
        const target: Survey.NodeTarget = {
            type: Survey.NodeType.OPTION,
            property: Survey.NodeTargetProperty.WHY,
            nodeId: option.id
        };
        const deleteWhy = async () => {
            if (option.context) {
                const { ...newcontext } = omit(option.context, "why");
                setOption({ ...option, context: newcontext });
            }
            await updateLang(selectedSurveyLang, user.uid).option(option.id).why(null);
        };
        children.push({
            parentKey: key,
            key: `${key}.why`,
            target,
            icon: <SurveyIcons.OptionWhy />,
            title: (
                <SurveyNodeEditableCell
                    nodeKey={`${key}.why`}
                    target={target}
                    onDelete={deleteWhy}
                    onChange={(surveyLang, value) => updateLang(surveyLang, user.uid).option(option.id).why(value)}
                    nodeValue={getNodeTargetValue(target, selectedSurveyLang)}
                    defaultValue={getNodeTargetValue(target, defaultSurveyLang)}
                    surveyLang={selectedSurveyLang}
                    suggestionComment={
                        comments.filter(comm => sameTarget(comm.target, target)).sort((a, b) => b.creationDate?.getTime() - a.creationDate?.getTime())[0]
                    }
                />
            ),
            data: option
        });
    }
    // Context fix node
    if (option.context?.fixes) {
        option.context?.fixes.forEach((fix, fixIndex) => {
            const tagEval: { [key: string]: ReactNode } = {};
            surveyVersion.evaluationTags.forEach(tag => {
                tagEval[tag] = (
                    <Input.Checkbox
                        key={`${option.id}-${tag}`}
                        defaultChecked={fix.tags.includes(tag)}
                        onChange={checked =>
                            setOption({
                                ...option,
                                context: {
                                    ...option.context,
                                    fixes: option.context?.fixes?.map(_fix => {
                                        if (_fix.id !== fix.id) {
                                            return _fix;
                                        }
                                        return {
                                            ..._fix,
                                            tags: checked ? [..._fix.tags, tag] : _fix.tags.filter(_tag => _tag !== tag)
                                        };
                                    })
                                }
                            })
                        }
                    />
                );
            });
            const target: Survey.NodeTarget = {
                type: Survey.NodeType.OPTION,
                property: Survey.NodeTargetProperty.FIXES,
                nodeId: option.id,
                subNodeId: fix.id
            };
            const deleteFixes = async () => {
                if (option.context?.fixes) {
                    setOption({
                        ...option,
                        context: {
                            ...option.context,
                            fixes: option.context.fixes.filter(_fix => _fix.id !== fix.id)
                        }
                    });
                    await updateLang(selectedSurveyLang, user.uid).option(option.id).fix(fix.id, null);
                }
            };
            children.push({
                parentKey: key,
                key: `${key}.fix.${fixIndex}`,
                target,
                icon: <SurveyIcons.OptionFix />,
                title: (
                    <SurveyNodeEditableCell
                        nodeKey={`${key}.fix`}
                        target={target}
                        onDelete={deleteFixes}
                        onChange={(surveyLang, value) => updateLang(surveyLang, user.uid).option(option.id).fix(fix.id, value)}
                        nodeValue={getNodeTargetValue(target, selectedSurveyLang)}
                        defaultValue={getNodeTargetValue(target, defaultSurveyLang)}
                        surveyLang={selectedSurveyLang}
                        suggestionComment={
                            comments.filter(comm => sameTarget(comm.target, target)).sort((a, b) => b.creationDate?.getTime() - a.creationDate?.getTime())[0]
                        }
                    />
                ),
                data: option,
                option: (
                    <Select.Basic
                        onChange={value =>
                            setOption({
                                ...option,
                                context: {
                                    ...option.context,
                                    fixes: option.context?.fixes?.map(_fix => {
                                        if (_fix.id !== fix.id) {
                                            return _fix;
                                        }
                                        return {
                                            ..._fix,
                                            type: value
                                        };
                                    })
                                }
                            })
                        }
                        value={fix.type}
                        options={[
                            { value: Question.FixType.QUICK, label: <Tag type="info" message="Quick" /> },
                            { value: Question.FixType.LONG, label: <Tag type="danger" message="Long" /> }
                        ]}
                        styles={selectStyles}
                    />
                ),
                tagEval
            });
        });
    }
    const tagEval: { [key: string]: ReactNode } = {};
    surveyVersion.evaluationTags.forEach(tag => {
        const optionEval = option.evaluations.find(evaluation => evaluation.tag === tag);
        tagEval[tag] = (
            <Select.Basic
                key={`${option.id}-${tag}`}
                defaultValue={optionEval?.evaluation}
                styles={selectStyles}
                onChange={newEvaluation => {
                    if (newEvaluation === Question.Evaluation.None) {
                        setOption({
                            ...option,
                            evaluations: option.evaluations.filter(tagEval => tagEval.tag !== tag)
                        });
                    } else {
                        setOption({
                            ...option,
                            evaluations: [...option.evaluations.filter(tagEval => tagEval.tag !== tag), { tag, evaluation: newEvaluation }]
                        });
                    }
                }}
                value={optionEval?.evaluation}
                options={Object.values(Question.Evaluation).map(value => ({ value, label: getIconFromEvaluation(value) }))}
            />
        );
    });
    const target: Survey.NodeTarget = {
        type: Survey.NodeType.OPTION,
        property: Survey.NodeTargetProperty.TEXT,
        nodeId: option.id
    };

    const preambleSection = surveyVersion.preambleSectionId ? query(surveyVersion).getSectionFromId(surveyVersion.preambleSectionId) : null;
    const actions = [
        {
            key: EditableCellActionType.ADD_QUESTION,
            icon: <SurveyIcons.Question size="xs" />,
            tooltip: "Double click to add question",
            onDoubleClick: () => {
                const newQuestion = newEmptyQuestion();
                setOption({ ...option, questions: [...(option.questions || []), newQuestion] });
                updateLang(selectedSurveyLang, user.uid).question(newQuestion.id).text("");
            }
        },
        {
            key: EditableCellActionType.WHY_CONTEXT,
            icon: <SurveyIcons.OptionWhy />,
            hide: option.context?.why,
            tooltip: "Double click to add 'why' context",
            onDoubleClick: () => {
                setOption({ ...option, context: { ...option.context, why: true } });
                updateLang(selectedSurveyLang, user.uid).option(option.id).why("");
            }
        },
        {
            key: EditableCellActionType.FIX_SUGGESTION,
            icon: <SurveyIcons.OptionFix />,
            tooltip: "Double click to add a new 'fix' suggestion",
            onDoubleClick: () => {
                const newFix = newEmptyFix(option.evaluations.map(evaluation => evaluation.tag));
                setOption({ ...option, context: { ...option.context, fixes: [...(option.context?.fixes || []), newFix] } });
                updateLang(selectedSurveyLang, user.uid).option(option.id).fix(newFix.id, "");
            }
        }
    ];

    const preambleHasParentQuestion = !!preambleSection && query(preambleSection).hasQuestion(parentQuestion.id);

    return {
        parentKey,
        key,
        target,
        icon: optionType === Question.OptionType.Checkbox ? <CheckSquareTwoToneIcon isTwoToneColor /> : <PositiveTwoToneIcon isTwoToneColor />,
        title: (
            <TreeNodeEditableCell
                nodeKey={key}
                actions={actions}
                comments={comments}
                defaultSurveyLang={defaultSurveyLang}
                selectedSurveyLang={selectedSurveyLang}
                surveyVersion={surveyVersion}
                target={target}
                onChange={(surveyLang, text) => updateLang(surveyLang, user.uid).option(option.id).text(text)}
                onDelete={deleteOption}
            />
        ),
        data: option,
        children: children.length > 0 ? children : undefined,
        option: <OptionTypeSelect disabled={isLangPublished} option={option} setOption={setOption} />,
        tagEval,
        variants: getVariantColumns(
            surveyVersion,
            option,
            preambleHasParentQuestion,
            preambleHasParentQuestion ? () => openOptionAttributeModal(lang, option, surveyVersion, setOption, isLangPublished) : undefined
        )
    };
};

import { Question, Section, Survey } from "@vaultinum/vaultinum-api";
import { ReactNode } from "react";

export interface TreeNode<T extends TreeNode<T>> {
    key: string;
    title: ReactNode;
    icon?: ReactNode;
    children?: T[];
    parentKey: string;
}

export interface SurveyTreeNode extends TreeNode<SurveyTreeNode> {
    target?: Survey.NodeTarget;
    data: Question | Section | Question.Option | null;
    option?: ReactNode;
    tagEval?: {
        [key: string]: ReactNode | null;
    };
    variants?: {
        [key: string]: ReactNode | null;
    };
    weight?: ReactNode;
}

export function findNodeWithKey<T extends TreeNode<T>>(nodes: T[], key: string): T | undefined {
    let targetNode = nodes.find(node => node.key === key);
    if (targetNode) {
        return targetNode;
    }
    for (const node of nodes) {
        if (node.children) {
            targetNode = findNodeWithKey(node.children, key);
            if (targetNode) {
                return targetNode;
            }
        }
    }
    return undefined;
}

import { CodeAnalysis } from "@vaultinum/vaultinum-api";
import { Plugin } from "@vaultinum/vaultinum-kys-api";
import { IconTag, Spin } from "@vaultinum/vaultinum-sdk";

export enum ReviewStep {
    analysis,
    assign,
    start,
    comment,
    end
}

export type CodeRepositoryPattern = { codeRepositoryId: string; pattern: string; path: string };

export function formatSeconds(duration: number): string {
    const hours = Math.trunc(duration / 3600000) % 24;
    const minutes = Math.trunc(duration / 60000) % 60;
    const seconds = Math.trunc(duration / 1000) % 60;
    return `0${hours}`.slice(-2) + ":" + `0${minutes}`.slice(-2) + ":" + `0${seconds}`.slice(-2);
}

export function formatAnalysisStatus(status?: Plugin.Status | CodeAnalysis.AnalysisStatus) {
    switch (status) {
        case Plugin.Status.IDLE:
            return <span className="ml-8 text-xs italic text-grey-primary">{status}</span>;
        case Plugin.Status.WORKING:
            return (
                <span className="flex space-x-4">
                    <span>
                        <Spin size="small" />
                    </span>
                    <span>{status}</span>
                </span>
            );
        case Plugin.Status.SUCCESS:
            return <IconTag.Success message={status} />;
        case Plugin.Status.FAILED:
            return <IconTag.Danger message={status} />;
        case Plugin.Status.SKIPPED:
            return <IconTag.Info message={status} />;
        default:
            return "-";
    }
}

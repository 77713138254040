import { Button, MicrosoftIcon } from "@vaultinum/vaultinum-sdk";
import { doEmailLogin, doMicrosoftLogin } from "../../services/loginService";
import CredentialsForm from "./CredentialsForm";

export default function LoginPage() {
    return (
        <div className="mx-auto flex w-96 flex-col gap-4">
            <h1 className="text-center">Login</h1>
            <CredentialsForm actionName="Login" doAction={doEmailLogin} />
            <div className="text-center">Or</div>
            <Button type="default" icon={MicrosoftIcon} onClick={doMicrosoftLogin} isLoading={false}>
                Sign in with <strong>Microsoft</strong>
            </Button>
        </div>
    );
}

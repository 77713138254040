import { StaffUser } from "@vaultinum/vaultinum-api";
import { User } from "@vaultinum/vaultinum-sdk";
import { Route, Routes } from "react-router-dom";
import { KYS2_REPORT_PAGE } from "../../../services/routing.service";
import { CodeAnalysisReportList } from "./CodeAnalysisReportList";
import CodeAuditPage from "./CodeAuditPage";

export default function Kys2Page({ staffUser }: { user: User; staffUser: StaffUser }) {
    return (
        <Routes>
            <Route index element={<CodeAnalysisReportList staffUser={staffUser} />} />
            <Route path={`${KYS2_REPORT_PAGE}/:fullAuditId/:reportId`} element={<CodeAuditPage />} />
        </Routes>
    );
}

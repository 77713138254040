import { FullAudit } from "@vaultinum/vaultinum-api";
import { Select } from "@vaultinum/vaultinum-sdk";

export default function ScopeSelector(props: {
    value?: FullAudit.Scope | FullAudit.Scope[];
    defaultValue?: FullAudit.Scope | FullAudit.Scope[];
    onChange: (value: FullAudit.Scope | FullAudit.Scope[]) => void;
    disabled?: boolean;
    mode?: "single" | "multiple";
    required?: boolean;
}) {
    return (
        <>
            {props.mode === "multiple" ? (
                <Select.Multiple
                    {...props}
                    label="Scope"
                    placeholder="Select scope..."
                    options={Object.values(FullAudit.Scope).map(scope => ({
                        value: scope,
                        label: scope
                    }))}
                    isDisabled={props.disabled}
                    required={props.required}
                />
            ) : (
                <Select.Basic
                    {...props}
                    label="Scope"
                    placeholder="Select scope..."
                    options={Object.values(FullAudit.Scope).map(scope => ({
                        value: scope,
                        label: scope
                    }))}
                    isDisabled={props.disabled}
                    required={props.required}
                />
            )}
        </>
    );
}

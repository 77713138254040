import { Survey, SURVEY_ACCESS_COLLECTION } from "@vaultinum/vaultinum-api";
import {
    collection,
    collectionGroup,
    CollectionReference,
    converter,
    deleteDoc,
    doc,
    DocumentReference,
    getFirestore,
    getItems,
    Query,
    query,
    setDoc,
    surveyDoc,
    Unsubscribe,
    where
} from "@vaultinum/vaultinum-sdk";

const CONVERTER = converter<Survey.Access>();

function surveyAccessGroupCollection(): Query<Survey.Access> {
    return collectionGroup(getFirestore(), SURVEY_ACCESS_COLLECTION).withConverter(CONVERTER);
}

function surveyAccessCollection(surveyKey: string): CollectionReference<Survey.Access> {
    return collection(surveyDoc(surveyKey), SURVEY_ACCESS_COLLECTION).withConverter(CONVERTER);
}

function surveyAccessDoc(surveyKey: string, staffUserId: string): DocumentReference<Survey.Access> {
    return doc(surveyAccessCollection(surveyKey), staffUserId);
}

export function getSurveysAccessesByStaffUserId(staffUserId: string): Promise<Survey.Access[]>;
export function getSurveysAccessesByStaffUserId(staffUserId: string, onUpdate: (accesses: Survey.Access[]) => void): Unsubscribe;
export function getSurveysAccessesByStaffUserId(staffUserId: string, onUpdate?: (accesses: Survey.Access[]) => void): Promise<Survey.Access[]> | Unsubscribe {
    const q = query(surveyAccessGroupCollection(), where("staffUserId", "==", staffUserId));
    if (onUpdate) {
        return getItems(q, onUpdate);
    }
    return getItems(q);
}

export function getSurveyAccesses(surveyKey: string): Promise<Survey.Access[]>;
export function getSurveyAccesses(surveyKey: string, onUpdate: (accesses: Survey.Access[]) => void): Unsubscribe;
export function getSurveyAccesses(surveyKey: string, onUpdate?: (accesses: Survey.Access[]) => void): Promise<Survey.Access[]> | Unsubscribe {
    if (onUpdate) {
        return getItems(surveyAccessCollection(surveyKey), onUpdate);
    }
    return getItems(surveyAccessCollection(surveyKey));
}

export async function addSurveyAccess(surveyKey: string, staffUserId: string): Promise<void> {
    return setDoc(surveyAccessDoc(surveyKey, staffUserId), { staffUserId, surveyKey });
}

export async function deleteSurveyAccess(surveyKey: string, staffUserId: string): Promise<void> {
    return deleteDoc(surveyAccessDoc(surveyKey, staffUserId));
}

export async function deleteSurveysAccessForStaffUserId(staffUserId: string): Promise<void> {
    const surveysAccesses = await getSurveysAccessesByStaffUserId(staffUserId);
    for (const surveyAccess of surveysAccesses) {
        try {
            await deleteDoc(surveyAccessDoc(surveyAccess.surveyKey, staffUserId));
        } catch (error) {
            console.error(error);
        }
    }
}

import sanitize from "sanitize-filename";

export function includesWithCase(baseString: string | undefined, searchedString: string, caseSensitive?: boolean) {
    if (!baseString) {
        return false;
    }
    return caseSensitive ? baseString.includes(searchedString) : baseString.toLowerCase().includes(searchedString.toLowerCase());
}

export function sanitizeString(value: string): string {
    return encodeURI(sanitize(value).replace(/[!'()*]/g, ""));
}

import { calculateTagScoresSummary, Question, ScoreImpact, Survey } from "@vaultinum/vaultinum-api";
import { SurveyTreeNode } from "../survey-builder/editor/nodes/TreeNode";

function getScoreTagsForNodeTarget(scoreImpact: ScoreImpact, nodeTarget?: Survey.NodeTarget): Question.TagScore[] | undefined {
    let scoreTags: Question.TagScore[] = [];
    if (nodeTarget?.type === Survey.NodeType.SECTION && nodeTarget.property === Survey.NodeTargetProperty.TITLE) {
        scoreTags = scoreImpact.sections[nodeTarget.nodeId];
    } else if (nodeTarget?.type === Survey.NodeType.QUESTION && nodeTarget.property === Survey.NodeTargetProperty.TEXT) {
        scoreTags = scoreImpact.questions[nodeTarget.nodeId];
    } else if (nodeTarget?.type === Survey.NodeType.OPTION && nodeTarget.property === Survey.NodeTargetProperty.TEXT) {
        scoreTags = scoreImpact.options[nodeTarget.nodeId];
    }
    return scoreTags;
}

const ScoreLabel = ({ score }: { score: number }) => <div className="text-center text-xs text-red-600">[{score}]</div>;

export function NodeSummaryScoreImpact({ scoreImpact, nodeTarget }: { scoreImpact: ScoreImpact; nodeTarget?: Survey.NodeTarget }): JSX.Element {
    const scoreTags = getScoreTagsForNodeTarget(scoreImpact, nodeTarget);
    return scoreTags?.length ? <ScoreLabel score={calculateTagScoresSummary(scoreTags, scoreImpact.tagWeights, 1)} /> : <></>;
}

export function NodeTagScoreImpact({ scoreImpact, node, tag }: { scoreImpact: ScoreImpact; node: SurveyTreeNode; tag: string }): JSX.Element {
    if (!node.tagEval) {
        return <></>;
    }
    const tagScores = getScoreTagsForNodeTarget(scoreImpact, node.target);
    const tagScore = tagScores?.find(tagScore => tagScore.tag === tag);
    return (
        <div className="flex items-center justify-center space-x-1">
            <span>{node.tagEval[tag]}</span>
            {tagScore && <ScoreLabel score={calculateTagScoresSummary([tagScore], { [tag]: 1 }, 1)} />}
        </div>
    );
}

import { Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, EditTwoToneIcon, HighlightTwoToneIcon, message, NegativeTwoToneIcon, PositiveTwoToneIcon, useLang } from "@vaultinum/vaultinum-sdk";
import { useCallback, useContext, useMemo } from "react";
import Highlighter from "react-highlight-words";
import { StringDiffDisplay } from "../../../../components";
import { SearchContext } from "../../../../contexts/SearchContext";
import { addSurveyComment, deleteSurveyComment, resolveComment, updateCommentSuggestion } from "../../../../services/surveyCommentService";
import { SurveyEditableNodesContext } from "../survey-builder/editor/SurveyEditableNodesContextProps";
import { SurveyBuilderContext } from "../survey-builder/SurveyBuilderContext";
import confirmNodeDeletion from "./ConfirmNodeDeletion";
import EditableCell, { EditableCellActionProps, EditableCellActionType } from "./EditableCell";
import { applyCommentChangeSuggestion } from "./SurveyCommentTools";

export default function SurveyNodeEditableCell({
    nodeKey,
    nodeValue,
    defaultValue,
    target,
    surveyLang,
    onDelete,
    onChange,
    actions,
    suggestionComment
}: {
    nodeKey: string;
    target: Survey.NodeTarget;
    surveyLang: Survey.Lang;
    onChange: (surveyLang: Survey.Lang, value: string) => void;
    actions?: EditableCellActionProps[];
    defaultValue?: string;
    nodeValue?: string;
    suggestionComment?: Survey.Comment;
    onDelete?: () => Promise<void>;
}) {
    const lang = useLang<BaseLang>();
    const { user, suggestionMode, isWorking } = useContext(SurveyBuilderContext);
    const { toggleExpandedRowKey, editedTarget, setEditedTarget } = useContext(SurveyEditableNodesContext);
    const { search } = useContext(SearchContext);

    const confirmDelete = () => {
        if (onDelete) {
            confirmNodeDeletion(lang, async () => {
                if (toggleExpandedRowKey) {
                    toggleExpandedRowKey(nodeKey, false);
                }
                await onDelete();
            });
        }
    };

    const displayValue = useMemo(() => {
        if (suggestionComment?.changeSuggestion) {
            if (search && suggestionComment.changeSuggestion.toLowerCase().includes(search.toLowerCase())) {
                return <Highlighter highlightClassName="search-match" searchWords={[search]} autoEscape textToHighlight={suggestionComment.changeSuggestion} />;
            } else {
                return <StringDiffDisplay value={nodeValue || ""} other={suggestionComment.changeSuggestion} useWebWorker />;
            }
        } else if (!nodeValue && defaultValue) {
            return <span className="default-text">{defaultValue}</span>;
        }
        return null;
    }, [nodeValue, defaultValue, suggestionComment, search]);

    const applyValueChange = useCallback(
        async (value: string) => {
            try {
                if (suggestionMode) {
                    if (!suggestionComment) {
                        if (value !== nodeValue) {
                            await addSurveyComment({
                                authorUID: user.uid,
                                lang: surveyLang.lang,
                                surveyKey: surveyLang.surveyKey,
                                surveyVersion: surveyLang.surveyVersion,
                                target,
                                changeSuggestion: value
                            });
                        }
                    } else if (![suggestionComment.changeSuggestion, nodeValue].includes(value)) {
                        await updateCommentSuggestion(suggestionComment, value);
                    } else if (value === nodeValue) {
                        await deleteSurveyComment(suggestionComment);
                    }
                } else if (value !== nodeValue) {
                    onChange(surveyLang, value);
                }
            } catch (error) {
                void message.error(`Action failed: ${error}`);
            }
        },
        [suggestionMode, suggestionComment, surveyLang, user, target, nodeValue, onChange]
    );

    const extraActions = useMemo(() => {
        let cellActions: EditableCellActionProps[] = [];
        if (!suggestionMode && suggestionComment?.changeSuggestion) {
            cellActions = [
                {
                    key: EditableCellActionType.APPLY_SUGGESTION,
                    icon: <PositiveTwoToneIcon size="xs" />,
                    tooltip: "Apply suggestion",
                    onDoubleClick: async () => {
                        applyCommentChangeSuggestion(suggestionComment, surveyLang, user.uid);
                        await resolveComment(suggestionComment, user.uid);
                    }
                },
                {
                    key: EditableCellActionType.DISCARD_SUGGESTION,
                    icon: <NegativeTwoToneIcon color="red" size="xs" isTwoToneColor />,
                    tooltip: "Discard suggestion",
                    onDoubleClick: () => resolveComment(suggestionComment, user.uid)
                }
            ];
        }
        if (!suggestionMode && actions?.length) {
            cellActions = [
                ...cellActions,
                ...actions.map(action => ({
                    ...action,
                    onDoubleClick: async () => {
                        if (action.onDoubleClick) {
                            await action.onDoubleClick();
                            if (toggleExpandedRowKey) {
                                toggleExpandedRowKey(nodeKey, true);
                            }
                        }
                    }
                }))
            ];
        }
        return cellActions;
    }, [actions, suggestionComment, nodeKey, surveyLang, user.uid, suggestionMode, toggleExpandedRowKey]);

    const editIcon = useMemo(() => (suggestionMode ? <HighlightTwoToneIcon isTwoToneColor /> : <EditTwoToneIcon isTwoToneColor />), [suggestionMode]);

    return (
        <EditableCell
            disabled={(suggestionComment && suggestionComment?.authorUID !== user.uid && suggestionMode) || isWorking}
            onChange={applyValueChange}
            onDelete={suggestionMode ? undefined : confirmDelete}
            search={search}
            value={suggestionMode && suggestionComment?.changeSuggestion ? suggestionComment?.changeSuggestion : nodeValue || defaultValue}
            displayValue={displayValue}
            editIcon={editIcon}
            extraActions={extraActions}
            editedTarget={editedTarget}
            setEditedTarget={setEditedTarget}
            target={target}
            surveyKey={surveyLang.surveyKey}
        />
    );
}

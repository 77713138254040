import { Column, SectionTitle, Spin, Table } from "@vaultinum/vaultinum-sdk";

export default function TableLayout<T extends object>({ title, items, columns }: { title: string; items: T[] | undefined; columns: Column<T>[] }) {
    const titleWithCount = [title, ...(items ? [`(${items.length})`] : [])].join(" ");
    return (
        <>
            <SectionTitle title={titleWithCount} />
            {!items && <Spin />}
            {!!items?.length && (
                <div className="h-full max-h-60 w-full">
                    <Table<T> data={items} columns={columns} />
                </div>
            )}
        </>
    );
}

import { Question } from "@vaultinum/vaultinum-api";
import { groupBy } from "lodash";

export function getIconFromEvaluation(evaluation: Question.Evaluation): string | undefined {
    if (evaluation === Question.Evaluation.None) {
        return "";
    }
    if (evaluation === Question.Evaluation.NotApplicable) {
        return "⚪";
    }
    if (evaluation === Question.Evaluation.Positive) {
        return "✅";
    }
    if (evaluation === Question.Evaluation.Warning) {
        return "⚠️";
    }
    if (evaluation === Question.Evaluation.High) {
        return "❌";
    }
    if (evaluation === Question.Evaluation.Critical) {
        return "❌❌";
    }
    return undefined;
}

export function getQuestionOptionIcons(option: Question.Option): string {
    const evalTags = groupBy(option.evaluations, "evaluation");
    return Object.keys(evalTags)
        .map(
            evalName =>
                `${getIconFromEvaluation(evalName as Question.Evaluation)}${
                    evalTags[evalName].filter(x => x.tag !== undefined).length > 0 ? `[${evalTags[evalName].map(x => x.tag).join("][")}]` : ""
                }`
        )
        .join("");
}

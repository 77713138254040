import {
    createUserWithEmailAndPassword,
    getAuth,
    linkWithCredential,
    OAuthProvider,
    openNotificationWithIcon,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signInWithPopup,
    User,
    UserCredential
} from "@vaultinum/vaultinum-sdk";

export async function doMicrosoftLogin() {
    const provider = new OAuthProvider("microsoft.com");
    provider.addScope("email");
    provider.addScope("profile");
    provider.setCustomParameters({
        tenant: process.env.REACT_APP_MICROSOFT_TENANT_ID as string,
        prompt: "select_account"
    });
    try {
        await signInWithPopup(getAuth(), provider);
    } catch (error) {
        if (error.code === "auth/account-exists-with-different-credential") {
            const password = prompt("An account already exists with this email. Please enter your password to link the account.");
            if (password) {
                try {
                    const { user } = await signInWithEmailAndPassword(getAuth(), error.customData.email, password);
                    const credential = OAuthProvider.credentialFromResult(error.customData);
                    if (credential) {
                        await linkWithCredential(user, credential);
                    }
                } catch (err) {
                    openNotificationWithIcon({ type: "error", description: err.message });
                }
            }
        } else {
            openNotificationWithIcon({ type: "error", description: error.message });
        }
    }
}

export function doEmailLogin(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(getAuth(), email, password);
}

export function doEmailRegister(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(getAuth(), email, password);
}

export function doLogout() {
    return getAuth().signOut();
}

export async function doSendEmailVerification() {
    return sendEmailVerification(getAuth().currentUser as User);
}

import { Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, Controller, Form, Input, message, Modal, useForm, useLang, User, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { updateLang } from "../../../../../services/surveyLangService";

export default function AddOrEditSurveyEvaluationTagModal({
    isOpen,
    onClose,
    surveyVersion,
    surveyLang,
    setSurveyVersion,
    user,
    evalTag
}: {
    isOpen: boolean;
    onClose: () => void;
    surveyVersion: Survey.Version;
    surveyLang: Survey.Lang;
    setSurveyVersion: (surveyVersion: Survey.Version) => void;
    user: User;
    evalTag?: { tag: string };
}) {
    const lang = useLang<BaseLang>();
    const [isWorking, setIsWorking] = useState(false);
    const currentTag = evalTag?.tag || "";
    const isEditing = !!currentTag;

    const schema = yup.object({
        tagKey: useRequiredString()
            .trim()
            .test("unique-tag-key", "A tag key with the same name already exists", function (value) {
                return value === currentTag || (!isEditing && !surveyVersion.evaluationTags.includes(value.toUpperCase()));
            }),
        name: useRequiredString().trim(),
        description: useRequiredString().trim()
    });

    const {
        handleSubmit,
        control,
        getValues,
        formState: { errors, isValid }
    } = useForm({
        schema,
        defaultValues: {
            tagKey: currentTag,
            name: surveyLang.evaluationTag[currentTag]?.name || "",
            description: surveyLang.evaluationTag[currentTag]?.description || ""
        }
    });

    function onConfirm() {
        try {
            setIsWorking(true);
            const { tagKey, name, description } = getValues();
            const normalizedTagKey = tagKey.toUpperCase();
            if (!isEditing) {
                setSurveyVersion({ ...surveyVersion, evaluationTags: [...surveyVersion.evaluationTags, normalizedTagKey] });
            }
            updateLang(surveyLang, user.uid).evaluationTag(normalizedTagKey).value(name, description);
            onClose();
        } catch (error) {
            void message.error(`Failed to create the tag, caused by: ${error.message}`);
        } finally {
            setIsWorking(false);
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isEditing ? "Update evaluation tag" : "Add evaluation tag"}
            okText={isEditing ? "Update" : "Add"}
            onConfirm={handleSubmit(onConfirm)}
            isLoading={isWorking}
            isDisabled={!isValid}
            lang={lang}
        >
            <Form onSubmit={handleSubmit(onConfirm)}>
                <Controller
                    name="tagKey"
                    control={control}
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            label="Tag"
                            placeholder="Shorthand code..."
                            disabled={isEditing || isWorking}
                            errorMessage={errors.tagKey?.message}
                            required
                        />
                    )}
                />
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <Input.Text {...field} label="Name" placeholder="Display name..." disabled={isWorking} required />}
                />
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <Input.Text {...field} label="Description" placeholder="How to understand this evaluation..." disabled={isWorking} required />
                    )}
                />
            </Form>
        </Modal>
    );
}

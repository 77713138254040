import { Deposit, Escrow } from "@vaultinum/vaultinum-api";
import { ArchiveIcon, BaseLang, ModalButton, openNotificationWithIcon, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { archiveEscrow } from "../../../services/vaultService";

export function DepositEscrowActions({ deposits, escrow }: { deposits?: Deposit[]; escrow?: Escrow }): JSX.Element {
    const lang = useLang<BaseLang>();
    const [isLoading, setIsLoading] = useState(false);

    async function doArchive() {
        if (escrow) {
            setIsLoading(true);
            try {
                await archiveEscrow(escrow.id, true);
            } catch {
                openNotificationWithIcon({ type: "error", description: "Failed to archive escrow" });
            } finally {
                setIsLoading(false);
            }
        }
    }

    if ((escrow && escrow?.isArchived) || !deposits?.every(deposit => !deposit.iddn && !deposit.depositStoreId)) {
        return <></>;
    }

    return (
        <ModalButton.Confirm
            children="Confirm the archiving of this escrow"
            title="Archive escrow"
            icon={ArchiveIcon}
            onConfirm={doArchive}
            buttonProps={{
                type: "default",
                title: "Archive Escrow",
                icon: ArchiveIcon,
                isLoading: false,
                isDisabled: isLoading
            }}
            lang={lang}
        />
    );
}

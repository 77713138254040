import { Button, ButtonProps } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { handleAsyncActionWithMessages } from "../common/AsyncTools";

export default function AsyncActionButton({ isLoading, onClick, ...props }: Omit<ButtonProps, "isLoading" | "onClick"> & { isLoading?: boolean; onClick: () => Promise<string> }) {
    const [working, setWorking] = useState(false);
    const onButtonClick = async () => {
        setWorking(true);
        try {
            await handleAsyncActionWithMessages(onClick);
        } finally {
            setWorking(false);
        }
    };
    return <Button onClick={onButtonClick} isLoading={working || !!isLoading} {...props} />;
}

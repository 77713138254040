import { Survey } from "@vaultinum/vaultinum-api";
import { DEFAULT_LANG, getCountriesData } from "@vaultinum/vaultinum-sdk";

export function sameTarget(a?: Survey.NodeTarget, b?: Survey.NodeTarget | null) {
    if (!a || !b) {
        return false;
    }
    return a && b && a.nodeId === b.nodeId && a.type === b.type && a.property === b.property && a.subNodeId === b.subNodeId;
}

export function getCountryName(countryCode: string): string {
    const translatedCountryList = getCountriesData(DEFAULT_LANG);
    return translatedCountryList.find(country => country.cca2 === countryCode)?.name || countryCode;
}

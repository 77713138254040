import { AddCircleIcon, Button } from "@vaultinum/vaultinum-sdk";
import { SurveyTreeNode } from "./TreeNode";

export const ADD_NODE_KEY = ".add";

type AddNodeType = "preamble" | "section" | "question" | "option" | "fix" | "why" | "description";

export interface AddNodeButton {
    onClick: () => void;
    type: AddNodeType;
    hide?: boolean;
    text?: string;
}

const getTextFromType = (addNodeType: AddNodeType): string => {
    if (addNodeType === "question") {
        return "Question";
    }
    if (addNodeType === "why") {
        return "📕 Why";
    }
    if (addNodeType === "fix") {
        return "🧰 Fix";
    }
    if (addNodeType === "option") {
        return "Option";
    }
    if (addNodeType === "section") {
        return "Section";
    }
    if (addNodeType === "description") {
        return "Description";
    }
    return "unknown type";
};

export const createAddNode = (parentKey: string, key: string, buttons: AddNodeButton[]): SurveyTreeNode => ({
    parentKey,
    key: `${key}${ADD_NODE_KEY}`,
    icon: <AddCircleIcon />,
    data: null,
    title: (
        <div className="space-x-1">
            {buttons
                .filter(button => !button.hide)
                .map(({ onClick, type }) => (
                    <Button isLoading={false} key={type} size="sm" onClick={onClick}>
                        {getTextFromType(type)}
                    </Button>
                ))}
        </div>
    )
});

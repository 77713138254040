import React from "react";

export enum Shortcut {
    CopyNode = "Copy node",
    CutNode = "Cut node",
    PasteNode = "Paste node",
    DeselectNode = "Deselect node",
    DeleteNode = "Delete node",
    EditNodeText = "Edit node text",
    SelectNextNode = "Select next node",
    SelectPreviousNode = "Select previous node",
    UnfoldCurrentNode = "Unfold current node",
    FoldCurrentNode = "Fold current node",
    CreateNewNode = "Create new node"
}

export enum Key {
    C = "C",
    X = "X",
    V = "V",
    Escape = "Escape",
    Delete = "Delete",
    Backspace = "Backspace",
    Insert = "Insert",
    ArrowDown = "ArrowDown",
    ArrowUp = "ArrowUp",
    ArrowRight = "ArrowRight",
    ArrowLeft = "ArrowLeft",
    Enter = "Enter",
    Ctrl = "Ctrl"
}

export type ShortcutType = {
    name: Shortcut;
    keys: Key[];
    combinationKeys?: Key[];
    isAuthorized?: ({ isSurveyAlreadyPublished }: { isSurveyAlreadyPublished: boolean }) => boolean;
};

export function keyToSymbol(key: Key): string {
    switch (key) {
        case Key.Escape:
            return "ESC";
        case Key.ArrowDown:
            return "↓";
        case Key.ArrowUp:
            return "↑";
        case Key.ArrowRight:
            return "→";
        case Key.ArrowLeft:
            return "←";
        case Key.Ctrl:
            return window.navigator.platform.startsWith("Mac") ? "⌘" : "Ctrl";
        default:
            return key;
    }
}

export function hasPressedCtrlKey(e: React.KeyboardEvent<HTMLDivElement>) {
    return window.navigator.platform.startsWith("Mac") ? e.metaKey : e.ctrlKey;
}

export const SHORTCUTS: ShortcutType[] = [
    {
        name: Shortcut.CopyNode,
        keys: [Key.C],
        combinationKeys: [Key.Ctrl],
        isAuthorized: ({ isSurveyAlreadyPublished }) => !isSurveyAlreadyPublished
    },
    {
        name: Shortcut.CutNode,
        keys: [Key.X],
        combinationKeys: [Key.Ctrl],
        isAuthorized: ({ isSurveyAlreadyPublished }) => !isSurveyAlreadyPublished
    },
    {
        name: Shortcut.PasteNode,
        keys: [Key.V],
        combinationKeys: [Key.Ctrl],
        isAuthorized: ({ isSurveyAlreadyPublished }) => !isSurveyAlreadyPublished
    },
    {
        name: Shortcut.DeselectNode,
        keys: [Key.Escape]
    },
    {
        name: Shortcut.DeleteNode,
        keys: [Key.Delete],
        isAuthorized: ({ isSurveyAlreadyPublished }) => !isSurveyAlreadyPublished
    },
    {
        name: Shortcut.EditNodeText,
        keys: [Key.Insert]
    },
    {
        name: Shortcut.SelectNextNode,
        keys: [Key.ArrowDown]
    },
    {
        name: Shortcut.SelectPreviousNode,
        keys: [Key.ArrowUp]
    },
    {
        name: Shortcut.UnfoldCurrentNode,
        keys: [Key.ArrowRight]
    },
    {
        name: Shortcut.FoldCurrentNode,
        keys: [Key.ArrowLeft]
    },
    {
        name: Shortcut.CreateNewNode,
        keys: [Key.Enter],
        isAuthorized: ({ isSurveyAlreadyPublished }) => !isSurveyAlreadyPublished
    }
];

export const shortcutKeys = (shortcut: ShortcutType) =>
    shortcut.keys
        .map(key => [shortcut.combinationKeys?.map(combinationKey => `${keyToSymbol(combinationKey)} + `).join(""), keyToSymbol(key)].join(""))
        .join(" / ");

import { LANG_EN } from "@vaultinum/vaultinum-api";
import { copyToClipboard, formatDate, formatDateFromNow, RowCard, Separator } from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { ReactNode } from "react";
import Flag from "react-flagkit";

function Row({ text, value }: { text: string; value: ReactNode | undefined }): JSX.Element {
    return (
        <div
            className={classNames("flex justify-between gap-4 hover:bg-grey-extra-light", { "cursor-pointer": typeof value === "string" })}
            {...(typeof value === "string" && { onClick: () => copyToClipboard(value) })}
        >
            <span className="whitespace-nowrap font-light  capitalize text-grey-primary">{text}</span>
            <span>{value ?? "-"}</span>
        </div>
    );
}

export default function BaseInfoCard({
    name,
    creationDate,
    attributes,
    country,
    headerRight,
    footerLeft,
    footerRight
}: {
    name?: string;
    creationDate?: Date;
    attributes?: Record<string, ReactNode | undefined>;
    country?: string;
    headerRight?: ReactNode;
    footerLeft?: ReactNode;
    footerRight?: ReactNode;
}) {
    return (
        <RowCard
            {...(country && { icon: <Flag className="hidden md:flex" country={country} alt={country} title={country} /> })}
            rightChildren={
                <div className="flex h-full flex-col justify-between">
                    {headerRight}
                    {footerRight}
                </div>
            }
        >
            <div className="flex w-full flex-col gap-10 md:flex-row">
                <div className="w-40">
                    <div className="flex items-center gap-4">
                        <h2>{name}</h2>
                        {country && <Flag className="flex md:hidden" country={country} alt={country} title={country} />}
                    </div>
                    {creationDate && (
                        <div className="text-grey-primary">
                            Created {formatDateFromNow(creationDate, LANG_EN)} ({formatDate(creationDate, LANG_EN)})
                        </div>
                    )}
                    {footerLeft}
                </div>
                {attributes && (
                    <>
                        <Separator orientation="vertical" />
                        <div className="flex flex-col justify-center">
                            {Object.entries(attributes).map(([text, value]) => (
                                <Row key={text} text={text} value={value} />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </RowCard>
    );
}

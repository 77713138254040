import { UserProfile } from "@vaultinum/vaultinum-api";
import { formatName } from "@vaultinum/vaultinum-sdk";
import { BaseInfoCard } from "../../../components";

export function UserProfileInfoCard({ userProfile }: { userProfile: UserProfile }): JSX.Element {
    return (
        <BaseInfoCard
            country={userProfile.nationality}
            name={formatName(userProfile)}
            creationDate={userProfile.creationDate}
            attributes={{
                id: userProfile.id,
                email: userProfile.email,
                "preferred lang": userProfile.settings?.preferredLang || "-",
                phone: userProfile.cellPhone ? `${userProfile.cellPhone.countryCode || ""} ${userProfile.cellPhone.phoneNumber || ""}` : "-"
            }}
        />
    );
}

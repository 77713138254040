import { Escrow } from "@vaultinum/vaultinum-api";
import { BaseLang, Button, Modal, openNotificationWithIcon, useLang, UploadForm } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { uploadEscrowContract } from "../services/vaultService";

export default function UploadEscrowContractModal({ escrow }: { escrow: Escrow }): JSX.Element {
    const lang = useLang<BaseLang>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([]);

    const upload = async () => {
        setIsLoading(true);
        try {
            if (!files.length) {
                throw new Error("No files available");
            }
            const file = files[0];
            const formData = new FormData();
            formData.set("file", file, file.name);
            formData.set("escrowId", escrow.id);
            await uploadEscrowContract(formData);
            closeModal();
        } catch (e) {
            openNotificationWithIcon({ type: "error", message: "An error occured during the upload" });
        } finally {
            setIsLoading(false);
        }
    };

    const resetFileInput = () => {
        setFiles([]);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const hasSupplierAccountId = !!escrow.supplier?.accountId;

    return (
        <>
            <Button
                type="default"
                title={!hasSupplierAccountId ? "Supplier has no account yet, you cannot upload the contract" : undefined}
                isLoading={isLoading}
                onClick={openModal}
                children="Upload contract"
                isDisabled={!hasSupplierAccountId}
            />
            <Modal
                size="sm"
                isLoading={isLoading}
                onConfirm={upload}
                onClose={closeModal}
                isOpen={isOpen}
                okText="Upload"
                children={
                    <div className="space-y-6 p-3">
                        <UploadForm files={files} onDrop={setFiles} onRemove={resetFileInput} disabled={isLoading} />
                    </div>
                }
                lang={lang}
            />
        </>
    );
}

import { Question, Section } from "@vaultinum/vaultinum-api";

export function removeVariantFromSections(sections: Section[], key: string): typeof sections {
    return sections.map(section => ({
        ...section,
        ...(section.sections && { sections: removeVariantFromSections(section.sections, key) }),
        ...(section.questions && { questions: removeVariantFromQuestions(section.questions, key) })
    }));
}

function removeVariantFromQuestions(questions: Question[], key: string): typeof questions {
    return questions.map(question => ({
        ...question,
        ...(question.variants && { variants: Object.fromEntries(Object.entries(question.variants).filter(([k]) => k !== key)) }),
        options: question.options.map(option => ({
            ...option,
            ...(option.questions && { questions: removeVariantFromQuestions(option.questions, key) }),
            ...(option.attributes && { attributes: Object.fromEntries(Object.entries(option.attributes).filter(([k]) => k !== key)) })
        }))
    }));
}

export function removeVariantValueFromSections(sections: Section[], key: string, valuesToRemove: string[]): typeof sections {
    return sections.map(section => ({
        ...section,
        ...(section.sections && { sections: removeVariantValueFromSections(section.sections, key, valuesToRemove) }),
        ...(section.questions && { questions: removeVariantValueFromQuestions(section.questions, key, valuesToRemove) })
    }));
}

function removeVariantValueFromQuestions(questions: Question[], key: string, valuesToRemove: string[]): Question[] {
    if (!valuesToRemove.length) {
        return questions;
    }
    return questions.map(question => ({
        ...question,
        ...(question.variants && {
            variants: Object.fromEntries(Object.entries(question.variants).map(([k, vs]) => [k, k === key ? vs.filter(v => !valuesToRemove.includes(v)) : vs]))
        }),
        options: question.options.map(option => ({
            ...option,
            ...(option.questions && { questions: removeVariantValueFromQuestions(option.questions, key, valuesToRemove) }),
            ...(option.attributes && {
                attributes: Object.fromEntries(Object.entries(option.attributes).filter(([k, v]) => k === key && !valuesToRemove.includes(v)))
            })
        }))
    }));
}

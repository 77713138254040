import classnames from "classnames";
import { ReactNode } from "react";
import "./SimpleBadge.css";

// Default Antd Badge also includes a long listcolumns of hidden numbers used for animation purposes. It breaks the drag-n-grop ghost.
export default function SimpleBadge({
    className,
    content,
    children,
    size = "sm",
    title,
    onClick,
    offset,
    badgeColor
}: {
    content: number | string;
    children: ReactNode;
    className?: string;
    size?: "sm" | "default";
    title?: string;
    onClick?: () => void;
    offset?: boolean;
    badgeColor?: "primary" | "danger";
}) {
    return (
        <span className={classnames("simple-ant-badge ant-badge", className)}>
            {children}
            {!!content && (
                <sup
                    data-show="true"
                    className={classnames(`ant-scroll-number ant-badge-count ant-badge-count-${size} ant-badge-multiple-words bg-${badgeColor}`, {
                        "cursor-pointer": !!onClick,
                        "-mr-2.5 mt-0": offset
                    })}
                    title={title || content.toString()}
                    onClick={onClick}
                >
                    <span className="ant-scroll-number-only">
                        <p className="ant-scroll-number-only-unit">{content}</p>
                    </span>
                </sup>
            )}
        </span>
    );
}

import { Deposit, LANG_EN, SUPPORTED_LANGUAGES_CODES } from "@vaultinum/vaultinum-api";
import { BaseLang, Button, Modal, Select, openNotificationWithIcon, useLang, UploadForm } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { uploadVerifiedDepositReport } from "../services/vaultService";

export default function UploadDepositReportModal({ deposit }: { deposit: Deposit }): JSX.Element {
    const lang = useLang<BaseLang>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reportLang, setReportLang] = useState<string>(LANG_EN);
    const [files, setFiles] = useState<File[]>([]);

    const upload = async () => {
        setIsLoading(true);
        try {
            if (!files.length) {
                throw new Error("No files available");
            }
            const file = files[0];
            const formData = new FormData();
            formData.set("file", file, file.name);
            formData.set("depositId", deposit.iddn);
            formData.set("langCode", reportLang);
            await uploadVerifiedDepositReport(formData);
            closeModal();
        } catch (e) {
            openNotificationWithIcon({ type: "error", message: "An error occured during the upload" });
        } finally {
            setIsLoading(false);
        }
    };

    const resetFileInput = () => {
        setFiles([]);
    };

    const onChange = (selectedLang: string) => {
        setReportLang(selectedLang);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            {reportLang && (
                <>
                    <Button isLoading={isLoading} onClick={openModal}>
                        Upload PDF report
                    </Button>
                    <Modal
                        lang={lang}
                        size="sm"
                        onConfirm={upload}
                        isLoading={isLoading}
                        onClose={closeModal}
                        isOpen={isOpen}
                        okText="Upload"
                        children={
                            <div className="space-y-6 p-3">
                                <Select.Basic
                                    options={SUPPORTED_LANGUAGES_CODES.map(lang => ({ value: lang, label: lang }))}
                                    value={reportLang}
                                    onChange={onChange}
                                    label="Lang"
                                    className="flex items-center space-x-2"
                                />
                                <UploadForm files={files} onDrop={setFiles} onRemove={resetFileInput} disabled={isLoading} />
                            </div>
                        }
                    />
                </>
            )}
        </>
    );
}

import { LANG_EN, Survey } from "@vaultinum/vaultinum-api";
import {
    BaseLang,
    Button,
    Buttons,
    Column,
    DeleteIcon,
    EditIcon,
    EyeIcon,
    EyeInvisibleIcon,
    formatDateTime,
    getSurveys,
    message,
    ModalHelper,
    Table,
    Tag,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getKYS1SurveyLink } from "../../../services/routing.service";
import { getSurveysAccessesByStaffUserId } from "../../../services/surveyAccessService";
import { deleteSurveyWithKey, setSurveyVisible } from "../../../services/surveyService";
import AddOrEditSurveyModal from "./AddOrEditSurveyModal";

function getColumns(
    isAdmin: boolean,
    onView: (surveyKey: string) => void,
    onEdit: (survey: Survey) => void,
    toggleVisibility: (survey: Survey) => void,
    onDelete: (survey: Survey) => void
): Column<Survey>[] {
    return [
        {
            header: "Key",
            accessorKey: "key"
        },
        {
            header: "Name",
            accessorKey: "name"
        },
        {
            header: "Scope",
            accessorKey: "scope"
        },
        {
            header: "Created",
            accessorKey: "creationDate",
            cell: cell => formatDateTime(cell.getValue<Date>(), LANG_EN)
        },
        {
            header: "Visible",
            accessorKey: "visible",
            cell: cell => cell.getValue<boolean>() && <Tag type="info" message="Public" />
        },
        {
            header: "Latest version",
            accessorKey: "latestVersion"
        },
        {
            header: "Actions",
            enableSorting: false,
            enableColumnFilter: false,
            cell: cell => {
                const survey = cell.row.original;
                return (
                    <div className="flex gap-2">
                        <Button onClick={() => onView(survey.key)} isLoading={false}>
                            View
                        </Button>
                        <Buttons.Icon type="default" icon={EditIcon} onClick={() => onEdit(survey)} isLoading={false} title="Edit" />
                        {isAdmin && (
                            <>
                                <Buttons.Icon
                                    type="default"
                                    icon={survey.visible ? EyeInvisibleIcon : EyeIcon}
                                    onClick={() => toggleVisibility(survey)}
                                    isLoading={false}
                                    title={survey.visible ? "Hide survey" : "Publish survey"}
                                />
                                <Buttons.Icon type="default" icon={DeleteIcon} onClick={() => onDelete(survey)} isLoading={false} title="Delete" />
                            </>
                        )}
                    </div>
                );
            }
        }
    ];
}

export default function SurveysView({ isAdmin, staffUserId }: { isAdmin: boolean; staffUserId: string }) {
    const lang = useLang<BaseLang>();
    const navigate = useNavigate();
    const [surveys, setSurveys] = useState<Survey[]>([]);
    const [surveyAccesses, setSurveyAccesses] = useState<Survey.Access[]>([]);
    const [selectedSurvey, setSelectedSurvey] = useState<Survey | null>(null);
    const [showNewSurveyDialog, setShowNewSurveyDialog] = useState(false);

    useEffect(() => getSurveys(setSurveys), []);
    useEffect(() => getSurveysAccessesByStaffUserId(staffUserId, setSurveyAccesses), [staffUserId]);

    const onSurveyDelete = (survey: Survey) => {
        ModalHelper.Confirm({
            title: "Delete survey",
            children: `Delete survey with key: ${survey.key}? Action will fail if there is any client using this survey revision.`,
            onConfirm: () => deleteSurveyWithKey(survey.key).catch(e => message.error(e.message)),
            lang
        });
    };

    const toggleSurveyVisibility = (survey: Survey) => {
        ModalHelper.Confirm({
            title: "Survey visibility",
            children: `Make survey "${survey.name}" ${!survey.visible ? "visible" : "hidden"} to clients?`,
            onConfirm: () => setSurveyVisible(survey.key, !survey.visible),
            lang
        });
    };

    const onView = (surveyKey: string) => navigate(getKYS1SurveyLink(surveyKey));
    const onEdit = (survey: Survey) => {
        setSelectedSurvey(survey);
        setShowNewSurveyDialog(true);
    };
    const onToggle = (survey: Survey) => {
        toggleSurveyVisibility(survey);
    };

    const columns = getColumns(isAdmin, onView, onEdit, onToggle, onSurveyDelete);

    return (
        <div className="h-full w-full flex-col gap-2">
            <AddOrEditSurveyModal
                isOpen={showNewSurveyDialog}
                onClose={() => {
                    setShowNewSurveyDialog(false);
                    setSelectedSurvey(null);
                }}
                survey={selectedSurvey}
            />
            {isAdmin && <Button isLoading={false} onClick={() => setShowNewSurveyDialog(true)} children="New survey" />}
            <div className="h-full overflow-hidden">
                <Table<Survey> data={surveys.filter(survey => isAdmin || surveyAccesses.some(({ surveyKey }) => surveyKey === survey.key))} columns={columns} />
            </div>
        </div>
    );
}

import { Escrow, LANG_EN } from "@vaultinum/vaultinum-api";
import { Button, Column, formatDateTime, Table, Tag } from "@vaultinum/vaultinum-sdk";
import { useNavigate } from "react-router-dom";
import { escrowProgressStatusToType } from "../../../helpers";
import { getEscrowLink } from "../../../services/routing.service";

function getEscrowsColumn(redirectToEscrowDetail: (escrowId: string) => void): Column<Escrow>[] {
    return [
        {
            header: "Creation date",
            accessorKey: "creationDate",
            cell: cell => formatDateTime(cell.getValue<Date>(), LANG_EN),
            defaultSort: "desc"
        },
        {
            header: "Supplier",
            accessorKey: "supplier",
            accessorFn: row => row.supplier?.company?.name || row.supplier?.contacts?.map(contact => contact.email).join(", ")
        },
        {
            header: "Beneficiary",
            accessorKey: "beneficiary",
            accessorFn: row => {
                if (row.type === Escrow.Type.ACCESS_CLAUSE) {
                    return "N/A";
                }
                return row.beneficiary?.company?.name || row.beneficiary?.contacts?.map(contact => contact.email).join(", ");
            },
            cell: cell => {
                const beneficiary = cell.getValue<string>();
                return beneficiary === "N/A" ? <div className="text-neutral-300">{beneficiary}</div> : beneficiary;
            }
        },
        {
            header: "Type",
            accessorKey: "type"
        },
        {
            header: "Progress status",
            accessorKey: "progressStatus",
            cell: cell => {
                const progressStatus = cell.getValue<Escrow.ProgressStatus>();
                return <Tag type={escrowProgressStatusToType(progressStatus)} message={progressStatus?.replace("_", " ")} />;
            }
        },
        {
            header: "Actions",
            accessorKey: "actions",
            enableSorting: false,
            enableColumnFilter: false,
            cell: cell => (
                <div className="flex space-x-1">
                    <Button isLoading={false} size="sm" onClick={() => redirectToEscrowDetail(cell.row.original.id)}>
                        View
                    </Button>
                </div>
            )
        }
    ];
}

export default function Escrows({ escrows }: { escrows: Escrow[] }): JSX.Element {
    const navigate = useNavigate();

    const redirectToEscrowDetail = (escrowId: string) => {
        navigate(getEscrowLink(escrowId));
    };

    const escrowsColumn = getEscrowsColumn(redirectToEscrowDetail);

    return (
        <div className="flex h-full max-h-96 items-stretch overflow-hidden">
            <Table<Escrow> data={escrows} columns={escrowsColumn} />
        </div>
    );
}

import { Deposit } from "@vaultinum/vaultinum-api";
import { Button, DownloadIcon, openNotificationWithIcon } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { downloadCertificate } from "../../../services/vaultService";

export function CertificateDownloadButton({ deposit }: { deposit: Deposit }): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);

    async function download() {
        setIsLoading(true);
        try {
            await downloadCertificate(deposit.depositStoreId, deposit.iddn);
        } catch {
            openNotificationWithIcon({ type: "error", description: "Failed to download certificate" });
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Button onClick={download} icon={DownloadIcon} isLoading={isLoading}>
            Download certificate
        </Button>
    );
}

import { Survey } from "@vaultinum/vaultinum-api";
import { User } from "@vaultinum/vaultinum-sdk";
import { createContext } from "react";

export interface SurveyBuilderContextProps {
    comments: Survey.Comment[];
    surveyVersion: Survey.Version;
    surveyLang: Survey.Lang;
    defaultSurveyLang?: Survey.Lang;
    suggestionMode: boolean;
    user: User;
    isWorking: boolean;
    setIsWorking: (isWorking: boolean) => void;
}

export const SurveyBuilderContext = createContext<SurveyBuilderContextProps>({} as SurveyBuilderContextProps);

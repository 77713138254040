/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import { calculateSurveyScoreImpact, getNodeTargetValue, query, Question, Section, Survey } from "@vaultinum/vaultinum-api";
import {
    AntdColumnType,
    AntdTable,
    BaseLang,
    message,
    MessageIcon,
    ModalHelper,
    QuestionCircleTwoToneIcon,
    Tag,
    Tooltip,
    useLang,
    WarningFilledIcon
} from "@vaultinum/vaultinum-sdk";
import { last } from "lodash";
import React, { HTMLAttributes, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { deleteDataTargetFromSurveyLang } from "../../../../../common/SurveyTools";
import SearchBar, { SearchBarValues } from "../../../../../components/SearchBar";
import SimpleBadge from "../../../../../components/SimpleBadge";
import { SearchContext } from "../../../../../contexts/SearchContext";
import { updateLangText } from "../../../../../services/surveyLangService";
import { nodeTypeToGroupKey } from "../../../../../services/surveyService";
import confirmNodeDeletion from "../../components/ConfirmNodeDeletion";
import { NodeSummaryScoreImpact, NodeTagScoreImpact } from "../../components/NodeScoreImpact";
import { getSurveyVersionIssues } from "../HealthCheckTools";
import { hasPressedCtrlKey, Key, Shortcut, SHORTCUTS } from "../ShortcutsHelper";
import { SurveyBuilderContext } from "../SurveyBuilderContext";
import DragableBodyRow from "./DragableBodyRow";
import { ADD_NODE_KEY } from "./nodes/AddNode";
import { surveyToTreeNodes } from "./nodes/SurveyTreeNodeBuilder";
import {
    addChildrenToNode,
    addNode,
    checkCanAddChildren,
    cloneNode,
    containsNodeTarget,
    convertTreeNodesToSurvey,
    filterTreeNodes,
    getNodeAndChildren,
    getNodePath,
    getNodeTargets,
    moveTreeNode,
    nodeMatchSearch,
    removeTreeNodes
} from "./nodes/SurveyTreeNodeTools";
import { findNodeWithKey, SurveyTreeNode } from "./nodes/TreeNode";
import SurveyCommentPanel from "./SurveyCommentPanel";
import { SurveyEditableNodesContext } from "./SurveyEditableNodesContextProps";
import "./SurveyTableView.css";
import { HealthCheckInfoBar } from "./HealthCheckInfoBar";

type CopyOperation = {
    op: "cut" | "copy";
    nodes: SurveyTreeNode[];
};

const KeyDisplay = ({ node }: { node: SurveyTreeNode }) => {
    if (node.target?.type === Survey.NodeType.OPTION && node.target.property === Survey.NodeTargetProperty.TEXT) {
        return <span className="key-display">{last(node.key.split("-"))}.&nbsp;</span>;
    }
    if (
        node.target?.type &&
        [Survey.NodeType.QUESTION, Survey.NodeType.SECTION].includes(node.target.type) &&
        [Survey.NodeTargetProperty.TEXT, Survey.NodeTargetProperty.TITLE].includes(node.target.property)
    ) {
        return <span className="key-display">{node.key}&nbsp;</span>;
    }
    return null;
};

const QuestionCountDisplay = React.memo(
    ({
        node,
        filteredVariants,
        surveyVariants
    }: {
        node: SurveyTreeNode;
        filteredVariants?: { [key: string]: string[] };
        surveyVariants?: Survey.Variant[];
    }) => {
        let questionsCount = 0;
        if (node.target?.type === Survey.NodeType.SECTION) {
            questionsCount = query(node.data as Section).getQuestionCount({ filteredVariants, surveyVariants });
        } else if (node.target?.type === Survey.NodeType.QUESTION) {
            questionsCount = query(node.data as Question).getQuestionCount({ filteredVariants, surveyVariants }) - 1;
        } else if (node.target?.type === Survey.NodeType.OPTION) {
            questionsCount = query(node.data as Question.Option).getQuestionCount({ filteredVariants, surveyVariants });
        }
        if (questionsCount > 0) {
            return (
                <Tooltip title={`${questionsCount} sub-questions`}>
                    {questionsCount} <QuestionCircleTwoToneIcon isTwoToneColor color="blue" />
                </Tooltip>
            );
        }
        return null;
    }
);

const nextNodeToSelect = (nodes: SurveyTreeNode[], selectedNode: SurveyTreeNode, expandedRowKeys: string[], down: boolean): SurveyTreeNode | null => {
    if (down && selectedNode.children && selectedNode.children?.length > 0 && expandedRowKeys.includes(selectedNode.key)) {
        return selectedNode.children[0];
    }
    const parentNode = findNodeWithKey(nodes, selectedNode.parentKey);
    const children = parentNode ? parentNode.children : nodes;
    if (children) {
        // Find next sibling node
        const selectedNodeIndex = children.indexOf(selectedNode);
        if (down) {
            if (selectedNodeIndex < children.length - 1) {
                // There is a sibling after the node
                return children[selectedNodeIndex + 1];
            }
        } else if (selectedNodeIndex > 0) {
            // There is a sibling before the node
            const siblingNode = children[selectedNodeIndex - 1];
            // Check if previous sibling is expanded, selects its last child if true
            if (siblingNode?.children?.length && expandedRowKeys.includes(siblingNode.key)) {
                return siblingNode.children[siblingNode.children.length - 1];
            }
            return siblingNode;
        } else if (parentNode) {
            // No previous sibling, select parent node
            return parentNode;
        }
    }
    if (parentNode) {
        return nextNodeToSelect(
            nodes,
            parentNode,
            expandedRowKeys.filter(key => key !== parentNode.key),
            down
        );
    }
    return null;
};

export default function SurveyTableView({
    surveyVersion,
    surveyLangs,
    setSurveyVersion
}: {
    surveyVersion: Survey.Version;
    surveyLangs: Survey.Lang[];
    setSurveyVersion: (surveyVersion: Survey.Version) => void;
}) {
    const lang = useLang<BaseLang>();
    const { user, comments, suggestionMode, defaultSurveyLang, surveyLang } = useContext(SurveyBuilderContext);
    const [searchMatchingNodes, setSearchMatchingNodes] = useState<SurveyTreeNode[]>([]);
    const [selectedCommentTreeNode, setSelectedCommentTreeNode] = useState<SurveyTreeNode | null>(null);
    const [selectedNodeKeys, setSelectedNodeKeys] = useState<string[]>([]);
    const [ctrlPressed, setCtrlPressed] = useState(false);
    const [copiedNodes, setCopiedNodes] = useState<CopyOperation | undefined>(undefined);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [editedTarget, setEditedTarget] = useState<Survey.NodeTarget | undefined>(undefined);
    const [filteredVariants, setFilteredVariants] = useState<{ [key: string]: string[] }>();
    // Memo used as surveyToTreeNodes is CPU intensive
    const treeNodes = useMemo(
        () =>
            surveyToTreeNodes(
                lang,
                surveyVersion,
                surveyLang,
                defaultSurveyLang,
                surveyLangs,
                setSurveyVersion,
                user,
                comments.filter(comm => !comm.isResolved && comm.changeSuggestion)
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [surveyVersion, surveyLang, defaultSurveyLang, surveyLangs, setSurveyVersion, user, comments]
    );

    const [searchValues, setSearchValues] = useState<SearchBarValues>({ search: "" });
    const [refreshOnly, setRefreshOnly] = useState(false);
    const [replace, setReplace] = useState("");

    const searchNodes = useCallback((values: SearchBarValues) => {
        setRefreshOnly(false);
        setSearchValues(values);
    }, []);

    const filterNodes = (searchValue: string, data: SurveyTreeNode[]) => {
        const nodes = data.flatMap(getNodeAndChildren);
        const searchMatchingNodes = nodes.filter(node => nodeMatchSearch(node, surveyLang, searchValue));
        const paths = nodes.flatMap(node => getNodePath(nodes, node));
        setExpandedRowKeys(paths);
        setSearchMatchingNodes(searchMatchingNodes);
    };

    // Memo used as filterTreeNodes can be CPU intensive
    const data = useMemo(() => {
        let data: SurveyTreeNode[] = treeNodes || [];
        if (!refreshOnly) {
            if (filteredVariants && Object.keys(filteredVariants).length) {
                const filteredSurveyVersion = {
                    ...surveyVersion,
                    sections: query(surveyVersion).filterByVariant({ filteredVariants, surveyVariants: surveyVersion.variants })
                };
                data = surveyToTreeNodes(
                    lang,
                    filteredSurveyVersion,
                    surveyLang,
                    defaultSurveyLang,
                    surveyLangs,
                    setSurveyVersion,
                    user,
                    comments.filter(comm => !comm.isResolved && comm.changeSuggestion)
                );
            } else {
                data = filterTreeNodes(treeNodes, surveyLang, searchValues.search, suggestionMode);
                if (searchValues.search) {
                    filterNodes(searchValues.search, data);
                } else {
                    setSearchMatchingNodes([]);
                }
            }
        } else if (replace) {
            const filtered = filterTreeNodes(treeNodes, surveyLang, replace, suggestionMode);
            data = filtered.length ? filtered : searchMatchingNodes;
            filterNodes(replace, data);
        }
        return data;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshOnly, treeNodes, surveyLang, replace, searchValues, suggestionMode, filteredVariants]);

    const surveyIssues = useMemo(() => getSurveyVersionIssues(surveyVersion, surveyLang), [surveyVersion, surveyLang]);
    const scoreImpact = useMemo(() => calculateSurveyScoreImpact(surveyVersion), [surveyVersion]);

    const toggleExpandedRowKey = useCallback(
        (rowKey: string, expanded: boolean) => {
            setExpandedRowKeys(expanded ? [...expandedRowKeys, rowKey] : expandedRowKeys.filter(key => key !== rowKey));
        },
        [expandedRowKeys]
    );

    useEffect(() => {
        if (!editedTarget) {
            document.getElementById("suvey-table-wrapper")?.focus();
        }
    }, [editedTarget]);

    const isSurveyAlreadyPublished = surveyVersion.publishedLangs.includes(surveyLang.lang);

    const makeNodeVisible = (targetNode: SurveyTreeNode) => {
        const nodes = data.flatMap(getNodeAndChildren);
        const path = getNodePath(nodes, targetNode);
        setExpandedRowKeys(path);
        setSelectedNodeKeys([targetNode.key]);
    };

    const moveRow = useCallback(
        (fromKey: string, toKey: string | undefined, insertAsChild: boolean) => {
            if (!toKey || toKey.endsWith(ADD_NODE_KEY)) {
                return;
            }
            const dragNode = findNodeWithKey<SurveyTreeNode>(treeNodes, fromKey);
            const targetNode = findNodeWithKey(data, toKey);
            if (dragNode && targetNode) {
                const newData = moveTreeNode(treeNodes, dragNode, targetNode, insertAsChild);
                const newSurvey = convertTreeNodesToSurvey(surveyVersion, newData);
                setSurveyVersion(newSurvey);
            }
        },
        [data, treeNodes, surveyVersion, setSurveyVersion]
    );

    const onKeyCapture = (e: React.KeyboardEvent<HTMLDivElement>) => {
        setCtrlPressed(e.ctrlKey);
        if (editedTarget) {
            return;
        }
        const shortcut = SHORTCUTS.find(shortcut => shortcut.keys.some(key => key.toLocaleLowerCase() === e.key.toLocaleLowerCase()));
        if (shortcut) {
            if (shortcut.combinationKeys?.every(key => key === Key.Ctrl && !hasPressedCtrlKey(e))) {
                return;
            }
            if (!shortcut?.isAuthorized || shortcut.isAuthorized({ isSurveyAlreadyPublished })) {
                const selectedNodes = selectedNodeKeys.map(key => findNodeWithKey(data, key)) as SurveyTreeNode[];
                switch (shortcut.name) {
                    case Shortcut.CopyNode:
                        setCopiedNodes({ op: "copy", nodes: selectedNodes });
                        break;
                    case Shortcut.CutNode:
                        setCopiedNodes({ op: "cut", nodes: selectedNodes });
                        break;
                    case Shortcut.PasteNode:
                        if (!selectedNodes.length || !copiedNodes) {
                            return;
                        }
                        doPaste(selectedNodes[0], copiedNodes);
                        break;
                    case Shortcut.DeselectNode:
                        setSelectedNodeKeys([]);
                        setCopiedNodes(undefined);
                        break;
                    case Shortcut.DeleteNode:
                        confirmNodeDeletion(lang, doDelete);
                        break;
                    case Shortcut.EditNodeText:
                        if (!selectedNodes.length) {
                            return;
                        }
                        setEditedTarget(selectedNodes[0].target);
                        break;
                    case Shortcut.SelectNextNode:
                        if (selectedNodes.length) {
                            const nextSelectedNode = nextNodeToSelect(data, selectedNodes[0], expandedRowKeys, true);
                            if (nextSelectedNode) {
                                setSelectedNodeKeys([nextSelectedNode.key]);
                                e.preventDefault();
                            }
                        } else if (data.length) {
                            setSelectedNodeKeys([data[0].key]);
                        }
                        break;
                    case Shortcut.SelectPreviousNode:
                        if (selectedNodes.length !== 1) {
                            return;
                        }
                        const nextSelectedNode = nextNodeToSelect(data, selectedNodes[0], expandedRowKeys, false);
                        if (nextSelectedNode) {
                            setSelectedNodeKeys([nextSelectedNode.key]);
                            e.preventDefault();
                        }
                        break;
                    case Shortcut.UnfoldCurrentNode:
                        if (selectedNodes.length !== 1) {
                            return;
                        }
                        toggleExpandedRowKey(selectedNodeKeys[0], true);
                        break;
                    case Shortcut.FoldCurrentNode:
                        if (selectedNodes.length !== 1) {
                            return;
                        }
                        toggleExpandedRowKey(selectedNodeKeys[0], false);
                        break;
                    case Shortcut.CreateNewNode:
                        if (selectedNodes.length !== 1) {
                            return;
                        }
                        const selectedNode = selectedNodes[0];
                        if (!selectedNode.target) {
                            return;
                        }
                        const parentNode = e.ctrlKey ? findNodeWithKey(data, selectedNode.parentKey) : selectedNode;
                        let nodeType: Survey.NodeType | null = null;
                        if (selectedNode.target.type === Survey.NodeType.SECTION) {
                            nodeType = e.ctrlKey ? Survey.NodeType.SECTION : Survey.NodeType.QUESTION;
                        }
                        if (selectedNode.target.type === Survey.NodeType.QUESTION) {
                            nodeType = e.ctrlKey ? Survey.NodeType.QUESTION : Survey.NodeType.OPTION;
                        }
                        if (selectedNode.target.type === Survey.NodeType.OPTION) {
                            nodeType = e.ctrlKey ? Survey.NodeType.OPTION : Survey.NodeType.QUESTION;
                        }
                        if (parentNode?.target && nodeType) {
                            const updatedSurveyVersion = addNode(surveyVersion, surveyLang, parentNode.target, nodeType, user);
                            setSurveyVersion(updatedSurveyVersion);
                            if (!expandedRowKeys.includes(parentNode.key)) {
                                toggleExpandedRowKey(parentNode.key, true);
                            }
                            e.preventDefault();
                        }
                        break;
                    default:
                        e.preventDefault();
                        break;
                }
            }
        }
    };

    const doPaste = (targetNode: SurveyTreeNode, copiedNodes: CopyOperation) => {
        const selectedNodes = selectedNodeKeys.map(key => findNodeWithKey(data, key)) as SurveyTreeNode[];
        try {
            checkCanAddChildren(targetNode, copiedNodes.nodes);
            if (copiedNodes.op === "cut") {
                let updatedData = removeTreeNodes(treeNodes, copiedNodes.nodes);
                updatedData = addChildrenToNode(updatedData, selectedNodes[0], copiedNodes.nodes);
                const updatedSurvey = convertTreeNodesToSurvey(surveyVersion, updatedData);
                setSurveyVersion(updatedSurvey);
            } else if (copiedNodes.op === "copy") {
                const clonedNodes = copiedNodes.nodes.map(node => cloneNode(node, surveyLang, user));
                const updatedData = addChildrenToNode(treeNodes, selectedNodes[0], clonedNodes);
                const updatedSurvey = convertTreeNodesToSurvey(surveyVersion, updatedData);
                setSurveyVersion(updatedSurvey);
            }
        } catch (error) {
            if (error instanceof Error) {
                ModalHelper.Info({
                    title: `Can't ${copiedNodes.op} `,
                    children: error.message,
                    lang
                });
            }
        }
    };

    const doDelete = async () => {
        const selectedNodes = selectedNodeKeys.map(key => findNodeWithKey(data, key)) as SurveyTreeNode[];
        const nextSelectedNode = nextNodeToSelect(data, selectedNodes[0], expandedRowKeys, false);
        let updatedSuveryVersion = surveyVersion;
        if (nextSelectedNode) {
            setSelectedNodeKeys([nextSelectedNode.key]);
        }
        for (const node of selectedNodes) {
            if (node.target) {
                try {
                    updatedSuveryVersion = await deleteDataTargetFromSurveyLang(surveyVersion, node.target, surveyLang, surveyLangs, user.uid);
                } catch (err) {
                    void message.error(err.message);
                    return;
                }
            }
        }
        const updatedData = removeTreeNodes(treeNodes, selectedNodes);
        const updatedSurvey = convertTreeNodesToSurvey(updatedSuveryVersion, updatedData);
        setSurveyVersion(updatedSurvey);
    };

    const renderInfoCol = useCallback(
        (text: string, node: SurveyTreeNode) => {
            const nodeTargets = getNodeTargets(node);
            const nodeIssues = surveyIssues.filter(issue => containsNodeTarget(nodeTargets, issue.target));
            const nodeCommentsIncludingLeafs = comments.filter(comment => containsNodeTarget(nodeTargets, comment.target));
            return (
                <div className="comment-action flex items-center" onClick={() => setSelectedCommentTreeNode(node)}>
                    {!!nodeCommentsIncludingLeafs.length && (
                        <SimpleBadge className="comment-count-badge" content={nodeCommentsIncludingLeafs.length}>
                            <MessageIcon />
                        </SimpleBadge>
                    )}
                    {!nodeCommentsIncludingLeafs.length && !nodeIssues.length && (
                        <span className="no-comments">
                            <MessageIcon />
                        </span>
                    )}
                    {!!nodeIssues.length && (
                        <SimpleBadge className="comment-count-badge" content={nodeIssues.length}>
                            <WarningFilledIcon color="orange" />
                        </SimpleBadge>
                    )}
                </div>
            );
        },
        [surveyIssues, comments]
    );

    const renderNameCol = useCallback((text: string, node: SurveyTreeNode) => {
        let variants: { [key: string]: string[] } | undefined;
        return (
            <div className="flex w-full">
                <span className="mr-2">{node.icon}</span>
                <KeyDisplay node={node} />
                {variants && node.target?.property === Survey.NodeTargetProperty.TEXT && (
                    <div className="mr-1 flex space-x-1">
                        {Object.entries(variants).map(([key, values]) => (
                            <Tag key={key} message={`${key}[${values.join(", ")}]`} />
                        ))}
                    </div>
                )}
                {node.title}
            </div>
        );
    }, []);

    const columns: AntdColumnType<SurveyTreeNode> = [
        {
            title: "Name",
            width: 400,
            fixed: true,
            render: renderNameCol
        },
        {
            title: "Info",
            className: "center-col",
            width: 70,
            render: renderInfoCol
        },
        {
            title: "Questions",
            className: "center-col",
            width: 90,
            ellipsis: true,
            render: (text: string, node: SurveyTreeNode) => {
                return <QuestionCountDisplay node={node} filteredVariants={filteredVariants} surveyVariants={surveyVersion.variants} />;
            }
        },
        ...(surveyVersion.variants || []).map(variant => ({
            title: variant.key,
            className: "center-col variant",
            width: 80,
            key: variant.key,
            render: (value: string, node: SurveyTreeNode) => node.variants?.[variant.key],
            filters: variant.values.map(value => ({
                text: value,
                value
            }))
        })),
        {
            title: "Option",
            className: "center-col options",
            dataIndex: "option",
            width: 100,
            ellipsis: true
        },
        ...surveyVersion.evaluationTags.map(tag => ({
            title: (
                <Tooltip title={`Score weight = ${Math.round(scoreImpact.tagWeights[tag] * 100)}%`}>
                    <div>{surveyLang.evaluationTag[tag]?.name || ""}</div>
                </Tooltip>
            ),
            className: "center-col eval-tag",
            width: 80,
            render: (text: string, node: SurveyTreeNode) => <NodeTagScoreImpact node={node} scoreImpact={scoreImpact} tag={tag} />,
            ellipsis: true
        })),
        {
            title: "Weight",
            width: 50,
            render: (text: string, node: SurveyTreeNode) => node.weight
        },
        {
            title: "Score",
            width: 50,
            render: (text: string, node: SurveyTreeNode) => <NodeSummaryScoreImpact scoreImpact={scoreImpact} nodeTarget={node.target} />
        }
    ];

    const searchAndReplace = async (searchValue: string, replaceValue: string) => {
        const newData: { [key: string]: string } = {};
        searchMatchingNodes
            .map(node => node.target)
            .forEach(node => {
                if (node) {
                    const originalText = getNodeTargetValue(node, surveyLang);
                    if (originalText) {
                        const searchPattern = new RegExp(searchValue, "g");
                        const newText = originalText.replaceAll(searchPattern, replaceValue);
                        const key =
                            node.property === Survey.NodeTargetProperty.FIXES && node.subNodeId
                                ? `${nodeTypeToGroupKey(node.type)}.${node.nodeId}.fixes.${node.subNodeId}`
                                : `${nodeTypeToGroupKey(node.type)}.${node.nodeId}.${node.property}`;
                        newData[key] = newText;
                    }
                }
            });
        setRefreshOnly(true);
        if (Object.keys(newData).length) {
            await updateLangText(surveyLang, user.uid, newData);
        }
    };

    const onRow = (node: any, index?: number) =>
        ({
            node,
            index,
            moveRow,
            canDragRows: !isSurveyAlreadyPublished
        }) as HTMLAttributes<unknown>;

    return (
        <div className="survey-table-view">
            <HealthCheckInfoBar surveyVersion={surveyVersion} surveyLang={surveyLang} />
            <SearchBar setSearchValues={searchNodes} setReplace={setReplace} onReplace={searchAndReplace} />
            {searchMatchingNodes.length > 0 && (
                <div className="mb-1">
                    <Tag message={`${searchMatchingNodes.length} Matches`} />
                </div>
            )}
            {selectedCommentTreeNode?.target && (
                <SurveyCommentPanel
                    user={user}
                    node={selectedCommentTreeNode}
                    comments={comments}
                    issues={surveyIssues}
                    onClose={() => setSelectedCommentTreeNode(null)}
                    selectedSurveyLang={surveyLang}
                    jumpToNode={node => makeNodeVisible(node)}
                />
            )}
            {
                <DndProvider backend={HTML5Backend}>
                    <SearchContext.Provider
                        value={{
                            search: searchValues.search
                        }}
                    >
                        <SurveyEditableNodesContext.Provider
                            value={{
                                selectedNodeKeys,
                                setSelectedNodeKeys,
                                ctrlPressed,
                                copiedNodes,
                                toggleExpandedRowKey,
                                editedTarget,
                                setEditedTarget
                            }}
                        >
                            <div
                                id="suvey-table-wrapper"
                                className="table-wrapper"
                                tabIndex={0}
                                onKeyDownCapture={onKeyCapture}
                                onKeyUpCapture={e => setCtrlPressed(e.ctrlKey)}
                            >
                                <AntdTable
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    scroll={{ x: 2400 }}
                                    components={{
                                        body: { row: DragableBodyRow }
                                    }}
                                    bordered
                                    size="small"
                                    rowClassName={(node: SurveyTreeNode) => `node-${node.target?.type.toLowerCase()}`}
                                    expandable={{
                                        expandedRowKeys,
                                        onExpand: (expanded, record) => toggleExpandedRowKey(record.key, expanded)
                                    }}
                                    onRow={onRow}
                                    onChange={(_pagination, filters, _sorter, extra) => {
                                        if (extra.action === "filter") {
                                            Object.keys(filters).forEach(key => {
                                                const values = filters[key] as string[];
                                                if (values?.length) {
                                                    setFilteredVariants(prev => ({ ...prev, [key]: values }));
                                                } else {
                                                    setFilteredVariants(prev => {
                                                        const newFilterVariants = { ...prev };
                                                        delete newFilterVariants[key];
                                                        if (!Object.keys(newFilterVariants).length) {
                                                            return undefined;
                                                        }
                                                        return newFilterVariants;
                                                    });
                                                }
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </SurveyEditableNodesContext.Provider>
                    </SearchContext.Provider>
                </DndProvider>
            }
        </div>
    );
}

import { EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import { Alert, Button, Controller, Form, Input, useForm, UserCredential, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";

export default function CredentialsForm({
    actionName,
    doAction
}: {
    actionName: string;
    doAction: (email: string, password: string) => Promise<UserCredential>;
}): JSX.Element {
    const [working, setWorking] = useState(false);
    const [error, setError] = useState("");

    const schema = yup.object({
        email: useRequiredString().trim().matches(EMAIL_REGEX, "Invalid email address"),
        password: useRequiredString()
    });

    const { handleSubmit, control, getValues } = useForm<{ email: string; password: string }>({
        schema
    });

    const doFormSubmit = async () => {
        const { email, password } = getValues();
        setWorking(true);
        setError("");
        try {
            await doAction(email, password);
        } catch (error) {
            setError(String(error));
        }
        setWorking(false);
    };

    return (
        <Form onSubmit={handleSubmit(doFormSubmit)}>
            <Controller
                name="email"
                control={control}
                render={({ field }) => <Input.Email {...field} label="Email" placeholder="Email..." disabled={working} />}
            />
            <Controller
                name="password"
                control={control}
                render={({ field }) => <Input.Password {...field} label="Password" placeholder="Password..." disabled={working} />}
            />
            {error && (
                <div className="mb-5">
                    <Alert.Danger message={error} />
                </div>
            )}
            <Button htmlType="submit" isLoading={working} children={actionName} />
        </Form>
    );
}

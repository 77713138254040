import { Account, isDefined, StaffUser, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import {
    AppCode,
    BaseLang,
    Button,
    Controller,
    downloadImage,
    Fieldset,
    Form,
    getLogoByDomainId,
    getWhiteLabelDomainByFQDN,
    ImagePreview,
    Input,
    mapAppsToDefaultConfiguration,
    message,
    Modal,
    Select,
    SUPPORTED_LANGUAGES,
    ToggleButton,
    uploadDomainLogo,
    UploadForm,
    useForm,
    UseFormReturn,
    useLang,
    useModal,
    useRequiredString,
    yup
} from "@vaultinum/vaultinum-sdk";
import { camelCase } from "lodash";
import { Dispatch, forwardRef, Ref, SetStateAction, useEffect, useImperativeHandle, useRef, useState } from "react";
import { DEFAULT_DOMAIN } from "../../../common/Global";
import { getAllAccounts } from "../../../services/accountService";
import { addWhiteLabelDomain, updateWhiteLabelDomain } from "../../../services/whiteLabelService";

const ACCEPTED_FILE_TYPES = { "image/png": [".png"], "image/jpg": [".jpg", ".jpeg"] };
const FILE_TYPES = Object.values(ACCEPTED_FILE_TYPES).flat();
const SUPPORTED_APPS = [AppCode.FULL_AUDIT, AppCode.DEPOSIT] as const;

const DomainForm = forwardRef(
    (
        {
            form,
            isWorking,
            files,
            setFiles,
            onConfirm,
            domain
        }: {
            form: UseFormReturn<WhiteLabelDomain>;
            isWorking: boolean;
            files: File[];
            setFiles: Dispatch<SetStateAction<File[]>>;
            onConfirm: () => Promise<void>;
            domain?: WhiteLabelDomain;
        },
        ref?: Ref<{ reset: () => void }>
    ): JSX.Element => {
        const { control, reset, setValue } = form;
        const [accounts, setAccounts] = useState<Account[]>([]);
        const [apps, setApps] = useState<{ [key: string]: boolean }>({});

        useEffect(() => {
            resetApps();
            return getAllAccounts(setAccounts);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            setValue("configuration", mapAppsToDefaultConfiguration(apps));
        }, [apps, reset, setValue]);

        useImperativeHandle(ref, () => ({
            reset() {
                resetApps();
                resetFileInput();
            }
        }));

        const resetApps = () => {
            setApps(SUPPORTED_APPS.reduce((acc, app) => ({ ...acc, [app]: !!domain?.configuration[app] }), {}));
        };

        const resetFileInput = () => {
            setFiles([]);
        };

        return (
            <Form onSubmit={form.handleSubmit(onConfirm)}>
                <div className="flex divide-x">
                    <div className="w-1/2 space-y-6 px-4">
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <Input.Text {...field} label="Name" disabled={isWorking} required errorMessage={form.formState.errors.name?.message} />
                            )}
                        />
                        <Controller
                            name="fqdn"
                            control={control}
                            render={({ field }) => (
                                <Input.Text
                                    {...field}
                                    label="FQDN - Fully Qualified Domain Name"
                                    disabled={isWorking || !!domain?.fqdn}
                                    required
                                    errorMessage={form.formState.errors.fqdn?.message}
                                    {...(!domain?.fqdn && { addonAfter: `.${DEFAULT_DOMAIN}` })}
                                />
                            )}
                        />
                        <Fieldset label="Logo" className="p-5">
                            <div className="flex items-center space-x-4">
                                <UploadForm
                                    files={files}
                                    onDrop={setFiles}
                                    onRemove={resetFileInput}
                                    disabled={isWorking}
                                    fileTypes={FILE_TYPES}
                                    accept={ACCEPTED_FILE_TYPES}
                                    preview={<ImagePreview file={files[0]} />}
                                />
                            </div>
                        </Fieldset>
                        <Controller
                            name="ownerAccountId"
                            control={control}
                            render={({ field }) => (
                                <Select.Search
                                    {...field}
                                    label="Owner account"
                                    placeholder="Select an account..."
                                    options={accounts.map(account => ({ label: account.companyName, value: account.id }))}
                                    isDisabled={isWorking}
                                    isClearable
                                    onChange={value => setValue("ownerAccountId", value || "")}
                                />
                            )}
                        />
                    </div>
                    <div className="w-1/2 space-y-6 px-4">
                        <Controller
                            name="accessMode"
                            control={control}
                            render={({ field }) => (
                                <Select.Basic
                                    {...field}
                                    placeholder="Select an access mode..."
                                    label="Access mode"
                                    options={Object.values(WhiteLabelDomain.AccessMode).map(mode => ({ label: mode, value: mode }))}
                                    isDisabled={isWorking}
                                    required
                                    errorMessage={form.formState.errors.accessMode?.message}
                                />
                            )}
                        />
                        <Controller
                            name="preferredLanguage"
                            control={control}
                            render={({ field }) => (
                                <Select.Basic
                                    {...field}
                                    label="Preferred Language"
                                    required
                                    isDisabled={isWorking}
                                    options={SUPPORTED_LANGUAGES.map(lang => ({ label: lang.code, value: lang.code }))}
                                    errorMessage={form.formState.errors.preferredLanguage?.message}
                                />
                            )}
                        />
                        <Controller
                            name="configuration"
                            control={control}
                            render={({ field }) => (
                                <Fieldset label="Activate Apps">
                                    <div className="flex gap-2">
                                        {SUPPORTED_APPS.map(app => (
                                            <ToggleButton
                                                {...field}
                                                isChecked={!!apps[app]}
                                                onClick={() => setApps(prev => ({ ...prev, [app]: !prev?.[app] }))}
                                                text={camelCase(app)}
                                                key={app}
                                            />
                                        ))}
                                    </div>
                                </Fieldset>
                            )}
                        />
                        {!!apps[AppCode.DEPOSIT] && (
                            <Controller
                                name="configuration.deposit.isDepositAllowedWithoutOrganizationInfo"
                                control={control}
                                render={({ field }) => (
                                    <Select.Basic
                                        {...field}
                                        label="Deposit allowed without company information"
                                        required
                                        defaultValue={domain?.configuration.deposit?.isDepositAllowedWithoutOrganizationInfo}
                                        isDisabled={isWorking}
                                        options={["YES", "NO"].map(value => ({ label: value, value: value === "YES" }))}
                                        errorMessage={form.formState.errors.configuration?.deposit?.isDepositAllowedWithoutOrganizationInfo?.message}
                                    />
                                )}
                            />
                        )}
                    </div>
                </div>
            </Form>
        );
    }
);

export default function DomainModalWithButton({ domain, staffUser }: { staffUser: StaffUser; domain?: WhiteLabelDomain }) {
    const lang = useLang<BaseLang>();
    const { isOpen, doOpen, doClose } = useModal();
    const [isWorking, setIsWorking] = useState(false);
    const schema = yup.object({
        name: useRequiredString(),
        fqdn: useRequiredString()
            .trim()
            .matches(/^(?=.{4,253}$)(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z0-9-]{1,63}(?<!-))*$/, "FQDN is invalid"),
        accessMode: useRequiredString(),
        preferredLanguage: useRequiredString()
    });
    const form = useForm<WhiteLabelDomain>({
        schema,
        mode: "onChange",
        defaultValues: {
            ...domain,
            accessMode: domain?.accessMode ?? WhiteLabelDomain.AccessMode.OPEN
        }
    });
    const [files, setFiles] = useState<File[]>([]);
    const modalRef = useRef<{ reset: () => void }>(null);

    useEffect(() => {
        void (async () => {
            if (domain?.id) {
                setIsWorking(true);
                try {
                    const domainLogo = await getLogoByDomainId(domain.id);
                    const createdFile = await downloadImage(domainLogo, "logo");
                    setFiles([createdFile].filter(isDefined));
                } finally {
                    setIsWorking(false);
                }
            }
        })();
    }, [domain?.id, setFiles]);

    async function onConfirm() {
        const isUpdate = !!domain;

        if (!(await form.trigger())) {
            throw new Error("An error occurred while validating the form. Please check your inputs.");
        }

        try {
            setIsWorking(true);
            const domainForm = form.getValues();
            const domainData: WhiteLabelDomain = {
                ...domainForm,
                fqdn: domain?.fqdn || `${domainForm.fqdn}.${DEFAULT_DOMAIN}`,
                isActive: domain?.isActive ?? true,
                ...(domain?.id ? { id: domain.id } : {})
            };
            if ((await getWhiteLabelDomainByFQDN(domainData.fqdn, true)) && !isUpdate) {
                throw new Error("This FQDN already exists");
            }

            let createdDomain: WhiteLabelDomain | null = null;
            if (isUpdate) {
                await updateWhiteLabelDomain(domain.id, domainData);
            } else {
                createdDomain = await addWhiteLabelDomain(domainData, staffUser.id);
            }

            if (files?.length) {
                const domainId = createdDomain?.id ?? domain?.id;
                if (domainId) {
                    await uploadDomainLogo(domainId, files[0]);
                }
            }
            void message.success(`Your domain has been ${isUpdate ? "updated" : "created"}.`);
        } catch (error) {
            void message.error(`Failed to ${isUpdate ? "update" : "create"} the domain: ${error.message || error}`);
            throw error;
        } finally {
            setIsWorking(false);
        }
    }

    function onOpen() {
        modalRef.current?.reset();
        if (!domain) {
            form.reset({
                fqdn: "",
                ownerAccountId: "",
                accessMode: WhiteLabelDomain.AccessMode.OPEN,
                configuration: {}
            });
        }
        doOpen();
    }

    return (
        <>
            <Button type={domain ? "default" : "primary"} children={domain ? "Edit" : "New domain"} onClick={onOpen} isLoading={isWorking} />
            <Modal
                isOpen={isOpen}
                onClose={doClose}
                isDisabled={!form.formState.isValid}
                isLoading={isWorking}
                children={
                    <DomainForm form={form} isWorking={isWorking} domain={domain} files={files} setFiles={setFiles} ref={modalRef} onConfirm={onConfirm} />
                }
                okText={domain ? "Save" : "Create"}
                title={domain ? "Edit domain" : "New domain"}
                lang={lang}
                size="lg"
                onConfirm={onConfirm}
            />
        </>
    );
}

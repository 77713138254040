import { EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import { BaseLang, Controller, Form, Input, message, Modal, useForm, useLang, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { addLPRUser, resetUserAccessKey } from "../../../services";

export default function LPRUserModal({ isOpen, onClose, reset }: { isOpen: boolean; onClose: () => void; reset: boolean }) {
    const lang = useLang<BaseLang>();
    const [working, setWorking] = useState(false);

    const schema = yup.object({
        email: useRequiredString().trim().matches(EMAIL_REGEX, "Invalid email address")
    });

    const { handleSubmit, control, watch, formState } = useForm<{ email: string }>({
        schema,
        mode: "onChange"
    });

    async function doAddUser() {
        const { email } = watch();
        setWorking(true);
        try {
            let accessKey: string;
            if (reset) {
                accessKey = await resetUserAccessKey(email);
            } else {
                accessKey = await addLPRUser(email);
            }
            await navigator.clipboard.writeText(accessKey);
            await message.success(`${reset ? "Reset" : "Added"} user successfully, access key (${accessKey}) copied to clipboard.`, 10);
        } catch (err) {
            await message.error(`Failed to ${reset ? "reset" : "add"} user`);
        } finally {
            setWorking(false);
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={handleSubmit(doAddUser)}
            title={`${reset ? "Reset" : "Add"} user`}
            okText={reset ? "Reset" : "Add"}
            isLoading={working}
            lang={lang}
            isDisabled={!formState.isValid}
        >
            <Form>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <Input.Email
                            {...field}
                            label="Email"
                            placeholder="Email..."
                            disabled={working}
                            errorMessage={formState.errors.email?.message}
                            required
                        />
                    )}
                />
            </Form>
        </Modal>
    );
}

import { StaffUser } from "@vaultinum/vaultinum-api";
import { Route, Routes } from "react-router-dom";
import { EDIT_NDA_PAGE } from "../../../services/routing.service";
import EditNDAView from "./non-disclosure-agreement/EditNDAView";
import { RequestsList } from "./RequestsList";

export default function FullAuditRouter({ staffUser }: { staffUser: StaffUser }): JSX.Element {
    return (
        <Routes>
            <Route index element={<RequestsList staffUser={staffUser} />} />
            <Route path={`/${EDIT_NDA_PAGE}`}>
                <Route path=":ndaVersion" element={<EditNDAView />} />
            </Route>
        </Routes>
    );
}

import { Survey } from "@vaultinum/vaultinum-api";
import SurveyIcons from "./SurveyIcons";

export default function SurveyNodeTargetIcon({ target }: { target: Survey.NodeTarget }) {
    if (target.type === Survey.NodeType.SECTION) {
        if (target.property === Survey.NodeTargetProperty.DESCRIPTION) {
            return <SurveyIcons.SectionDescription isTwoToneColor />;
        }
        return <SurveyIcons.Section isTwoToneColor />;
    }
    if (target.type === Survey.NodeType.QUESTION) {
        if (target.property === Survey.NodeTargetProperty.DESCRIPTION) {
            return <SurveyIcons.QuestionDescription isTwoToneColor />;
        }
        return <SurveyIcons.Question isTwoToneColor />;
    }
    if (target.type === Survey.NodeType.OPTION) {
        if (target.property === Survey.NodeTargetProperty.FIXES) {
            return <SurveyIcons.OptionFix />;
        }
        if (target.property === Survey.NodeTargetProperty.WHY) {
            return <SurveyIcons.OptionWhy />;
        }
        return <SurveyIcons.Option isTwoToneColor />;
    }
    return null;
}

import { SupportedLanguageCode, Survey } from "@vaultinum/vaultinum-api";
import { Select } from "@vaultinum/vaultinum-sdk";
import { Flag } from ".";
import { LANGS } from "../constants";

export default function LangSelector({
    surveyLangs,
    onSelected,
    selectedSurveyLang,
    working
}: {
    surveyLangs: Survey.Lang[];
    onSelected: (selectedLang: SupportedLanguageCode) => void;
    selectedSurveyLang?: SupportedLanguageCode;
    working?: boolean;
}): JSX.Element {
    return (
        <Select.Basic
            value={selectedSurveyLang}
            onChange={onSelected}
            isLoading={working}
            options={[
                ...surveyLangs.map(surveyLang => ({
                    value: surveyLang.lang,
                    label: (
                        <span className="flex items-center">
                            <Flag countryCode={surveyLang.lang} /> {LANGS.find(lang => surveyLang.lang === lang.code)?.name}
                        </span>
                    )
                }))
            ]}
        />
    );
}

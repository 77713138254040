import { CodeAnalysisReport, Plugin, PluginExecStatus } from "@vaultinum/vaultinum-kys-api";
import { Buttons, HistoryIcon, message } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { resetPluginStatus } from "../../../../services/analyserService";

export default function ResetStatusButton({ report, plugin }: { report: CodeAnalysisReport; plugin: PluginExecStatus }): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);

    // Keep same alignment for all statuses
    if (plugin.status !== Plugin.Status.SUCCESS) {
        return <div className="w-5" />;
    }

    async function resetPlugin() {
        try {
            setIsLoading(true);
            await resetPluginStatus(report, plugin.pluginKey);
            void message.success("Plugin has been reset successfully");
        } catch {
            void message.error("Failed to reset plugin status");
        } finally {
            setIsLoading(false);
        }
    }

    return <Buttons.Icon title="Reset plugin status" icon={HistoryIcon} isLoading={isLoading} size="sm" type="default" onClick={resetPlugin} />;
}

import { NonDisclosureAgreement } from "@vaultinum/vaultinum-kys-api";
import { useEffect, useState } from "react";
import { getNDAVersions } from "../services/referentialService";

export function useNDAVersions() {
    const [ndaVersions, setNDAVersions] = useState<NonDisclosureAgreement[]>([]);
    const [latestPublishedNDA, setLatestPublishedNDA] = useState<NonDisclosureAgreement | null>();

    useEffect(() => {
        setLatestPublishedNDA(ndaVersions.find(nda => nda.latest) || null);
    }, [ndaVersions]);

    useEffect(() => getNDAVersions(setNDAVersions), []);

    return { ndaVersions, latestPublishedNDA };
}

import { FlagTwoToneIcon, FolderTwoToneIcon, InfoCircleTwoToneIcon, PositiveTwoToneIcon, QuestionCircleTwoToneIcon } from "@vaultinum/vaultinum-sdk";

const SurveyIcons = {
    Section: FolderTwoToneIcon,
    SectionDescription: InfoCircleTwoToneIcon,
    QuestionDescription: InfoCircleTwoToneIcon,
    PreambleSection: FlagTwoToneIcon,
    Question: QuestionCircleTwoToneIcon,
    Option: PositiveTwoToneIcon,
    OptionWhy: () => <>📕</>,
    OptionFix: () => <>🧰</>
};

export default SurveyIcons;

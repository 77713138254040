import { StaffUser } from "@vaultinum/vaultinum-api";
import { formatDisplayName } from "@vaultinum/vaultinum-sdk";
import { ReactNode, useEffect, useState } from "react";
import { isSystemUser } from "../constants";
import { getStaffUser, getStaffUserById } from "../services/staffUserService";

export default function StaffUserName({ uid, staffUserId, loggedStaffUser }: { uid?: string; staffUserId?: string; loggedStaffUser?: StaffUser }) {
    const [staffUserName, setStaffUserName] = useState<string>("-");
    useEffect(() => {
        if (uid && isSystemUser(uid)) {
            setStaffUserName(uid);
        }
        if (loggedStaffUser && loggedStaffUser.id === staffUserId) {
            setStaffUserName("Me");
        } else if (uid) {
            return getStaffUser(uid, staffUser => staffUser && setStaffUserName(formatDisplayName(staffUser)));
        } else if (staffUserId) {
            return getStaffUserById(staffUserId, staffUser => staffUser && setStaffUserName(formatDisplayName(staffUser)));
        } else {
            setStaffUserName("-");
        }
        return () => {};
    }, [uid, staffUserId, loggedStaffUser]);

    return <span>{staffUserName}</span>;
}

export function StaffUserNamesAsList({
    staffUserIds,
    loggedStaffUser,
    emptyText = "N/A"
}: {
    staffUserIds: string[] | undefined;
    loggedStaffUser?: StaffUser;
    emptyText?: string;
}): JSX.Element {
    return (
        <>
            {staffUserIds?.length ? (
                staffUserIds
                    .map<ReactNode>(staffUserId => <StaffUserName key={staffUserId} staffUserId={staffUserId} loggedStaffUser={loggedStaffUser} />)
                    .reduce((prev, curr) => [prev, ", ", curr])
            ) : (
                <span className="italic text-grey-light">{emptyText}</span>
            )}
        </>
    );
}

import { AccountUser, FullAudit, FullAuditActors, isDefined, queryFullAudit, StaffUser } from "@vaultinum/vaultinum-api";
import { FULL_AUDIT_AGREEMENTS, FULL_AUDIT_EVENTS } from "@vaultinum/vaultinum-kys-api";
import {
    collectionGroup,
    converter,
    DataEventSource,
    doGet,
    doPost,
    downloadStorageFile,
    getAccount,
    getAccountUser,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    VAULTINUM_DOMAIN_ID,
    where,
    writeBatch
} from "@vaultinum/vaultinum-sdk";
import { kebabCase } from "lodash";

const FULL_AUDIT_ENDPOINT = "admin-fullAudit";
const KYS_FULL_AUDIT_ENDPOINT = "kys-fullAudit";

const STORAGE = {
    domains: "domains",
    domain: (domainId: string | null) => `${STORAGE.domains}/${domainId ?? VAULTINUM_DOMAIN_ID}`,
    domainAudit: (domainId: string | null, auditId: string) => `${STORAGE.domain(domainId)}/audits/${auditId}`,
    domainAuditReports: (domainId: string | null, fullAuditId: string) => `${STORAGE.domainAudit(domainId, fullAuditId)}/reports`
} as const;

export function sendFullAuditInvitationEmailNotification(fullAuditId: string, isAuditedClient: boolean): Promise<void> {
    return doPost(`${FULL_AUDIT_ENDPOINT}/${fullAuditId}/email`, { isAuditedClient }, process.env.REACT_APP_API_HOST);
}

export async function getFullAuditUsers(fullAuditId: string): Promise<FullAuditActors> {
    return doGet(`${KYS_FULL_AUDIT_ENDPOINT}/${fullAuditId}/users`, {}, process.env.REACT_APP_KYS_API_HOST || process.env.REACT_APP_API_HOST);
}

export async function getFullAuditReviewers(fullAudit: FullAudit): Promise<(AccountUser | StaffUser)[]> {
    if (queryFullAudit(fullAudit).roleExists(FullAudit.Role.REVIEWER) && fullAudit.reviewerAccount?.accountUserIds?.length) {
        const reviewerAccountIds = queryFullAudit(fullAudit).getAccountIdsByRole(FullAudit.Role.REVIEWER);
        const accountUsers = (
            await Promise.all(
                reviewerAccountIds.map(async accountId => {
                    const account = await getAccount(accountId);
                    if (!account) {
                        return [];
                    }
                    const reviewers = await Promise.all((fullAudit.reviewerAccount?.accountUserIds ?? []).map(userId => getAccountUser(account, userId)));
                    return reviewers.filter(isDefined);
                })
            )
        ).flat();
        return accountUsers;
    }
    return [];
}

function getFileName(productName: string, type: "code-scan" | "insights" | "charts"): string {
    return `Vaultinum-${type}-${kebabCase(productName)}`;
}

export function downloadCodeScanReport(fullAudit: FullAudit): Promise<void> {
    return downloadStorageFile(
        `${STORAGE.domainAuditReports(fullAudit.whiteLabelDomainId, fullAudit.id)}/code-scan-report.pdf`,
        `${getFileName(fullAudit.product.name, "code-scan")}.pdf`
    );
}

export async function removeFullAuditAgreements(): Promise<void> {
    const querySnapshot = await getDocs(query(collectionGroup(getFirestore(), FULL_AUDIT_AGREEMENTS)));
    const batch = writeBatch(getFirestore());
    querySnapshot.docs.forEach(docSnapshot => batch.delete(docSnapshot.ref));
    await batch.commit();
}

const fullAuditEventsListener = new DataEventSource<FullAudit.Event[]>(onUpdate =>
    onSnapshot(
        query(
            collectionGroup(getFirestore(), FULL_AUDIT_EVENTS).withConverter(converter<FullAudit.Event>()),
            where("source", "==", FullAudit.Event.Source.FULL_AUDIT)
        ),
        querySnapshot => onUpdate(querySnapshot.docs.map(doc => doc.data()))
    )
);

export function getFullAuditEvents(onUpdate: (events: FullAudit.Event[]) => void): () => void {
    return fullAuditEventsListener.addListener(onUpdate);
}

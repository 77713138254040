import { StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { User } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { KYS1_SURVEY_PAGE } from "../../../services/routing.service";
import { getStaffUsers } from "../../../services/staffUserService";
import SurveyVersionsPage from "./SurveyVersionsPage";
import SurveysView from "./SurveysView";
import SurveyBuilderView from "./survey-builder/SurveyBuilderPage";

export default function Kys1Page({ user, staffUser }: { user: User; staffUser: StaffUser }) {
    const [staffUsers, setStaffUsers] = useState<StaffUser[]>([]);
    useEffect(() => getStaffUsers(setStaffUsers), []);
    const isAdmin = staffUser.roles.includes(StaffUserRole.ADMIN);
    return (
        <Routes>
            <Route index element={<SurveysView isAdmin={isAdmin} staffUserId={staffUser.id} />} />
            <Route path={`${KYS1_SURVEY_PAGE}/:surveyKey`}>
                <Route index element={<SurveyVersionsPage isAdmin={isAdmin} user={user} staffUsers={staffUsers} />} />
                <Route path=":version">
                    <Route index element={<SurveyBuilderView isAdmin={isAdmin} user={user} />} />
                    <Route path=":sectionId" element={<SurveyBuilderView isAdmin={isAdmin} user={user} />} />
                </Route>
            </Route>
        </Routes>
    );
}

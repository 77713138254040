import { diagnosticCount } from "@codemirror/lint";
import { ViewUpdate } from "@codemirror/view";
import { Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, Form, Input, Modal, User, message, useLang } from "@vaultinum/vaultinum-sdk";
import { useCallback, useState } from "react";
import { JsonEditor } from "../../../components";
import { uploadSurvey } from "../../../services/surveyService";

export default function UploadSurveyModal({ isOpen, onClose, refSurvey, user }: { isOpen: boolean; onClose: () => void; refSurvey: Survey; user: User }) {
    const lang = useLang<BaseLang>();
    const [loading, setLoading] = useState(false);
    const [surveyJSON, setSurveyJSON] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [hasErrors, setHasErrors] = useState(false);

    async function uploadNewSurvey() {
        setLoading(true);
        try {
            const { surveyVersion, langs }: { surveyVersion: Survey.Version; langs: Survey.Lang[] } = JSON.parse(surveyJSON);
            const newSurveyVersion: Survey.Version = {
                ...surveyVersion,
                surveyKey: refSurvey.key,
                publishedLangs: [],
                latest: false
            };
            const version = await uploadSurvey(newSurveyVersion, langs, user.uid);
            void message.success(`Survey uploaded successfully. Version=${version}`);
            onClose();
        } catch (err) {
            setErrorMessage(String(err));
        }
        setLoading(false);
    }

    const onChange = useCallback((val: string) => {
        setSurveyJSON(val);
    }, []);

    function onUpdate(viewUpdate: ViewUpdate) {
        setHasErrors(diagnosticCount(viewUpdate.state) > 0);
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Upload new survey version"
            okText="Upload"
            onConfirm={uploadNewSurvey}
            isLoading={loading}
            isDisabled={hasErrors}
            lang={lang}
            size="lg"
            isSubmitKeyPressDisabled
            maxHeight="90vh"
        >
            <Form>
                <Input.Text label="Survey Key" placeholder="Key code..." value={refSurvey.key} disabled={true} />
                <JsonEditor value={surveyJSON} schema={{ type: "object" }} isLoading={loading} onChange={onChange} onUpdate={onUpdate} />
            </Form>
            {errorMessage && <div className="message">{errorMessage}</div>}
        </Modal>
    );
}

import { Survey } from "@vaultinum/vaultinum-api";

export const OPERATIONS_TO_GROUP = [Survey.Version.Revision.Operation.Op.remove];

export const OPERATION_TEXT: { [key in Survey.Version.Revision.Operation.Op]: string } = {
    add: "added",
    replace: "updated",
    remove: "deleted"
};

export const CHANGE_SIZE_LIMIT = 1000;

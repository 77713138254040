import classnames from "classnames";
import { Change, diffChars } from "diff";
import { useCallback, useEffect, useRef, useState } from "react";
import "./StringDiffDisplay.css";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const stringHash = require("string-hash");

const diffCache: { [key: string]: Change[] } = {};

export default function StringDiffDisplay({ value, other, useWebWorker }: { value: string; other: string; useWebWorker?: boolean }) {
    const workerRef = useRef<Worker>();
    const componentIsMounted = useRef(true);
    const key = stringHash(`${value}:${other}`);
    const [diffs, setDiffs] = useState<Change[] | undefined>(diffCache[key]);

    const syncDiff = useCallback(() => {
        if (!diffs) {
            if (useWebWorker) {
                workerRef?.current?.postMessage({ value, other });
            } else if (componentIsMounted.current) {
                const changes = diffChars(value, other);
                diffCache[key] = changes;
                setDiffs(changes);
            }
        }
    }, [diffs, key, other, useWebWorker, value]);

    useEffect(() => {
        if (useWebWorker) {
            workerRef.current = new Worker(new URL("./../common/WebWorker", import.meta.url));
            workerRef.current.onmessage = ({ data }) => {
                if (componentIsMounted.current) {
                    diffCache[key] = data;
                    setDiffs(data);
                }
                workerRef.current?.terminate();
            };
        }
        syncDiff();
        return () => {
            workerRef?.current?.terminate();
            componentIsMounted.current = false;
        };
    }, [key, syncDiff, useWebWorker]);

    return (
        <span className="string-diff">
            {diffs?.map((part: Change, i: number) => (
                <span
                    key={i}
                    className={classnames({
                        removed: part.removed,
                        added: part.added
                    })}
                >
                    {part.value}
                </span>
            ))}
        </span>
    );
}

import { AccountUser, StaffUser } from "@vaultinum/vaultinum-api";
import { first, upperFirst } from "lodash";

export function extractFirstLastNameFromEmail(email: string): [string, string] {
    const emailParts = email.split("@");
    if (emailParts.length > 1) {
        const nameParts = emailParts[0].split(".").map(upperFirst);
        if (nameParts.length > 1) {
            return [nameParts[0], nameParts[1]];
        }
    }
    return ["", ""];
}

export function getInitials(user: StaffUser | AccountUser): string {
    return `${first(user.firstName)}${first(user.lastName)}`.toUpperCase();
}

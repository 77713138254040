import { Account, App, StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { message, SectionTitle, Select } from "@vaultinum/vaultinum-sdk";

import { setAppSetting } from "../../../services/accountService";

export default function Plan({
    account,
    staffUser,
    appCode,
    appSettings
}: {
    account: Account;
    staffUser: StaffUser;
    appCode: App.Code;
    appSettings?: Account.AppSettings;
}): JSX.Element {
    async function onPlanChange(value: App.Timestamping.Plan | App.Sealing.Plan | App.Archiving.Plan) {
        try {
            await setAppSetting(account, appCode, "plan", value);
            void message.success(`${appCode.toLocaleUpperCase()} service plan updated to ${value}`);
        } catch (error) {
            void message.error(`Error while updating user plan: ${error}`);
        }
    }
    return (
        <>
            <SectionTitle title="Plan" />
            {staffUser.roles.includes(StaffUserRole.ADMIN) ? (
                <Select.Basic
                    className="w-80"
                    options={Object.entries(App.Timestamping.Plan).map(([key, value]) => ({ label: key, value }))}
                    onChange={onPlanChange}
                    value={appSettings?.plan as App.Timestamping.Plan | App.Sealing.Plan | App.Archiving.Plan}
                />
            ) : (
                appSettings?.plan
            )}
        </>
    );
}

import { UserProfile } from "@vaultinum/vaultinum-api";
import { TableStorageKey } from "@vaultinum/vaultinum-sdk";
import { Route, Routes } from "react-router-dom";
import { getUserProfileLink } from "../../../services/routing.service";
import { getUserProfiles } from "../../../services/userProfileService";
import UserProfilePage from "./UserProfilePage";
import { StandardModelListView } from "../components";

function UserProfilesView() {
    return (
        <StandardModelListView<UserProfile>
            getModelItems={getUserProfiles}
            name="User Profiles"
            modelPageLink={getUserProfileLink}
            columns={[
                { header: "Email", accessorKey: "email" },
                {
                    header: "Name",
                    accessorFn: row => `${row.firstName} ${row.lastName}`.trim()
                },
                {
                    header: "Accounts",
                    accessorFn: row => row.accountIds?.length
                },
                { header: "Lang", accessorFn: row => row.settings?.preferredLang || "-" }
            ]}
            searchOptions={{
                hideIgnored: true
            }}
            storageKey={TableStorageKey.USER_PROFILE}
            isVirtualized
        />
    );
}

export default function UsersProfilesPage() {
    return (
        <Routes>
            <Route index element={<UserProfilesView />} />
            <Route path=":userProfileId" element={<UserProfilePage />} />
        </Routes>
    );
}

import { VerifiedDepositReport } from "@vaultinum/vaultinum-deposit-api";
import { deleteDoc, reportDoc } from "@vaultinum/vaultinum-sdk";
import { getReportFileName } from "../common/ReportTools";
import { downloadPDF } from "./apiService";

// Verified Deposit Reports

export const downloadVerifiedDepositPDF = (report: VerifiedDepositReport) => {
    const url = `vault-deposit/pdf/${report.accountId}/${report.id}`;
    const fileName = getReportFileName("verified-deposit", report.depositIddn, report.creationDate);
    return downloadPDF(url, fileName, process.env.REACT_APP_VAULT_API_HOST);
};

// Common

export async function deleteReport(fullAuditId: string, reportId: string): Promise<void> {
    return deleteDoc(reportDoc(fullAuditId, reportId));
}

/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isDefined, LANG_EN, SupportedLanguageCode, Survey } from "@vaultinum/vaultinum-api";
import { deleteField, doPost, FieldValue, getSurveyLangs, serverTimestamp, surveyLangDoc, updateDoc } from "@vaultinum/vaultinum-sdk";
import { get } from "lodash";
import { DEPRECATED_AUTHOR } from "../constants";
import { addOrUpdateSurveyComment } from "./surveyCommentService";
import { groupKeyToNodeType } from "./surveyService";

export async function getSurveyLangsFromSurveyVersions(surveyVersions?: Survey.Version[]): Promise<Survey.Lang[]> {
    if (!surveyVersions?.length) {
        return [];
    }
    return (await Promise.all(surveyVersions.map(({ surveyKey, version }) => getSurveyLangs(surveyKey, version)))).filter(isDefined).flat();
}

async function getOtherLangs(surveyLang: Survey.Lang): Promise<Survey.Lang[]> {
    return (await getSurveyLangs(surveyLang.surveyKey, surveyLang.surveyVersion))?.filter(({ lang }) => lang !== surveyLang.lang) || [];
}

export function updateLangText(surveyLang: Survey.Lang, updatedByUID: string, text: { [key: string]: string | FieldValue }): Promise<void> {
    const surveyDoc = surveyLangDoc(surveyLang.surveyKey, surveyLang.surveyVersion, surveyLang.lang);
    return updateDoc(surveyDoc, {
        ...text,
        updatedByUID,
        updatedDate: serverTimestamp()
    });
}

async function deleteOtherLangs(surveyLang: Survey.Lang, key: string, updatedByUID: string): Promise<void | null> {
    const availableLangs = await getOtherLangs(surveyLang);
    await Promise.all(
        availableLangs.map(lang =>
            updateLangText(lang, updatedByUID, {
                [key]: deleteField()
            })
        )
    );
}

async function deprecateOtherLangs(surveyLang: Survey.Lang, value: string | null, key: string): Promise<void | null> {
    const [type, nodeId, property, subNodeId] = key.split(".");
    const nodeType = groupKeyToNodeType(type);

    if (!nodeType) {
        return null;
    }

    return new Promise(async (resolve, _reject) => {
        // Deprecate langs only if value is defined & EN has changed
        if (!value || surveyLang.lang !== LANG_EN) {
            return resolve();
        }

        const previousValue = get(surveyLang, key);

        const availableLangs = await getOtherLangs(surveyLang);

        // Only EN is available
        if (!availableLangs.length) {
            return resolve();
        }

        return Promise.all(
            availableLangs.map(lang => {
                const currentValue = get(lang, key);
                // Not translated yet, no comment to add
                if (!currentValue) {
                    return resolve();
                }
                const comment: Omit<Survey.Comment, "id" | "creationDate" | "isResolved" | "resolvedBy" | "resolutionDate" | "replies"> = {
                    authorUID: DEPRECATED_AUTHOR,
                    lang: lang.lang,
                    surveyKey: lang.surveyKey,
                    surveyVersion: lang.surveyVersion,
                    target: {
                        nodeId,
                        property: property as Survey.NodeTargetProperty,
                        type: nodeType,
                        ...(subNodeId ? { subNodeId } : {})
                    },
                    comment: `Text updated in English\nprevious → "${previousValue}"\nnew        → "${value}"`
                };
                return addOrUpdateSurveyComment(comment);
            })
        ).then(() => resolve());
    });
}

export function updateLang(surveyLang: Survey.Lang, updatedByUID: string): Record<string, any> {
    const surveyDoc = surveyLangDoc(surveyLang.surveyKey, surveyLang.surveyVersion, surveyLang.lang);
    const updatedBy = {
        updatedByUID,
        updatedDate: serverTimestamp()
    };
    return {
        survey: {
            title: (value: string | null) =>
                updateDoc(surveyDoc, {
                    ...updatedBy,
                    "survey.title": value ?? deleteField()
                }),
            description: (value: string | null) =>
                updateDoc(surveyDoc, {
                    ...updatedBy,
                    "survey.description": value ?? deleteField()
                }),
            aboutPageUID: (value: string | null) =>
                updateDoc(surveyDoc, {
                    ...updatedBy,
                    "survey.aboutPageUID": value ?? deleteField()
                })
        },
        section: (sectionId: string) => ({
            delete: (isBatch?: boolean) => {
                const data = {
                    ...updatedBy,
                    [`sections.${sectionId}`]: deleteField()
                };
                return isBatch ? data : updateDoc(surveyDoc, data);
            },
            title: async (value: string | null) => {
                const key = `sections.${sectionId}.title`;
                return Promise.all([
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [key]: value ?? deleteField()
                    }),
                    deprecateOtherLangs(surveyLang, value, key)
                ]);
            },
            description: (value: string | null) => {
                const key = `sections.${sectionId}.description`;
                return Promise.all([
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [key]: value ?? deleteField()
                    }),
                    deprecateOtherLangs(surveyLang, value, key)
                ]);
            },
            ref: () => surveyDoc
        }),
        question: (questionId: string) => ({
            delete: (isBatch?: boolean) => {
                const data = {
                    ...updatedBy,
                    [`questions.${questionId}`]: deleteField()
                };
                return isBatch ? data : updateDoc(surveyDoc, data);
            },
            text: (value: string | null) => {
                const key = `questions.${questionId}.text`;
                return Promise.all([
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [key]: value ?? deleteField()
                    }),
                    deprecateOtherLangs(surveyLang, value, key)
                ]);
            },
            description: (value: string | null) => {
                const key = `questions.${questionId}.description`;
                return Promise.all([
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [key]: value ?? deleteField()
                    }),
                    deprecateOtherLangs(surveyLang, value, key)
                ]);
            },
            ref: () => surveyDoc
        }),
        option: (optionId: string) => ({
            delete: (isBatch?: boolean) => {
                const data = {
                    ...updatedBy,
                    [`options.${optionId}`]: deleteField()
                };
                return isBatch ? data : updateDoc(surveyDoc, data);
            },
            text: (value: string | null) => {
                const key = `options.${optionId}.text`;
                return Promise.all([
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [key]: value ?? deleteField()
                    }),
                    deprecateOtherLangs(surveyLang, value, key)
                ]);
            },
            why: (value: string | null) => {
                const key = `options.${optionId}.why`;
                return Promise.all([
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [key]: value ?? deleteField()
                    }),
                    deprecateOtherLangs(surveyLang, value, key)
                ]);
            },
            fix: (fixId: string, value: string | null) => {
                const key = `options.${optionId}.fixes.${fixId}`;
                return Promise.all([
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [key]: value ?? deleteField()
                    }),
                    deprecateOtherLangs(surveyLang, value, key)
                ]);
            },
            ref: () => surveyDoc
        }),
        evaluationTag: (tagId: string) => ({
            delete: () => {
                const key = `evaluationTag.${tagId || '""'}`;
                return Promise.all([
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [key]: deleteField()
                    }),
                    deleteOtherLangs(surveyLang, key, updatedByUID)
                ]);
            },
            value: (name: string, description: string) =>
                updateDoc(surveyDoc, {
                    ...updatedBy,
                    [`evaluationTag.${tagId}`]: { name, description }
                })
        }),
        variants: (variantId: string) => ({
            delete: () =>
                updateDoc(surveyDoc, {
                    ...updatedBy,
                    [`variants.${variantId}`]: deleteField()
                }),
            text: (value: string | null) => {
                const key = `variants.${variantId}.text`;
                return Promise.all([
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [key]: value ?? deleteField()
                    }),
                    deprecateOtherLangs(surveyLang, value, key)
                ]);
            },
            options: (optionId: string) => ({
                delete: () =>
                    updateDoc(surveyDoc, {
                        ...updatedBy,
                        [`variants.${variantId}.options.${optionId}`]: deleteField()
                    }),
                text: (value: string | null) => {
                    const key = `variants.${variantId}.options.${optionId}.text`;
                    return Promise.all([
                        updateDoc(surveyDoc, {
                            ...updatedBy,
                            [key]: value ?? deleteField()
                        }),
                        deprecateOtherLangs(surveyLang, value, key)
                    ]);
                }
            })
        })
    };
}

export function translateSurvey(
    surveyVersion: Survey.Version,
    sourceLang: SupportedLanguageCode,
    targetLang: SupportedLanguageCode,
    createSuggestionComments = false
) {
    return doPost(
        `survey/${surveyVersion.surveyKey}/${surveyVersion.version}/translate`,
        {
            sourceLang,
            targetLang,
            createSuggestionComments
        },
        process.env.REACT_APP_KYS_BACKEND_URL
    );
}

import { FullAudit, Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, Controller, Form, Input, message, Modal, useForm, useLang, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { ScopeSelector } from "../../../components";
import { newSurvey, updateSurvey } from "../../../services/surveyService";

type SurveyForm = {
    surveyKey: string;
    name: string;
    scope?: FullAudit.Scope;
};

export default function AddOrEditSurveyModal({ isOpen, onClose, survey }: { isOpen: boolean; onClose: () => void; survey: Survey | null }): JSX.Element {
    const lang = useLang<BaseLang>();
    const schema = yup.object({
        surveyKey: useRequiredString().min(2).max(8),
        name: useRequiredString().trim()
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid }
    } = useForm<SurveyForm>({
        schema
    });
    const [working, setWorking] = useState(false);
    useEffect(() => {
        if (isOpen) {
            if (survey) {
                reset({
                    surveyKey: survey.key,
                    name: survey.name,
                    scope: survey.scope
                });
            } else {
                reset({
                    surveyKey: "",
                    name: "",
                    scope: undefined
                });
            }
        }
    }, [isOpen, survey, reset]);

    const createNewSurvey = async (data: SurveyForm) => {
        setWorking(true);
        try {
            const { surveyKey, ...props } = data;
            if (survey) {
                await updateSurvey(survey.key, props);
            } else {
                await newSurvey(surveyKey?.toUpperCase(), props);
            }
            onClose();
        } catch (e) {
            void message.error(e.message);
        } finally {
            setWorking(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={handleSubmit(createNewSurvey)}
            title={survey ? "Edit Survey" : "New Survey"}
            okText={survey ? "Update" : "Create"}
            isLoading={working}
            isDisabled={!isValid}
            lang={lang}
        >
            <Form>
                <Controller
                    name="surveyKey"
                    control={control}
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            label="Survey Key"
                            placeholder="Survey key..."
                            disabled={!!survey}
                            errorMessage={errors.surveyKey?.message}
                            required
                        />
                    )}
                />
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            label="Name used internally for the survey"
                            placeholder="Survey name..."
                            errorMessage={errors.name?.message}
                            required
                        />
                    )}
                />
                <Controller name="scope" control={control} render={({ field }) => <ScopeSelector {...field} />} />
            </Form>
        </Modal>
    );
}

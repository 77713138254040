import { StaffUser, Survey } from "@vaultinum/vaultinum-api";
import {
    Alert,
    BaseLang,
    Button,
    CellContext,
    Column,
    DeleteIcon,
    formatDisplayName,
    ModalButton,
    openNotificationWithIcon,
    Space,
    Table,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { getStaffUserById } from "../../../services/staffUserService";
import { deleteSurveyAccess, getSurveyAccesses } from "../../../services/surveyAccessService";
import AddSurveyAccess from "./components/AddSurveyAccess";

type StaffUserWithAccess = Survey.Access & {
    name: string;
    email?: string;
};

function getColumns(isAdmin: boolean, isLoading: boolean, lang: BaseLang, deleteAccess: (staffUserId: string) => void): Column<StaffUserWithAccess>[] {
    return [
        ...(isAdmin ? [{ header: "Id", accessorKey: "staffUserId" }] : []),
        {
            header: "Name",
            accessorKey: "name"
        },
        {
            header: "Email",
            accessorKey: "email"
        },
        ...(isAdmin
            ? [
                  {
                      header: "Action",
                      enableSorting: false,
                      enableColumnFilter: false,
                      accessorKey: "staffUserId",
                      cell: (cell: CellContext<StaffUserWithAccess, unknown>) => (
                          <ModalButton.Confirm
                              icon={DeleteIcon}
                              title="Delete access"
                              children="Are you sure you want to delete this access?"
                              onConfirm={() => deleteAccess(cell.getValue<string>())}
                              lang={lang}
                              buttonProps={{
                                  isLoading: false,
                                  isDisabled: isLoading,
                                  icon: DeleteIcon
                              }}
                          />
                      )
                  }
              ]
            : [])
    ];
}

export function SurveyAccessView({ isAdmin, survey, staffUsers }: { isAdmin: boolean; survey: Survey; staffUsers: StaffUser[] }) {
    const lang = useLang<BaseLang>();
    const [surveyAccesses, setSurveyAccesses] = useState<Survey.Access[]>([]);
    const [staffUsersWithAccess, setStaffUsersWithAccess] = useState<StaffUserWithAccess[]>([]);
    const [showAddSurveyAccess, setShowAddSurveyAccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => getSurveyAccesses(survey.key, setSurveyAccesses), [survey]);

    useEffect(() => {
        void (async function () {
            const users = await Promise.all(
                surveyAccesses.map(async access => {
                    const staffUser = await getStaffUserById(access.staffUserId);
                    return {
                        ...access,
                        name: staffUser ? formatDisplayName(staffUser) : "-",
                        email: staffUser?.email
                    };
                })
            );
            setStaffUsersWithAccess(users);
        })();
    }, [surveyAccesses]);

    async function deleteAccess(staffUserId: string) {
        setIsLoading(true);
        try {
            await deleteSurveyAccess(survey.key, staffUserId);
        } catch {
            openNotificationWithIcon({ type: "error", description: "Failed to delete access" });
        } finally {
            setIsLoading(false);
        }
    }

    const columns = getColumns(isAdmin, isLoading, lang, deleteAccess);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {isAdmin && (
                <div className="flex items-center gap-2">
                    <AddSurveyAccess
                        isOpen={showAddSurveyAccess}
                        onClose={() => setShowAddSurveyAccess(false)}
                        survey={survey}
                        staffUsers={staffUsers}
                        surveyAccesses={surveyAccesses}
                    />
                    <Button isLoading={false} onClick={() => setShowAddSurveyAccess(true)}>
                        Add access
                    </Button>
                    <Alert.Info message="Note: Users with ADMIN right have access by default." />
                </div>
            )}
            <Table<StaffUserWithAccess> data={staffUsersWithAccess} columns={columns} />
        </Space>
    );
}

// const copyToCollection = async (docs: Array<firestore.QueryDocumentSnapshot<firestore.DocumentData>>, targetCollectionPath: string) => {
//     for(const doc of docs){
//         await firestore()
//             .collection(targetCollectionPath)
//             .doc(doc.id)
//             .set(doc.data());
//     }
// }

// const renameTables = async () => {
//     const items = await firestore()
//         .collection("userProfile")
//         .get();
//     await copyToCollection(items.docs, `/${USER_PROFILE_COLLECTION}`);
// }

export default function MaintenancePage() {
    return (
        <div className="operations-page">
            {/* <Button onClick={() => renameTables()}>Renames Collections</Button> */}
            <h2>Maitenance operations</h2>
            <sub>WIP</sub>
        </div>
    );
}

import { Report } from "@vaultinum/vaultinum-legal-proof-api";
import { Alert, Button, Buttons, Column, DownloadIcon, message, Tag, useModal } from "@vaultinum/vaultinum-sdk";
import fileSize from "filesize";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { downloadReport, getAllReports, LPRMetadata } from "../../../services";
import { StandardModelListView } from "../components";
import LPRUserModal from "./LPRUserModal";

const columns: Column<LPRMetadata>[] = [
    {
        header: "Email",
        accessorKey: "email",
        cell: cell => cell.getValue<string>()
    },
    {
        header: "Report title",
        accessorKey: "reportTitle",
        cell: cell => cell.getValue<string>()
    },
    {
        header: "Report type",
        accessorKey: "reportType",
        cell: cell => <Tag type="info" message={cell.getValue<string>()} />
    },
    {
        header: "Requester",
        accessorKey: "requester",
        cell: cell => cell.getValue<string>()
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: cell => <Status reportStatus={cell.getValue<string>()} />
    },
    {
        header: "Failed tasks",
        accessorKey: "failedTasks",
        cell: cell => <FailedTasks failedTasks={cell.getValue<string[]>() ?? []} />
    },
    {
        header: "Visited Websites",
        accessorKey: "visitedWebsites",
        cell: cell => {
            return (
                <div className="flex flex-col gap-2">
                    {cell.getValue<string[]>()?.map((website, index) => (
                        <a key={index} href={website} target="_blank" rel="noreferrer">
                            {website}
                        </a>
                    ))}
                </div>
            );
        }
    },
    {
        header: "PDF Size",
        accessorKey: "pdfSize",
        cell: cell => fileSize(cell.getValue<number>() ?? 0),
        enableColumnFilter: false,
        enableSorting: false
    },
    {
        header: "ZIP Size",
        accessorKey: "zipSize",
        cell: cell => fileSize(cell.getValue<number>() ?? 0),
        enableColumnFilter: false,
        enableSorting: false
    }
];

function Status({ reportStatus }: { reportStatus: string }): JSX.Element {
    if (reportStatus === Report.ReportStatus.SUCCESS) {
        return <Tag type="success" message={reportStatus} />;
    }
    if (reportStatus === Report.ReportStatus.ERROR) {
        return <Tag type="danger" message={reportStatus} />;
    }
    return <Tag type="warning" message={reportStatus} />;
}

function FailedTasks({ failedTasks }: { failedTasks: string[] }): JSX.Element {
    return (
        <div className="flex gap-2">
            {failedTasks.map(task => (
                <Tag key={task} type="danger" message={task} />
            ))}
        </div>
    );
}
export function LPRList({ wasAdminWhenLogged }: { wasAdminWhenLogged: boolean }): JSX.Element {
    const [resetAccessKey, setResetAccessKey] = useState(false);
    const { doOpen, isOpen, doClose } = useModal();

    if (!process.env.REACT_APP_LPR_URL || !process.env.REACT_APP_LPR_ADMIN_ACCESS_KEY) {
        return (
            <div className="flex place-content-center">
                <Alert.Danger message="Your environment is not configured for handling LPR admin operations" />
            </div>
        );
    }

    async function doDownloadReport(report: LPRMetadata) {
        try {
            await downloadReport(report);
        } catch (err) {
            console.log({ err });
            await message.error("Failed to download report");
        }
    }

    function downloadReportAction(report: LPRMetadata) {
        return <Buttons.Icon isLoading={false} icon={DownloadIcon} onClick={() => doDownloadReport(report)} />;
    }

    function onOpen({ reset }: { reset: boolean }) {
        doOpen();
        setResetAccessKey(reset);
    }

    return (
        <>
            {wasAdminWhenLogged && (
                <div className="flex">
                    <LPRUserModal isOpen={isOpen} onClose={doClose} reset={resetAccessKey} />
                    <div className="flex gap-2">
                        <Button onClick={() => onOpen({ reset: false })} size="sm" isLoading={false} children="Add User" />
                        <Button onClick={() => onOpen({ reset: true })} size="sm" isLoading={false} children="Reset User Access key" />
                    </div>
                </div>
            )}
            <StandardModelListView<LPRMetadata>
                name="Reports LPR"
                getModelItems={getAllReports}
                columns={columns}
                extraActions={downloadReportAction}
                searchOptions={{ hideIgnored: true }}
                isVirtualized
            />
        </>
    );
}
export default function LPRPage({ wasAdminWhenLogged }: { wasAdminWhenLogged: boolean }): JSX.Element {
    return (
        <Routes>
            <Route index element={<LPRList wasAdminWhenLogged={wasAdminWhenLogged} />} />
        </Routes>
    );
}

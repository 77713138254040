import { Notification, USER_PROFILE_COLLECTION, USER_PROFILE_NOTIFICATIONS_COLLECTION } from "@vaultinum/vaultinum-api";
import { collection, DocumentSnapshot, getFirestore, onSnapshot, Timestamp } from "@vaultinum/vaultinum-sdk";

function convertDocToNotification(doc: DocumentSnapshot): Notification {
    const data = doc.data() as Omit<Notification, "id"> & { creationDate: Timestamp; readDate: Timestamp; plannedDate: Timestamp };
    return {
        ...data,
        id: doc.id,
        creationDate: data.creationDate?.toDate(),
        readDate: data.readDate?.toDate(),
        plannedDate: data.plannedDate?.toDate()
    };
}

export function getUserProfileNotificationsCount(userProfileId: string, onUpdate: (eventsCount: number) => void): () => void {
    return onSnapshot(collection(getFirestore(), USER_PROFILE_COLLECTION, userProfileId, USER_PROFILE_NOTIFICATIONS_COLLECTION), querySnapshot => {
        onUpdate(querySnapshot.size);
    });
}

export function getUserProfileNotifications(userProfileId: string, onUpdate: (notifications: Notification[]) => void): () => void {
    return onSnapshot(collection(getFirestore(), USER_PROFILE_COLLECTION, userProfileId, USER_PROFILE_NOTIFICATIONS_COLLECTION), querySnapshot => {
        onUpdate(querySnapshot.docs.map(convertDocToNotification));
    });
}

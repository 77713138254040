import { EnrichedFullAudit, getFullAudit } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { getFullAuditUsers } from "../services/fullAuditService";

export function useFullAudit(fullAuditId: string | undefined): EnrichedFullAudit | undefined {
    const [fullAudit, setFullAudit] = useState<EnrichedFullAudit | undefined>();
    useEffect(() => {
        if (!fullAuditId) {
            setFullAudit(undefined);
            return () => {};
        }
        return getFullAudit(fullAuditId, async fullAuditSnaptshot => {
            if (fullAuditSnaptshot) {
                try {
                    const { auditedAccount, reviewerAccounts, beneficiaryAccount } = await getFullAuditUsers(fullAuditSnaptshot.id);
                    const enrichedFullAudit: EnrichedFullAudit = {
                        ...fullAuditSnaptshot,
                        auditedAccount: {
                            ...fullAuditSnaptshot.auditedAccount,
                            ...auditedAccount
                        },
                        ...(fullAuditSnaptshot.beneficiaryAccount && {
                            beneficiaryAccount: {
                                ...fullAuditSnaptshot.beneficiaryAccount,
                                ...beneficiaryAccount
                            }
                        }),
                        reviewerAccounts
                    };
                    setFullAudit(enrichedFullAudit);
                } catch {
                    setFullAudit(fullAuditSnaptshot);
                }
            }
        });
    }, [fullAuditId]);
    return fullAudit;
}

import classNames from "classnames";

export enum DepositTagColor {
    GREEN = "green",
    GREY = "grey",
    ORANGE = "orange"
}

export function DepositTagAction({ children, color }: { children: React.ReactNode; color: DepositTagColor }) {
    const { GREEN, GREY, ORANGE } = DepositTagColor;

    const colorClasses: Record<DepositTagColor, string> = {
        [GREEN]: "bg-green-light bg-opacity-30 text-green-dark",
        [GREY]: "bg-grey-light bg-opacity-30 text-grey-dark",
        [ORANGE]: "bg-orange-light bg-opacity-30 text-orange-dark"
    };

    return <div className={classNames("flex items-center justify-center rounded-md p-1.5", colorClasses[color])}>{children}</div>;
}

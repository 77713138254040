import { Event, Notification } from "@vaultinum/vaultinum-api";
import { IconTag, Tag } from "@vaultinum/vaultinum-sdk";

export function getTagLevel(level: Event.Level | Notification.Level): JSX.Element | null {
    const message = level.toUpperCase();
    switch (level) {
        case Event.Level.INFO:
        case Notification.Level.INFO:
            return <IconTag.Info message={message} />;
        case Event.Level.ERROR:
        case Notification.Level.ERROR:
            return <IconTag.Danger message={message} />;
        case Event.Level.WARN:
        case Notification.Level.WARNING:
            return <IconTag.Warning message={message} />;
        default:
            return null;
    }
}

export function getStatusTag(status: Notification.Status): JSX.Element | null {
    const message = status.toUpperCase();
    switch (status) {
        case Notification.Status.PENDING:
            return <IconTag.Pending message={message} />;
        case Notification.Status.SENT:
            return <IconTag.Success message={message} />;
        case Notification.Status.FAILED:
            return <IconTag.Danger message={message} />;
        default:
            return null;
    }
}

export function getUpperCaseTag(value: string) {
    return <Tag message={value.toUpperCase()} />;
}

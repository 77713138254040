import { getNodeTargetValue, query, Section, Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, message, User } from "@vaultinum/vaultinum-sdk";
import { sameTarget } from "../../../../../../common/AccountTools";
import { isSurveyPublished, recursiveDeleteQuestion, recursiveDeleteSection } from "../../../../../../common/SurveyTools";
import { updateLang } from "../../../../../../services/surveyLangService";
import { EditableCellActionType } from "../../../components/EditableCell";
import SurveyIcons from "../../../components/SurveyIcons";
import SurveyNodeEditableCell from "../../../components/SurveyNodeEditableCell";
import { questionToTreeNode } from "./QuestionTreeNode";
import { getTagEvalColumns, TreeNodeEditableCell } from "./SurveyTreeNodeBuilder";
import { newEmptyQuestion, newEmptySection } from "./SurveyTreeNodeTools";
import { SurveyTreeNode } from "./TreeNode";

export const sectionToTreeNode = (
    lang: BaseLang,
    surveyVersion: Survey.Version, // Reference survey
    selectedSurveyLang: Survey.Lang,
    defaultSurveyLang: Survey.Lang | undefined,
    surveyLangs: Survey.Lang[],
    parentKey: string,
    key: string,
    section: Section,
    setSurveyVersion: (surveyVersion: Survey.Version) => void,
    setSection: (section: Section) => void,
    deleteSection: () => Promise<void>,
    user: User,
    comments: Survey.Comment[]
): SurveyTreeNode => {
    const sectionLang = selectedSurveyLang.sections[section.id];
    const isPublished = isSurveyPublished(surveyVersion);
    let children: SurveyTreeNode[] = [];
    if (sectionLang?.description !== undefined) {
        const target: Survey.NodeTarget = {
            type: Survey.NodeType.SECTION,
            property: Survey.NodeTargetProperty.DESCRIPTION,
            nodeId: section.id
        };
        children.push({
            key: `${key}-desc`,
            parentKey: parentKey || key,
            target,
            icon: <SurveyIcons.SectionDescription />,
            title: (
                <SurveyNodeEditableCell
                    nodeKey={`${key}-desc`}
                    target={target}
                    onChange={(surveyLang, value) => updateLang(surveyLang, user.uid).section(section.id).description(value)}
                    onDelete={isPublished ? undefined : () => updateLang(selectedSurveyLang, user.uid).section(section.id).description(null)}
                    nodeValue={getNodeTargetValue(target, selectedSurveyLang)}
                    defaultValue={getNodeTargetValue(target, defaultSurveyLang)}
                    surveyLang={selectedSurveyLang}
                    suggestionComment={
                        comments.filter(comm => sameTarget(comm.target, target)).sort((a, b) => b.creationDate?.getTime() - a.creationDate?.getTime())[0]
                    }
                />
            ),
            data: section
        });
    }
    children = [
        ...children, // Section Nodes
        ...(section.sections
            ? section.sections.map((subSection, i) =>
                  sectionToTreeNode(
                      lang,
                      surveyVersion,
                      selectedSurveyLang,
                      defaultSurveyLang,
                      surveyLangs,
                      key,
                      `${key}.${i + 1}`,
                      subSection,
                      setSurveyVersion,
                      subSection => setSection(query(section).updateSection(subSection)),
                      async () => {
                          try {
                              await recursiveDeleteSection(surveyLangs, subSection, user.uid);
                              setSection(query(section).deleteSection(subSection.id));
                          } catch (err) {
                              void message.error(err.message);
                          }
                      },
                      user,
                      comments
                  )
              )
            : []),
        // Question nodes
        ...(section.questions
            ? section.questions.map((question, i) =>
                  questionToTreeNode(
                      lang,
                      surveyVersion,
                      selectedSurveyLang,
                      defaultSurveyLang,
                      surveyLangs,
                      key,
                      `${key}.${i + 1 + (section.sections?.length || 0)}`,
                      question,
                      question => setSection(query(section).updateQuestion(question)),
                      async () => {
                          try {
                              await recursiveDeleteQuestion(surveyLangs, question, user.uid);
                              setSection(query(section).deleteQuestion(question.id));
                          } catch (err) {
                              void message.error(err.message);
                          }
                      },
                      user,
                      comments
                  )
              )
            : [])
    ];
    const target: Survey.NodeTarget = {
        type: Survey.NodeType.SECTION,
        property: Survey.NodeTargetProperty.TITLE,
        nodeId: section.id
    };

    const actions = [
        {
            key: EditableCellActionType.SET_SECTION_AS_PREAMBLE,
            icon: <SurveyIcons.PreambleSection size="xs" />,
            tooltip: "Double click to toggle section as preamble",
            hide: !surveyVersion.sections.includes(section),
            onDoubleClick: () => {
                if (surveyVersion.preambleSectionId === section.id) {
                    // eslint-disable-next-line unused-imports/no-unused-vars
                    const { preambleSectionId, ...updatedSurveyVersion } = surveyVersion;
                    setSurveyVersion(updatedSurveyVersion);
                } else {
                    setSurveyVersion({
                        ...surveyVersion,
                        preambleSectionId: section.id
                    });
                }
            }
        },
        {
            key: EditableCellActionType.ADD_SECTION_DESCRIPTION,
            icon: <SurveyIcons.SectionDescription size="xs" />,
            tooltip: "Double click to add section description",
            hide: sectionLang?.description !== undefined,
            onDoubleClick: () => updateLang(selectedSurveyLang, user.uid).section(section.id).description("")
        },
        {
            key: EditableCellActionType.ADD_NEW_QUESTION,
            icon: <SurveyIcons.Option size="xs" />,
            tooltip: "Double click to add new question",
            onDoubleClick: () => {
                const newQuestion = newEmptyQuestion();
                updateLang(selectedSurveyLang, user.uid).question(newQuestion.id).text("");
                setSection({ ...section, questions: [...(section.questions || []), newQuestion] });
            }
        },
        {
            key: EditableCellActionType.ADD_NEW_SUB_SECTION,
            icon: <SurveyIcons.Section size="xs" />,
            tooltip: "Double click to add new sub-section",
            onDoubleClick: () => {
                const newSection = newEmptySection();
                updateLang(selectedSurveyLang, user.uid).section(newSection.id).title("");
                setSection({ ...section, sections: [...(section.sections || []), newSection] });
            }
        }
    ];
    return {
        key,
        parentKey,
        target,
        title: (
            <TreeNodeEditableCell
                nodeKey={key}
                target={target}
                actions={actions}
                comments={comments}
                defaultSurveyLang={defaultSurveyLang}
                selectedSurveyLang={selectedSurveyLang}
                surveyVersion={surveyVersion}
                onChange={(surveyLang, title) => updateLang(surveyLang, user.uid).section(section.id).title(title)}
                onDelete={deleteSection}
            />
        ),
        icon: surveyVersion.preambleSectionId === section.id ? <SurveyIcons.PreambleSection /> : <SurveyIcons.Section />,
        data: section,
        children: children.length > 0 ? children : undefined,
        tagEval: getTagEvalColumns(query(section).getOrderedQuestions())
    };
};

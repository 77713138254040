import { StaffUser, Survey } from "@vaultinum/vaultinum-api";
import { Breadcrumb, BreadcrumbItems, getSurvey, Spin, Tabs, User } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ABSOLUTE_KYS1_PAGE } from "../../../services/routing.service";
import { SurveyAccessView } from "./SurveyAccessView";
import { SurveyGradeScoreView } from "./SurveyGradeScoreView";
import { SurveyVersionsView } from "./SurveyVersionsView";

const SurveyTabs = {
    versions: "Versions",
    access: "Access",
    score: "Score"
};

export default function SurveyVersionsPage({ isAdmin, user, staffUsers }: { isAdmin: boolean; user: User; staffUsers: StaffUser[] }) {
    const { surveyKey } = useParams<{ surveyKey: string }>();
    const [activeTab, setActiveTab] = useState(SurveyTabs.versions);
    const [survey, setSurvey] = useState<Survey | null>();

    useEffect(() => {
        if (!surveyKey) {
            return () => {};
        }
        return getSurvey(surveyKey, setSurvey);
    }, [surveyKey]);

    if (!survey || !surveyKey) {
        return <Spin />;
    }

    const breadcrumbItems: BreadcrumbItems = [
        {
            label: "Surveys",
            href: ABSOLUTE_KYS1_PAGE
        },
        {
            label: `${surveyKey} (${survey.name})`
        }
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <Tabs activeKey={activeTab} onChange={setActiveTab} centered>
                <Tabs.TabPane tab={SurveyTabs.versions} key={SurveyTabs.versions}>
                    <SurveyVersionsView isAdmin={isAdmin} user={user} survey={survey} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={SurveyTabs.access} key={SurveyTabs.access}>
                    <SurveyAccessView isAdmin={isAdmin} survey={survey} staffUsers={staffUsers} />
                </Tabs.TabPane>
                {isAdmin && (
                    <Tabs.TabPane tab={SurveyTabs.score} key={SurveyTabs.score}>
                        <SurveyGradeScoreView survey={survey} />
                    </Tabs.TabPane>
                )}
            </Tabs>
        </>
    );
}

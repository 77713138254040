import { BaseLang, DeleteIcon, ModalHelper } from "@vaultinum/vaultinum-sdk";

export default function confirmNodeDeletion(lang: BaseLang, onConfirm: () => void) {
    ModalHelper.Confirm({
        title: "Node deletion",
        children: "Are you sure to delete this node and all its children?",
        icon: DeleteIcon,
        onConfirm,
        lang
    });
}

import { StaffUser } from "@vaultinum/vaultinum-api";
import { Avatar, ThunderboltIcon, UserIcon, WarningFilledIcon } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { getInitials } from "../common/UserTools";
import { DEPRECATED_AUTHOR, TRANSLATION_AUTHOR } from "../constants";
import { getStaffUser } from "../services/staffUserService";

export default function StaffUserAvatar({ uid }: { uid?: string }) {
    const [staffUser, setStaffUser] = useState<StaffUser | null>(null);
    useEffect(() => {
        if (uid) {
            return getStaffUser(uid, setStaffUser);
        }
        setStaffUser(null);
        return () => {};
    }, [uid]);
    if (uid === DEPRECATED_AUTHOR) {
        return (
            <Avatar className="flex items-center bg-orange-extra-light">
                <WarningFilledIcon color="orange" />
            </Avatar>
        );
    }
    if (uid === TRANSLATION_AUTHOR) {
        return (
            <Avatar className="flex items-center bg-green-extra-light">
                <ThunderboltIcon color="green" />
            </Avatar>
        );
    }
    if (staffUser && (staffUser.firstName || staffUser.lastName)) {
        return <Avatar>{getInitials(staffUser)}</Avatar>;
    }
    return <Avatar icon={<UserIcon />} />;
}

import { Input, InputProps } from "@vaultinum/vaultinum-sdk";
import { useDebounceFn } from "ahooks";
import { useEffect, useState } from "react";
import { LONG_DEBOUNCE } from "../constants";
import "./DebouncedInput.css";

type DebouncedInputProps = Omit<InputProps, "onChange"> & {
    value: string;
    onChange: (value: string) => Promise<void> | void;
    label?: string;
    required?: boolean;
    wait?: number;
    errorMessage?: string;
};

export default function DebouncedInput({ value, label, required, onChange, wait = LONG_DEBOUNCE, errorMessage, ...props }: DebouncedInputProps) {
    const [internalValue, setValue] = useState<string>(value);

    const { run } = useDebounceFn(
        async () => {
            await onChange(internalValue);
        },
        { wait }
    );

    useEffect(() => setValue(value), [value]);

    return (
        <Input.Text
            {...props}
            value={internalValue}
            label={label}
            required={required}
            onChange={async e => {
                setValue(e.target.value);
                await run();
            }}
            errorMessage={errorMessage}
        />
    );
}

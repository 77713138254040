import { getPaymentLink } from "../../../helpers";
import { DepositTagAction, DepositTagColor } from "./DepositTagAction";

export function PaymentLink({ isPaidOffline, subscriptionId }: { isPaidOffline: boolean; subscriptionId?: string }) {
    return (
        <DepositTagAction
            color={isPaidOffline ? DepositTagColor.GREY : DepositTagColor.GREEN}
            children={
                <a href={getPaymentLink(isPaidOffline, subscriptionId)} target="_blank" rel="noreferrer">
                    {isPaidOffline ? "OFFLINE" : "STRIPE"}
                </a>
            }
        />
    );
}

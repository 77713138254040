import { StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { DropdownActionProps, Dropdowns, formatDisplayName, Header, LogoutIcon, Menu, User, UserIcon } from "@vaultinum/vaultinum-sdk";
import { ReactNode } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getMenuItemsPerBrand } from "./helpers";
import { doLogout } from "./services/loginService";
import { ABSOLUTE_PRIVATE_ROOT_PAGE, PRIVATE_ROOT_PAGE, PUBLIC_ROOT_PAGE, STAFF_USER_PROFILE_PAGE } from "./services/routing.service";

function addOnClickMenuItem(key: string, to: string, children: ReactNode, onClick?: () => void, staffUser?: StaffUser | null, requiredRoles?: StaffUserRole[]) {
    return !requiredRoles || (staffUser && requiredRoles.some(role => staffUser.roles.includes(role)))
        ? {
              key,
              label: <NavLink onClick={onClick} to={to} children={children} className="nav-link" />
          }
        : null;
}

function addMenuItem(key: string, to: string, children: ReactNode, staffUser?: StaffUser | null, requiredRoles?: StaffUserRole[]) {
    return addOnClickMenuItem(key, to, children, undefined, staffUser, requiredRoles);
}

function getActions(staffUser: StaffUser | null): DropdownActionProps[] {
    return [
        ...(staffUser
            ? [
                  {
                      icon: UserIcon,
                      label: "Profile",
                      href: STAFF_USER_PROFILE_PAGE
                  }
              ]
            : []),
        {
            icon: LogoutIcon,
            label: "Logout",
            onClick: doLogout
        }
    ];
}

export default function HeaderBar({
    user,
    roles,
    staffUser,
    loading
}: {
    user?: User | null;
    roles: StaffUserRole[];
    staffUser: StaffUser | null;
    loading: boolean;
}) {
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname
        .replace(PRIVATE_ROOT_PAGE, "")
        .split("/")
        .filter(path => path)[0];

    return (
        <Header>
            <img onClick={() => navigate(ABSOLUTE_PRIVATE_ROOT_PAGE)} className="logo" src="/Logo-white_128.png" alt="KYS Logo" width="60" />
            <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys={[path]}
                items={
                    user && staffUser && roles.length > 0 ? getMenuItemsPerBrand(addMenuItem, staffUser) : [addMenuItem("home", PUBLIC_ROOT_PAGE, "KYS admin")]
                }
            />
            {user && !loading && (
                <div className="right-menu">
                    <Dropdowns.Menu
                        title="Profile"
                        icon={UserIcon}
                        actions={getActions(staffUser)}
                        header={staffUser ? formatDisplayName(staffUser) : "Unauthorized"}
                    />
                </div>
            )}
        </Header>
    );
}

import { Grade, GradeColors, GRADES, Survey } from "@vaultinum/vaultinum-api";
import { Slider } from "@vaultinum/vaultinum-sdk";
import { updateScoreRange } from "../common/GradeScoreRangeTools";

const PRIMARY_COLOR = "#325574";

export default function SurveyGradeScoreRange({
    scoreRange,
    onUpdate
}: {
    scoreRange: Survey.GradeScoreRange;
    onUpdate?: (scoreRange: Survey.GradeScoreRange) => void;
}): JSX.Element {
    function isRangeLimitReached(scoreRange: Survey.GradeScoreRange, newRange: [number, number], grade: Grade): boolean {
        if ((grade === "A" && newRange[1] < 100) || (grade === "E" && newRange[0] > 0)) {
            return true;
        }
        const gradeIndex = GRADES.indexOf(grade);
        if (newRange[1] >= scoreRange[gradeIndex - 1] || newRange[0] <= scoreRange[gradeIndex + 2]) {
            return true;
        }
        return false;
    }

    function onGradeScoreChange(defaultScoreRange: Survey.GradeScoreRange, newRange: [number, number], grade: Grade) {
        if (!onUpdate || isRangeLimitReached(defaultScoreRange, newRange, grade)) {
            return;
        }

        const newGradeScoreRange = updateScoreRange(defaultScoreRange, grade, newRange);
        onUpdate(newGradeScoreRange);
    }

    function getRangeValue(scoreRange: Survey.GradeScoreRange, grade: Grade): [number, number] {
        const gradeIndex = GRADES.indexOf(grade);
        return [scoreRange[gradeIndex], scoreRange[gradeIndex + 1]];
    }

    // Marks is used to display value under the slider's cursor
    function getMarks(scoreRange: Survey.GradeScoreRange, grade: Grade): { [key: number]: number } {
        const gradeIndex = GRADES.indexOf(grade);
        return {
            [scoreRange[gradeIndex]]: scoreRange[gradeIndex],
            [scoreRange[gradeIndex + 1]]: scoreRange[gradeIndex + 1]
        };
    }

    return (
        <div className="space-y-2">
            {GRADES.map(grade => (
                <div key={grade} className="flex items-center gap-10">
                    <div style={{ backgroundColor: GradeColors[grade] }} className="w-20 rounded-md p-2 text-center text-3xl font-semibold text-white">
                        {grade}
                    </div>
                    <Slider
                        className="flex-auto"
                        marks={getMarks(scoreRange, grade)}
                        reverse
                        trackStyle={[{ backgroundColor: PRIMARY_COLOR }]}
                        handleStyle={[{ borderColor: PRIMARY_COLOR }, { borderColor: PRIMARY_COLOR }]}
                        range
                        value={getRangeValue(scoreRange, grade)}
                        onChange={newRange => onGradeScoreChange(scoreRange, newRange as [number, number], grade)}
                        allowCross={false}
                        min={0}
                        max={100}
                    />
                </div>
            ))}
        </div>
    );
}

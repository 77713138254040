import { Account, Company, CompanyContact, Deposit, DepositStore, Escrow, Note, StaffUser } from "@vaultinum/vaultinum-api";
import {
    BarCircleIcon,
    BaseLang,
    BreadcrumbItems,
    Button,
    CreditCardIcon,
    DownloadIcon,
    EditIcon,
    Input,
    ModalHelper,
    NoteDrawer,
    Spin,
    Tag,
    formatAddress,
    formatName,
    getAccount,
    getUserProfileFromUID,
    useLang
} from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AccountLink, AsyncActionButton, BaseInfoCard, ModelItemPageHeader, UploadEscrowContractModal } from "../../../components";
import { escrowProgressStatusToType, getVerificationLevelOptions } from "../../../helpers";
import { addNewEscrowNote, archiveEscrowNote, getEscrowActiveNotes, updateEscrowNote } from "../../../services/escrowNoteService";
import { ESCROW_PAGE } from "../../../services/routing.service";
import {
    downloadEscrowContract,
    getDepositStoresForAccount,
    getDepositStoresWithDeposits,
    getDepositsByStores,
    getEscrow,
    setEscrowAsPaidOfflineAndPendingDeposit,
    terminateEscrow
} from "../../../services/vaultService";
import DepositStores from "../account/DepositStores";

function VerificationLevelOptions({ verificationLevelRef }: { verificationLevelRef: React.MutableRefObject<Deposit.VerificationLevel> }): JSX.Element {
    const [selectedVerificationLevel, setSelectedVerificationLevel] = useState<Deposit.VerificationLevel>(verificationLevelRef.current);

    function onOptionChange(e: ChangeEvent<HTMLInputElement>) {
        const level = e.target.value as Deposit.VerificationLevel;
        setSelectedVerificationLevel(level);
        verificationLevelRef.current = level;
    }

    return (
        <>
            {getVerificationLevelOptions().map(({ value, label }) => (
                <Input.Radio
                    key={value}
                    value={value}
                    name="verificationLevelGroup"
                    onChange={onOptionChange}
                    label={label}
                    id={value}
                    checked={selectedVerificationLevel === value}
                />
            ))}
        </>
    );
}

function EscrowContractButtons({ escrow }: { escrow: Escrow }) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const verificationLevelRef = useRef<Deposit.VerificationLevel>(Deposit.VerificationLevel.None);

    const lang = useLang<BaseLang>();

    const onDownloadContract = async () => {
        setIsLoading(true);
        try {
            await downloadEscrowContract(escrow);
        } catch (err) {
            throw new Error("An error occured when trying to download the contract");
        } finally {
            setIsLoading(false);
        }
        return "Contract successfully downloaded";
    };

    function displayConfirmModal() {
        ModalHelper.Modal({
            title: "Payment confirmation",
            children: (
                <div className="flex flex-col space-y-2">
                    <span>Are you sure you want to set this escrow as paid offline? This action will also set the escrow as pending deposit.</span>
                    <VerificationLevelOptions verificationLevelRef={verificationLevelRef} />
                </div>
            ),
            onConfirm: updateEscrowPaymentStatus,
            lang
        });
    }

    function displayTerminateModal() {
        ModalHelper.Confirm({
            title: `Terminate escrow contract ${escrow.id}`,
            children: "Are you sure you want to set the status of this escrow to TERMINATED?",
            onConfirm: terminateContract,
            lang
        });
    }

    async function updateEscrowPaymentStatus() {
        await setEscrowAsPaidOfflineAndPendingDeposit(escrow.id, verificationLevelRef.current);
    }

    async function terminateContract() {
        try {
            setIsLoading(true);
            await terminateEscrow(escrow.id);
        } catch (err) {
            throw new Error("An error occured when setting the contract status to terminated");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="flex flex-col gap-2">
            <div className="flex gap-2">
                <UploadEscrowContractModal escrow={escrow} />
                {escrow.isContractAvailable && (
                    <AsyncActionButton icon={DownloadIcon} isLoading={isLoading} onClick={onDownloadContract} children="Download contract" />
                )}
            </div>
            <Button
                type="default"
                icon={CreditCardIcon}
                children="SET AS PAID OFFLINE"
                isLoading={false}
                onClick={displayConfirmModal}
                isDisabled={!!escrow.paymentId}
            />
            <Button isLoading={false} type="default" icon={BarCircleIcon} children="Terminate contract" onClick={displayTerminateModal} />
        </div>
    );
}

export default function EscrowPage({ staffUser }: { staffUser: StaffUser }): JSX.Element {
    const { escrowId } = useParams<{ escrowId: string }>();
    const [escrow, setEscrow] = useState<Escrow | null>();
    const [deposits, setDeposits] = useState<Deposit[] | null>();
    const [beneficiary, setBeneficiary] = useState<Account | null | undefined>();
    const [supplier, setSupplier] = useState<Account | null | undefined>();
    const [depositStores, setDepositStores] = useState<DepositStore[] | null | undefined>();
    const depositStoresWithDeposits = getDepositStoresWithDeposits(depositStores || [], deposits || []);
    const [notes, setNotes] = useState<Account.EscrowNote[]>();
    const [enrichedNotes, setEnrichedNotes] = useState<(Account.EscrowNote & { userName: string })[]>();
    const [showNotes, setShowNotes] = useState<boolean>(false);

    useEffect(() => {
        if (escrow) {
            return getEscrowActiveNotes(escrow.accountId, escrow.id, setNotes);
        }
        return () => {};
    }, [escrow]);

    useEffect(() => {
        void (async () => {
            if (notes) {
                const notesWithUserNames = await Promise.all(
                    notes.map(async note => {
                        const userProfile = await getUserProfileFromUID(note.createdByUID);
                        return {
                            ...note,
                            userName: userProfile ? formatName(userProfile) : "-"
                        };
                    })
                );
                setEnrichedNotes(notesWithUserNames);
            }
        })();
    }, [notes]);

    useEffect(() => {
        if (escrowId) {
            return getEscrow(escrowId, setEscrow);
        }
        return () => {};
    }, [escrowId]);

    useEffect(() => {
        if (escrow?.depositStoreIds?.length) {
            return getDepositsByStores(escrow.depositStoreIds, setDeposits);
        }
        return () => {};
    }, [escrow?.depositStoreIds]);

    useEffect(() => getAccount(escrow?.beneficiary?.accountId, setBeneficiary), [escrow?.beneficiary]);

    useEffect(() => getAccount(escrow?.supplier?.accountId, setSupplier), [escrow?.supplier]);

    useEffect(() => {
        return getDepositStoresForAccount(escrow?.supplier?.accountId, setDepositStores);
    }, [escrow?.supplier, escrow?.supplier?.accountId]);

    function closeNotesDrawer() {
        setShowNotes(false);
    }

    function openNotesDrawer() {
        setShowNotes(true);
    }

    async function onNoteSave(text: string, visibility: Note.Visibility, noteId: string | undefined) {
        if (staffUser.uid && escrow?.accountId) {
            if (noteId) {
                await updateEscrowNote(escrow.accountId, staffUser.uid, text, noteId);
            } else {
                await addNewEscrowNote(escrow.accountId, staffUser.uid, text, escrow.id, visibility);
            }
        } else {
            throw new Error("Failed to save note");
        }
    }

    async function onNoteDelete(noteId: string) {
        if (staffUser.uid && escrow?.accountId) {
            await archiveEscrowNote(escrow?.accountId, staffUser.uid, noteId);
        } else {
            throw new Error("Failed to delete note");
        }
    }

    if (!escrow) {
        return <Spin />;
    }

    const breadcrumbItems: BreadcrumbItems = [
        {
            label: "Escrows",
            href: ESCROW_PAGE
        },
        {
            label: escrow.id
        }
    ];

    return (
        <div className="flex flex-col gap-4">
            <div className="space-y-4">
                <ModelItemPageHeader breadcrumbItems={breadcrumbItems} />
                <BaseInfoCard
                    name="Escrow"
                    creationDate={escrow.creationDate}
                    attributes={{
                        type: escrow.type,
                        "verification level": <Tag type="info" message={escrow.depositVerification.level} />,
                        status: <Tag type={escrowProgressStatusToType(escrow.progressStatus)} message={escrow.progressStatus.replace("_", " ")} />,
                        ...(escrow.paymentId && {
                            subscription: (
                                <a
                                    title="Go to Stripe dashboard"
                                    href={`${process.env.REACT_APP_STRIPE_DASHBOARD}/subscriptions/${escrow.paymentId}`}
                                    className="link"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    children={escrow.paymentId}
                                />
                            )
                        })
                    }}
                    footerRight={
                        <div className="flex flex-col gap-2">
                            <Button type="default" icon={EditIcon} children="Show notes" isLoading={false} onClick={openNotesDrawer} />
                            <EscrowContractButtons escrow={escrow} />
                        </div>
                    }
                />
                <div className={classNames({ "flex grid-cols-2 flex-col gap-4 lg:grid lg:flex-row": escrow.type !== Escrow.Type.ACCESS_CLAUSE })}>
                    <PartyCard escrow={escrow} partyData={escrow.supplier} side="Supplier" companyName={supplier?.companyName} />
                    {escrow.type !== Escrow.Type.ACCESS_CLAUSE && (
                        <PartyCard escrow={escrow} partyData={escrow.beneficiary} side="Beneficiary" companyName={beneficiary?.companyName} />
                    )}
                </div>
            </div>
            {!!deposits && !!depositStores && <DepositStores depositStores={depositStoresWithDeposits} />}
            <NoteDrawer
                isVisible={showNotes}
                onClose={closeNotesDrawer}
                onSave={onNoteSave}
                onDelete={onNoteDelete}
                isReviewer={!!staffUser}
                notes={enrichedNotes}
            />
        </div>
    );
}

const PartyCard = ({
    escrow,
    side,
    partyData,
    companyName
}: {
    escrow: Escrow;
    side: "Beneficiary" | "Supplier";
    partyData?: { contacts: CompanyContact[]; company?: Company.Details; accountId?: string };
    companyName?: string;
}) => (
    <BaseInfoCard
        name={side}
        attributes={{
            ...(partyData && {
                ...(!partyData.company &&
                    companyName &&
                    partyData.accountId && {
                        "company name": <AccountLink accountId={partyData.accountId} text={companyName} />
                    }),
                ...(partyData.contacts.length && {
                    emails: partyData.contacts.map(contact => contact.email).join(", ")
                }),
                ...(partyData.accountId && {
                    "company name": <AccountLink accountId={partyData.accountId} text={partyData.company?.name} />
                }),
                ...(partyData.company?.registrationNumber && {
                    "registration number": partyData.company.registrationNumber
                }),
                ...(partyData.company?.nationality && {
                    nationality: partyData.company.nationality
                }),
                ...(partyData.company?.address && {
                    address: formatAddress(partyData.company.address)
                })
            })
        }}
        {...(escrow.payerAccountId === partyData?.accountId && { footerLeft: <Tag message="Payer" /> })}
    />
);

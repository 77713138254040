import { Deposit, Escrow } from "@vaultinum/vaultinum-api";
import { formatAsCurrency, Type } from "@vaultinum/vaultinum-sdk";

export function escrowProgressStatusToType(progressStatus: Escrow.ProgressStatus): Extract<Type, "success" | "warning" | "info"> {
    switch (progressStatus) {
        case Escrow.ProgressStatus.ACTIVE:
            return "success";
        case Escrow.ProgressStatus.PENDING_PAYMENT:
        case Escrow.ProgressStatus.PENDING_DEPOSIT:
            return "warning";
        default:
            return "info";
    }
}

export function depositStatusToType(status: Deposit.Status): Extract<Type, "success" | "warning" | "danger" | "info"> {
    switch (status) {
        case Deposit.Status.SEALED:
            return "success";
        case Deposit.Status.VERIFICATION_PENDING:
        case Deposit.Status.CONTROL_PENDING:
            return "warning";
        case Deposit.Status.EXPIRED:
        case Deposit.Status.REVOKED:
            return "danger";
        default:
            return "info";
    }
}

export function getVerificationLevelOptions(): { label: string; value: Deposit.VerificationLevel }[] {
    return [
        {
            label: "No verification",
            value: Deposit.VerificationLevel.None
        },
        {
            label: `Content Checked Deposit (${formatAsCurrency(800)})`,
            value: Deposit.VerificationLevel.Verified
        },
        {
            label: `Element Checked Deposit (From ${formatAsCurrency(3_800)})`,
            value: Deposit.VerificationLevel.Controlled
        }
    ];
}

export function getPaymentLink(isPaidOffline: boolean, subscriptionId?: string) {
    const path = isPaidOffline ? "invoices" : `subscriptions/${subscriptionId}`;
    return `${process.env.REACT_APP_STRIPE_DASHBOARD}/${path}`;
}

import { Section, Question } from "@vaultinum/vaultinum-api";

export function removeEvaluationFromSections(sections: Section[], tag: string): typeof sections {
    return sections.map(section => ({
        ...section,
        ...(section.sections && { sections: removeEvaluationFromSections(section.sections, tag) }),
        ...(section.questions && { questions: removeEvaluationFromQuestions(section.questions, tag) })
    }));
}

function removeEvaluationFromQuestions(questions: Question[], tag: string): Question[] {
    return questions.map(question => ({
        ...question,
        options: question.options.map(option => ({
            ...option,
            ...(option.questions && { questions: removeEvaluationFromQuestions(option.questions, tag) }),
            ...(option.context && {
                context: {
                    ...option.context,
                    ...(option.context.fixes && {
                        fixes: option.context.fixes.map(fix => ({
                            ...fix,
                            tags: fix.tags.filter(fixTag => fixTag !== tag)
                        }))
                    })
                }
            }),
            evaluations: option.evaluations.filter(evaluation => evaluation.tag !== tag)
        }))
    }));
}

import { Alert, BaseLang, ModalButton, RedoIcon, deleteFullAuditSurveyReportId, openNotificationWithIcon, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { deleteReport } from "../../../services";

export function DeleteSurveyReport({ fullAuditId, surveyReportId }: { fullAuditId: string; surveyReportId: string }): JSX.Element {
    const lang = useLang<BaseLang>();
    const [isLoading, setIsLoading] = useState(false);

    const onConfirm = async () => {
        setIsLoading(true);
        try {
            await deleteFullAuditSurveyReportId(fullAuditId);
            await deleteReport(fullAuditId, surveyReportId);
            openNotificationWithIcon({ type: "success", description: "Successfully re-opened surveys" });
        } catch (e) {
            openNotificationWithIcon({ type: "error", description: "An error occured when re-opening the surveys" });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ModalButton
            icon={RedoIcon}
            title="Re-open surveys"
            children={
                <div className="space-y-2">
                    <h4>Are you sure you want to re-open the surveys?</h4>
                    <Alert.Warning message="This will remove the current report and will allow the client to update the answers" />
                </div>
            }
            onConfirm={onConfirm}
            buttonProps={{
                children: "Re-open surveys",
                isLoading: false,
                isDisabled: isLoading,
                icon: RedoIcon
            }}
            lang={lang}
        />
    );
}

import { Breadcrumb, BreadcrumbItems } from "@vaultinum/vaultinum-sdk";

export default function ModelItemPageHeader({ breadcrumbItems }: { breadcrumbItems: BreadcrumbItems }) {
    if (!breadcrumbItems.length) {
        return null;
    }

    return (
        <div className="flex justify-between">
            <Breadcrumb items={breadcrumbItems} />
        </div>
    );
}

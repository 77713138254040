import { getAccount } from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAccountLinkById } from "../services/routing.service";

export default function AccountLink({ accountId, text }: { accountId: string; text?: string }) {
    const [companyName, setCompanyName] = useState<string>();

    useEffect(() => {
        if (!text && accountId) {
            return getAccount(accountId, account => setCompanyName(account?.companyName));
        }
        return () => {};
    }, [text, accountId]);

    return (
        <Link
            to={getAccountLinkById(accountId)}
            className={classNames("flex gap-1 truncate", { "cursor-pointer text-accent hover:underline": accountId })}
            children={text ?? companyName ?? accountId}
        />
    );
}

import { getAuth, RegisterForm } from "@vaultinum/vaultinum-sdk";
import { doEmailRegister, doSendEmailVerification } from "../../services/loginService";
import { LOGIN_PAGE } from "../../services/routing.service";

export default function RegisterPage() {
    const doRegister = async ({ email, password }: { email: string; password: string }) => {
        await doEmailRegister(email, password);
        try {
            if (getAuth().currentUser) {
                await doSendEmailVerification();
            }
        } catch (emailError) {
            throw Error("Failed to send verification email");
        }
    };

    return <RegisterForm logoSubtitle="Administration panel" doRegister={doRegister} loginUrl={LOGIN_PAGE} />;
}

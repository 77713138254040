import { StaffUser } from "@vaultinum/vaultinum-api";
import { Button, Controller, Form, IconTag, Input, message, useForm, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { updateStaffUserName } from "../../../services/staffUserService";

export default function ProfilePage({ staffUser }: { staffUser: StaffUser }): JSX.Element {
    const [loading, setLoading] = useState(false);

    const schema = yup.object({
        firstName: useRequiredString(),
        lastName: useRequiredString()
    });

    const { handleSubmit, control } = useForm({
        schema,
        defaultValues: {
            firstName: staffUser.firstName,
            lastName: staffUser.lastName
        }
    });

    const doSaveChanges = async (values: { firstName: string; lastName: string }) => {
        const { firstName, lastName } = values;
        setLoading(true);
        try {
            await updateStaffUserName(staffUser.id, firstName, lastName);
            void message.success("Profile saved");
        } catch {
            void message.error("An error occured while updating your profile.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2>User profile</h2>
            <IconTag.Warning message="For KYS 2, your name will be displayed in the report" />
            <Form onSubmit={handleSubmit(doSaveChanges)}>
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => <Input.Text {...field} label="First name" placeholder="Your first name..." />}
                />
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => <Input.Text {...field} label="Last name" placeholder="Your last name..." />}
                />
                <Button htmlType="submit" isLoading={loading} children="Save changes" />
            </Form>
        </div>
    );
}

import { Account, AccountInvitation, AccountRights, AccountUser } from "@vaultinum/vaultinum-api";
import {
    AddIcon,
    BaseLang,
    Button,
    Controller,
    DeleteIcon,
    Form,
    Input,
    Modal,
    UseFormReturn,
    getAccountUsers,
    message,
    useFieldArray,
    useForm,
    useLang,
    useModal
} from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import { getAccountInvitations, sendAccountInvitation } from "../../../services/accountService";

const CONTRIBUTOR_RIGHTS = [AccountRights.READ, AccountRights.WRITE];

function AccountInvitationForm({
    form,
    isWorking,
    account
}: {
    form: UseFormReturn<{ emails: { email: string }[] }>;
    isWorking: boolean;
    account: Account;
}): JSX.Element {
    const [accountUsers, setAccountUsers] = useState<AccountUser[]>([]);
    const [accountInvitations, setAccountInvitations] = useState<AccountInvitation[]>([]);

    useEffect(() => getAccountUsers(account, setAccountUsers), [account]);
    useEffect(() => getAccountInvitations(account.id, setAccountInvitations), [account]);

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "emails"
    });

    return (
        <Form>
            {fields.map((field, index) => (
                <div key={field.id} className="flex items-end justify-center gap-3">
                    <Controller
                        {...field}
                        name={`emails.${index}.email`}
                        control={form.control}
                        rules={{
                            required: true,
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Invalid email address"
                            },
                            validate: {
                                uniqueEmail: email => {
                                    if (accountUsers.map(({ email }) => email).includes(email)) {
                                        return "Email already belongs to this account";
                                    }
                                    if (accountInvitations.map(({ email }) => email).includes(email)) {
                                        return "Email has already been invited to this account";
                                    }
                                    return true;
                                }
                            }
                        }}
                        render={({ field: currentField }) => (
                            <Input.Email
                                {...currentField}
                                className="w-10/12"
                                label="Email address"
                                placeholder="Email"
                                disabled={isWorking}
                                errorMessage={form.formState.errors.emails?.[index]?.email?.message}
                                required
                            />
                        )}
                    />

                    <div
                        className={classNames({
                            hidden: fields.length === 1 || isWorking,
                            "flex p-2": fields.length > 1
                        })}
                    >
                        <DeleteIcon onClick={() => remove(index)} title="Remove" size="sm" color="slate" />
                    </div>
                </div>
            ))}
            {!isWorking && (
                <div className="mx-24 flex place-content-around items-baseline">
                    <Button icon={AddIcon} fill="link" onClick={() => append({ email: "" })} isLoading={false} children="Add new email" />
                </div>
            )}
        </Form>
    );
}

export function AccountInvitationModalWithButton({ account }: { account: Account }): JSX.Element {
    const lang = useLang<BaseLang>();
    const { isOpen, doOpen, doClose } = useModal();
    const [isWorking, setIsWorking] = useState(false);

    const form = useForm({
        defaultValues: {
            emails: [{ email: "" }]
        },
        mode: "onChange"
    });

    async function onConfirm() {
        try {
            await form.trigger();
        } catch (error) {
            void message.error(error.message || "An error occurred while validating the request. Please check your inputs.");
            throw error;
        }
        try {
            setIsWorking(true);
            const { emails } = form.getValues();
            const cleanEmails = uniq(emails.map(({ email }) => email.trim().toLowerCase()));

            for (const email of cleanEmails) {
                try {
                    await sendAccountInvitation(account.id, email, CONTRIBUTOR_RIGHTS);
                    void message.success(`Invitation has been sent for email=${email}`);
                } catch (error) {
                    void message.error(`Failed to send invitation for email=${email}`);
                }
            }
        } catch (error) {
            void message.error("Failed to send invitations");
            throw error;
        } finally {
            doClose();
            setIsWorking(false);
        }
    }

    function onOpen() {
        form.reset();
        doOpen();
    }

    return (
        <>
            <Button children="Invite users" onClick={onOpen} isLoading={isWorking} />
            <Modal
                isOpen={isOpen}
                onClose={doClose}
                isDisabled={!form.formState.isValid}
                isLoading={isWorking}
                children={<AccountInvitationForm form={form} isWorking={isWorking} account={account} />}
                okText="Send"
                title="Invite users"
                lang={lang}
                onConfirm={onConfirm}
                size="md"
            />
        </>
    );
}

import { getNodeTargetValue, query, Question, Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, CheckSquareTwoToneIcon, InputNumber, message, PositiveTwoToneIcon, Select, User } from "@vaultinum/vaultinum-sdk";
import { sameTarget } from "../../../../../../common/AccountTools";
import { isSurveyPublished, recursiveDeleteOption } from "../../../../../../common/SurveyTools";
import { updateLang } from "../../../../../../services/surveyLangService";
import { EditableCellActionType } from "../../../components/EditableCell";
import SurveyIcons from "../../../components/SurveyIcons";
import SurveyNodeEditableCell from "../../../components/SurveyNodeEditableCell";
import openQuestionVariantModal from "../SetQuestionVariantModal";
import { optionToTreeNode } from "./OptionTreeNode";
import { getTagEvalColumns, getVariantColumns, TreeNodeEditableCell } from "./SurveyTreeNodeBuilder";
import { newEmptyOption } from "./SurveyTreeNodeTools";
import { SurveyTreeNode } from "./TreeNode";

const OptionIndexDisplay = "abcdefghijklmnopqrstuvwxyz";

function isLangQuestionDescriptionDefined(questionId: string, surveyLang?: Survey.Lang) {
    return surveyLang?.questions[questionId]?.description !== undefined;
}

export const questionToTreeNode = (
    lang: BaseLang,
    surveyVersion: Survey.Version,
    selectedSurveyLang: Survey.Lang,
    defaultSurveyLang: Survey.Lang | undefined,
    surveyLangs: Survey.Lang[],
    parentKey: string,
    key: string,
    question: Question,
    setQuestion: (question: Question) => void,
    deleteQuestion: () => Promise<void>,
    user: User,
    comments: Survey.Comment[]
): SurveyTreeNode => {
    const target: Survey.NodeTarget = {
        type: Survey.NodeType.QUESTION,
        property: Survey.NodeTargetProperty.TEXT,
        nodeId: question.id
    };
    const children: SurveyTreeNode[] = [];
    const isLangPublished = isSurveyPublished(surveyVersion);

    function updateDescription(surveyLang: Survey.Lang, value: string): void {
        return updateLang(surveyLang, user.uid).question(question.id).description(value);
    }

    // Add description node if needed or if the description is not translated yet
    if (isLangQuestionDescriptionDefined(question.id, selectedSurveyLang) || isLangQuestionDescriptionDefined(question.id, defaultSurveyLang)) {
        const target: Survey.NodeTarget = {
            type: Survey.NodeType.QUESTION,
            property: Survey.NodeTargetProperty.DESCRIPTION,
            nodeId: question.id
        };
        children.push({
            key: `${key}-desc`,
            parentKey: key,
            target,
            icon: <SurveyIcons.SectionDescription />,
            title: (
                <SurveyNodeEditableCell
                    nodeKey={`${key}-desc`}
                    target={target}
                    onChange={updateDescription}
                    onDelete={isLangPublished ? undefined : () => updateLang(selectedSurveyLang, user.uid).question(question.id).description(null)}
                    defaultValue={getNodeTargetValue(target, defaultSurveyLang)}
                    nodeValue={getNodeTargetValue(target, selectedSurveyLang)}
                    surveyLang={selectedSurveyLang}
                    suggestionComment={
                        comments.filter(comm => sameTarget(comm.target, target)).sort((a, b) => b.creationDate?.getTime() - a.creationDate?.getTime())[0]
                    }
                />
            ),
            data: question
        });
    }
    const preambleSection = surveyVersion.preambleSectionId ? query(surveyVersion).getSectionFromId(surveyVersion.preambleSectionId) : null;
    const actions = [
        {
            key: EditableCellActionType.ADD_QUESTION_DESCRIPTION,
            icon: <SurveyIcons.QuestionDescription />,
            tooltip: "Double click to add a new description",
            hide: selectedSurveyLang.questions?.[question.id]?.description !== undefined,
            onDoubleClick: () => updateLang(selectedSurveyLang, user.uid).question(question.id).description("")
        },
        {
            key: EditableCellActionType.NEW_OPTION,
            icon: <SurveyIcons.Option size="xs" />,
            tooltip: "Double click to add a new option",
            onDoubleClick: () => {
                const newOption = newEmptyOption();
                updateLang(selectedSurveyLang, user.uid).option(newOption.id).text("");
                setQuestion({ ...question, options: [...question.options, newOption] });
            }
        }
    ];

    const maxLength = OptionIndexDisplay.length;
    const isPreambleQuestion = !!preambleSection && query(preambleSection).hasQuestion(question.id);
    return {
        parentKey,
        key,
        target,
        icon: <SurveyIcons.Question />,
        title: (
            <TreeNodeEditableCell
                nodeKey={key}
                target={target}
                actions={actions}
                comments={comments}
                defaultSurveyLang={defaultSurveyLang}
                selectedSurveyLang={selectedSurveyLang}
                surveyVersion={surveyVersion}
                onChange={(surveyLang, text) => updateLang(surveyLang, user.uid).question(question.id).text(text)}
                onDelete={deleteQuestion}
            />
        ),
        data: question,
        children:
            question.options.length > 0
                ? [
                      ...children,
                      ...question.options.map((option, i) => {
                          const alphabetIndex = Math.floor(i / maxLength) - 1;
                          const nodeKey = i >= maxLength ? `${OptionIndexDisplay[alphabetIndex]}${OptionIndexDisplay[i % maxLength]}` : OptionIndexDisplay[i];
                          return optionToTreeNode(
                              lang,
                              surveyVersion,
                              selectedSurveyLang,
                              defaultSurveyLang,
                              surveyLangs,
                              key,
                              question,
                              `${key}-${nodeKey}`,
                              question.optionsType,
                              option,
                              updatedOption => setQuestion(query(question).updateOption(updatedOption)),
                              async () => {
                                  try {
                                      await recursiveDeleteOption(surveyLangs, option, user.uid);
                                      setQuestion(query(question).deleteOption(option.id));
                                  } catch (err) {
                                      void message.error(err.message);
                                  }
                              },
                              user,
                              comments
                          );
                      })
                  ]
                : children,
        option: (
            <Select.Basic
                isDisabled={isLangPublished}
                onChange={value => setQuestion({ ...question, optionsType: value })}
                options={[
                    {
                        value: Question.OptionType.Checkbox,
                        label: <CheckSquareTwoToneIcon isTwoToneColor />
                    },
                    {
                        value: Question.OptionType.Radio,
                        label: <PositiveTwoToneIcon isTwoToneColor />
                    }
                ]}
                value={question.optionsType}
                styles={{
                    control: provided => ({
                        ...provided,
                        border: 0,
                        boxShadow: "none",
                        padding: "0 !important",
                        height: "1.5rem !important",
                        backgroundColor: "transparent !important"
                    }),
                    dropdownIndicator: provided => ({
                        ...provided,
                        display: "none"
                    })
                }}
            />
        ),
        tagEval: getTagEvalColumns(query(question).getOrderedQuestions()),
        variants: getVariantColumns(
            surveyVersion,
            question,
            isPreambleQuestion,
            !isPreambleQuestion ? () => openQuestionVariantModal(lang, question, surveyVersion, setQuestion, isLangPublished) : undefined
        ),
        weight: (
            <InputNumber
                className="-my-0.5 w-12"
                min={0.1}
                max={10}
                size="small"
                defaultValue={question.weight}
                onChange={value => setQuestion({ ...question, weight: value !== null ? value : 0 })}
            />
        )
    };
};

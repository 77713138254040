import { Account, UserProfile } from "@vaultinum/vaultinum-api";
import { Button, Column, getAccountsByIds, message } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import Flag from "react-flagkit";
import { useNavigate } from "react-router-dom";
import { TableLayout } from "../../../components";
import { getAccountLink } from "../../../services/routing.service";

function getColumns(onView: (account: Account) => void): Column<Account>[] {
    return [
        {
            header: "Name",
            accessorKey: "companyName"
        },
        { header: "Industry", accessorKey: "companyIndustry" },
        { header: "Size", accessorKey: "companySize" },
        {
            header: "Nationality",
            accessorKey: "companyNationality",
            cell: cell => {
                const companyNationality = cell.getValue<string>();
                return companyNationality ? <Flag country={companyNationality} alt={companyNationality} title={companyNationality} /> : "N/A";
            },
            size: 80
        },
        { header: "Domain", accessorKey: "whiteLabelDomainId" },
        {
            header: "Actions",
            enableSorting: false,
            enableColumnFilter: false,
            cell: cell => <Button isLoading={false} size="sm" onClick={() => onView(cell.row.original)} children="View" />
        }
    ];
}

export function UserProfileAccountsCard({ userProfile }: { userProfile: UserProfile }): JSX.Element {
    const [accounts, setAccounts] = useState<Account[]>();
    const navigate = useNavigate();

    useEffect(() => {
        void (async () => {
            try {
                const linkedAccounts = await getAccountsByIds(userProfile?.accountIds || []);
                setAccounts(linkedAccounts);
            } catch {
                void message.error("Failed to load linked accounts");
                setAccounts([]);
            }
        })();
    }, [userProfile]);

    const onView = (account: Account) => {
        navigate(getAccountLink(account));
    };

    const columns = getColumns(onView);

    return <TableLayout<Account> title="Linked accounts" items={accounts} columns={columns} />;
}

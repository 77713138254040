import { BaseLang, Buttons, InfoCircleTwoToneIcon, Modal, useLang, useModal } from "@vaultinum/vaultinum-sdk";
import { SHORTCUTS, shortcutKeys } from "../ShortcutsHelper";

export function KeyboardShortcutsModalButton() {
    const lang = useLang<BaseLang>();
    const { isOpen, doOpen, doClose } = useModal();
    return (
        <>
            <Buttons.Details icon={InfoCircleTwoToneIcon} isLoading={false} onClick={doOpen} />
            <Modal
                title="Keyboard Shortcuts"
                isOpen={isOpen}
                children={SHORTCUTS.map((shortcut, i) => (
                    <div key={i}>
                        <span>{shortcut.name}</span>
                        <span style={{ float: "right" }}>{shortcutKeys(shortcut)}</span>
                    </div>
                ))}
                lang={lang}
                onClose={doClose}
                okText="Ok"
            />
        </>
    );
}

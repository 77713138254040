import classnames from "classnames";
import { CSSProperties, useContext, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { SurveyEditableNodesContext } from "./SurveyEditableNodesContextProps";
import { canBeSibling, shouldBeInsertAsChild } from "./nodes/SurveyTreeNodeTools";
import { SurveyTreeNode } from "./nodes/TreeNode";
import { ADD_NODE_KEY } from "./nodes/AddNode";

export default function DragableBodyRow({
    index,
    moveRow,
    node,
    canDragRows,
    className,
    style,
    ...restProps
}: {
    index: number;
    moveRow: (fromKey: string, toKey: string | undefined, insertAsChild: boolean) => void;
    node?: SurveyTreeNode;
    canDragRows?: boolean;
    className: string;
    style: CSSProperties;
}): JSX.Element {
    const ref = useRef(null);
    const { selectedNodeKeys = [], setSelectedNodeKeys, ctrlPressed, copiedNodes, editedTarget } = useContext(SurveyEditableNodesContext);
    const canDragRow = canDragRows && !!node?.target;
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: "DragableBodyRow",
        collect: monitor => {
            const { node: dragNode } = monitor.getItem<{ node: SurveyTreeNode }>() || {};
            const dragKey = dragNode?.key;
            const targetKey = node?.key;
            if (!targetKey || dragKey === targetKey) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: targetKey.localeCompare(dragKey) > 0 ? " drop-over-downward" : " drop-over-upward"
            };
        },
        canDrop: (_item: { node: SurveyTreeNode }, monitor) => {
            const { node: dragNode } = monitor.getItem<{ node: SurveyTreeNode }>();
            return canBeSibling(dragNode, node);
        },
        drop: (item: { node: SurveyTreeNode }) => {
            const { node: dragNode } = item;
            const insertAsChild = shouldBeInsertAsChild(dragNode, node);
            moveRow(dragNode.key, node?.key, insertAsChild);
        }
    });
    const [dragProps, drag] = useDrag({
        type: "DragableBodyRow",
        item: { index, node },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        canDrag: canDragRow
    });
    drop(drag(ref));
    const isSelected = node && selectedNodeKeys.includes(node.key);
    const isCut = node && copiedNodes && copiedNodes.op === "cut" && copiedNodes.nodes.some(cutNode => cutNode === node);
    const isCopy = node && copiedNodes && copiedNodes.op === "copy" && copiedNodes.nodes.some(cutNode => cutNode === node);
    const toggleSelected = () => {
        if (node && setSelectedNodeKeys) {
            if (isSelected) {
                if (selectedNodeKeys.length > 1 && !ctrlPressed) {
                    setSelectedNodeKeys([node.key]);
                }
            } else if (ctrlPressed) {
                setSelectedNodeKeys([...selectedNodeKeys, node.key]);
            } else {
                setSelectedNodeKeys([node.key]);
            }
        }
    };

    return (
        <tr
            onClickCapture={toggleSelected}
            ref={ref}
            draggable={!editedTarget}
            className={classnames(className, {
                selected: isSelected,
                "cut-copy": isCut || isCopy,
                [dropClassName || ""]: isOver,
                dragging: dragProps.isDragging,
                "not-allowed": node?.key?.endsWith(ADD_NODE_KEY)
            })}
            style={{ cursor: canDragRow ? "move" : "cursor", ...style }}
            {...restProps}
        />
    );
}

import { AnalysedCodeRepository } from "@vaultinum/vaultinum-api";
import { CodeAnalysisReport } from "@vaultinum/vaultinum-kys-api";
import { EnrichedFullAudit } from "@vaultinum/vaultinum-sdk";
import { createContext } from "react";
type CodeAuditContextType = {
    report?: CodeAnalysisReport | null;
    codeRepositories: AnalysedCodeRepository[];
    fullAudit: EnrichedFullAudit;
};

export const CodeAuditContext = createContext<CodeAuditContextType>({} as CodeAuditContextType);

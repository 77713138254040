import { getNodeTargetValue, isDefined, Survey } from "@vaultinum/vaultinum-api";
import {
    BaseLang,
    Button,
    Buttons,
    Controller,
    Drawers,
    DropdownActionProps,
    Form,
    Input,
    List,
    MessageIcon,
    ModalHelper,
    SectionTitle,
    SurveyIcon,
    useForm,
    useLang,
    User,
    useRequiredString,
    WarningFilledIcon,
    yup
} from "@vaultinum/vaultinum-sdk";
import { groupBy, partition, startCase, uniqBy } from "lodash";
import { ReactNode, useMemo, useState } from "react";
import { sameTarget } from "../../../../../common/AccountTools";
import { DEPRECATED_AUTHOR } from "../../../../../constants";
import { addSurveyComment, applyCommentSuggestions, resolveComments } from "../../../../../services/surveyCommentService";
import CommentCard from "../../components/CommentCard";
import SurveyNodeTargetIcon from "../../components/SurveyNodeTargetIcon";
import { NodeWithIssues } from "../HealthCheckTools";
import { containsNodeTarget, getNodeAndChildren, getNodeTargets } from "./nodes/SurveyTreeNodeTools";
import { SurveyTreeNode } from "./nodes/TreeNode";
import "./SurveyCommentPanel.css";

enum CommentFilterValue {
    SUGGESTION = "suggestions",
    COMMENT = "comments",
    DEPRECATION = "deprecations"
}

const getIdFromTarget = (target: Survey.NodeTarget): string => `${target.type}-${target.nodeId}-${target.property}-${target.subNodeId}`;

function filterComments(comments: Survey.Comment[], selectedIssuesFilter: string[]): Survey.Comment[] {
    if (!selectedIssuesFilter.length) {
        return comments;
    }

    const filterSet = new Set(selectedIssuesFilter);
    return comments.filter(comment => {
        const isSuggestion = filterSet.has(CommentFilterValue.SUGGESTION) && comment.changeSuggestion;
        const isComment = filterSet.has(CommentFilterValue.COMMENT) && comment.comment && comment.authorUID !== DEPRECATED_AUTHOR;
        const isDeprecation = filterSet.has(CommentFilterValue.DEPRECATION) && comment.authorUID === "Deprecation";

        return isSuggestion || isComment || isDeprecation;
    });
}

function buildCommentFilterList(comments: Survey.Comment[]): { value: string; label: string }[] {
    return uniqBy(
        comments
            .map(comment => {
                if (comment.changeSuggestion) {
                    return { value: CommentFilterValue.SUGGESTION, label: startCase(CommentFilterValue.SUGGESTION) };
                }
                if (comment.comment && comment.authorUID !== DEPRECATED_AUTHOR) {
                    return { value: CommentFilterValue.COMMENT, label: startCase(CommentFilterValue.COMMENT) };
                }
                if (comment.authorUID === DEPRECATED_AUTHOR) {
                    return { value: CommentFilterValue.DEPRECATION, label: DEPRECATED_AUTHOR };
                }
                return undefined;
            })
            .filter(isDefined),
        "value"
    );
}

function InfoCard({
    node,
    surveyLang,
    children,
    jumpToNode
}: {
    node?: SurveyTreeNode;
    surveyLang: Survey.Lang;
    children: ReactNode;
    jumpToNode?: (node: SurveyTreeNode) => void;
}) {
    return (
        <>
            {node?.target && (
                <p className="my-1">
                    <span className="link" onClick={() => jumpToNode && jumpToNode(node)}>
                        <SurveyNodeTargetIcon target={node.target} /> {node?.key}
                    </span>{" "}
                    {getNodeTargetValue(node.target, surveyLang)}
                </p>
            )}
            <div className="relative h-full overflow-hidden bg-grey-extra-light py-2 px-5 mb-2">{children}</div>
        </>
    );
}

function CommentActions({
    selectedSurveyLang,
    comments,
    user,
    setSelectedIssuesFilter,
    selectedIssuesFilter,
    filteredComments
}: {
    selectedSurveyLang: Survey.Lang;
    comments: Survey.Comment[];
    user: User;
    setSelectedIssuesFilter: (value: string[]) => void;
    selectedIssuesFilter: string[];
    filteredComments: Survey.Comment[];
}): JSX.Element | null {
    const lang = useLang<BaseLang>();

    const [commentsOnly, commentsWithSuggestion] = partition(filteredComments, comment => !comment.changeSuggestion);
    const actions: DropdownActionProps[] = [];

    if (commentsOnly.length > 1) {
        actions.push({
            label: "Resolve all comments",
            onClick: () =>
                ModalHelper.Confirm({
                    title: "Resolve all comments",
                    children: `Do you want to resolve these ${commentsOnly.length} comments?`,
                    onConfirm: () => resolveComments(commentsOnly, user.uid),
                    lang
                })
        });
    }
    if (commentsWithSuggestion.length > 1) {
        actions.push({
            label: "Apply all suggestions",
            onClick: () =>
                ModalHelper.Confirm({
                    title: "Apply all suggestions",
                    children: `Do you want to apply these ${commentsWithSuggestion?.length} suggestions?`,
                    onConfirm: () => applyCommentSuggestions(selectedSurveyLang, commentsWithSuggestion, user.uid),
                    lang
                })
        });
    }

    const commentFilterList = buildCommentFilterList(comments);
    return (
        <div className="flex w-full items-end justify-end gap-2">
            {commentFilterList.length > 1 && (
                <Buttons.Filter label="Filter by" defaultList={selectedIssuesFilter} list={commentFilterList} onSubmit={setSelectedIssuesFilter} />
            )}
            {!!actions.length && <Buttons.Dropdown type="default" isLoading={false} children="Bulk actions" actions={actions} />}
        </div>
    );
}

function SubNodesComments({
    nodeChildren,
    user,
    selectedSurveyLang,
    comments,
    targetId,
    jumpToNode
}: {
    nodeChildren: SurveyTreeNode[];
    user: User;
    selectedSurveyLang: Survey.Lang;
    comments: Survey.Comment[];
    targetId: string;
    jumpToNode?: (node: SurveyTreeNode) => void;
}) {
    const [selectedIssuesFilter, setSelectedIssuesFilter] = useState<string[]>([]);

    const { groupedComments, commentIds } = useMemo(() => {
        const groupedComments = groupBy(filterComments(comments, selectedIssuesFilter), comment => getIdFromTarget(comment.target));
        return { groupedComments, commentIds: Object.keys(groupedComments).filter(id => id !== targetId) };
    }, [comments, targetId, selectedIssuesFilter]);

    if (!groupedComments) {
        return null;
    }

    return (
        <div className="flex h-full flex-col space-y-2">
            <CommentActions
                selectedIssuesFilter={selectedIssuesFilter}
                setSelectedIssuesFilter={setSelectedIssuesFilter}
                selectedSurveyLang={selectedSurveyLang}
                comments={comments}
                filteredComments={filterComments(comments, selectedIssuesFilter)}
                user={user}
            />
            <List
                list={commentIds}
                render={id => (
                    <CommentHeader
                        node={nodeChildren.find(node => sameTarget(node.target, groupedComments[id]?.length ? groupedComments[id][0]?.target : null))}
                        user={user}
                        selectedSurveyLang={selectedSurveyLang}
                        comments={groupedComments[id]}
                        jumpToNode={jumpToNode}
                    />
                )}
                isVirtualized
            />
        </div>
    );
}

function CommentHeader({
    user,
    node,
    selectedSurveyLang,
    canComment,
    comments,
    isVirtualized,
    jumpToNode
}: {
    user: User;
    node?: SurveyTreeNode;
    selectedSurveyLang: Survey.Lang;
    canComment?: boolean;
    comments?: Survey.Comment[];
    isVirtualized?: boolean;
    jumpToNode?: (node: SurveyTreeNode) => void;
}) {
    const [selectedIssuesFilter, setSelectedIssuesFilter] = useState<string[]>([]);

    const schema = yup.object().shape({
        comment: useRequiredString()
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm<{ comment: string }>({
        schema
    });

    const onSubmitComment = async ({ comment }: { comment: string }) => {
        if (node?.target) {
            await addSurveyComment({
                authorUID: user.uid,
                lang: selectedSurveyLang.lang,
                target: node.target,
                surveyKey: selectedSurveyLang.surveyKey,
                surveyVersion: selectedSurveyLang.surveyVersion,
                comment
            });
            reset({
                comment: ""
            });
        }
    };

    const infoCard = (
        <div className="flex flex-1 flex-col">
            {!!comments?.length && (
                <InfoCard node={node} surveyLang={selectedSurveyLang} jumpToNode={jumpToNode}>
                    <List
                        list={filterComments(comments, selectedIssuesFilter)}
                        render={comment => <CommentCard user={user} comment={comment} selectedSurveyLang={selectedSurveyLang} />}
                        isVirtualized={isVirtualized}
                    />
                </InfoCard>
            )}
            {canComment && (
                <Form className="new-comment-form" onSubmit={handleSubmit(onSubmitComment)}>
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => <Input.Text {...field} placeholder="Your comment..." errorMessage={errors.comment?.message} required />}
                    />
                    <Button htmlType="submit" isLoading={false} children="Comment" />
                </Form>
            )}
        </div>
    );

    if (isVirtualized) {
        return (
            <div className="flex h-full flex-col space-y-2">
                <CommentActions
                    selectedIssuesFilter={selectedIssuesFilter}
                    setSelectedIssuesFilter={setSelectedIssuesFilter}
                    selectedSurveyLang={selectedSurveyLang}
                    comments={comments ?? []}
                    filteredComments={filterComments(comments ?? [], selectedIssuesFilter)}
                    user={user}
                />
                {infoCard}
            </div>
        );
    }
    return infoCard;
}

function IssueCard({
    issues,
    node,
    selectedSurveyLang,
    jumpToNode
}: {
    issues: NodeWithIssues[];
    node?: SurveyTreeNode;
    selectedSurveyLang: Survey.Lang;
    jumpToNode?: (node: SurveyTreeNode) => void;
}) {
    if (!issues.length) {
        return null;
    }
    return (
        <InfoCard node={node} surveyLang={selectedSurveyLang} jumpToNode={jumpToNode}>
            <List list={issues} render={issue => <>{issue.issue}</>} />
        </InfoCard>
    );
}

export default function SurveyCommentPanel({
    user,
    onClose,
    node,
    comments,
    issues,
    selectedSurveyLang,
    jumpToNode
}: {
    user: User;
    onClose: () => void;
    node: SurveyTreeNode;
    comments: Survey.Comment[];
    issues: NodeWithIssues[];
    selectedSurveyLang: Survey.Lang;
    jumpToNode: (node: SurveyTreeNode) => void;
}) {
    const [selectedIssuesFilter, setSelectedIssuesFilter] = useState<string[]>([]);

    if (!node.target) {
        return <div>Invalid node</div>;
    }
    const targetId = getIdFromTarget(node.target);
    const nodeChildren = getNodeAndChildren(node);
    const nodeTargets = getNodeTargets(node);

    const targetComments = comments.filter(comment => sameTarget(comment.target, node.target));
    const subComments = comments.filter(comment => containsNodeTarget(nodeTargets, comment.target)).filter(comment => !sameTarget(comment.target, node.target));

    const nodeIssues = issues.filter(issue => sameTarget(node.target, issue.target));
    const subNodeIssues = issues.filter(issue => containsNodeTarget(nodeTargets, issue.target)).filter(issue => !sameTarget(issue.target, node.target));
    const groupedIssues = groupBy(
        subNodeIssues.filter(issue => {
            if (selectedIssuesFilter.length) {
                return selectedIssuesFilter.includes(issue.issue);
            }
            return true;
        }),
        issue => getIdFromTarget(issue.target)
    );

    const issueFilterList = uniqBy(
        [...nodeIssues, ...subNodeIssues].map(issue => ({ value: issue.issue, label: issue.issue })),
        "value"
    );

    const filteredNodeIssues = nodeIssues.filter(issue => !selectedIssuesFilter.length || selectedIssuesFilter.includes(issue.issue));
    const filteredSubNodeIssues = subNodeIssues.filter(issue => !selectedIssuesFilter.length || selectedIssuesFilter.includes(issue.issue));

    return (
        <Drawers.WithTabs
            header={{
                title: "Comments and issues",
                Icon: SurveyIcon
            }}
            size="lg"
            onClose={onClose}
            isVisible
            tabs={[
                {
                    key: "comments",
                    icon: MessageIcon,
                    title: `Comments (${targetComments.length})`,
                    children: (
                        <CommentHeader
                            selectedSurveyLang={selectedSurveyLang}
                            node={node}
                            user={user}
                            canComment
                            comments={targetComments}
                            jumpToNode={jumpToNode}
                            isVirtualized
                        />
                    )
                },
                {
                    key: "issues",
                    icon: WarningFilledIcon,
                    title: `Issues (${filteredNodeIssues.length + filteredSubNodeIssues.length})`,
                    children: (
                        <>
                            {issueFilterList.length > 1 && (
                                <div className="flex justify-end">
                                    <Buttons.Filter
                                        label="Issues with"
                                        defaultList={selectedIssuesFilter}
                                        list={issueFilterList}
                                        onSubmit={setSelectedIssuesFilter}
                                    />
                                </div>
                            )}
                            <div>
                                <IssueCard node={node} issues={filteredNodeIssues} selectedSurveyLang={selectedSurveyLang} jumpToNode={jumpToNode} />
                            </div>
                            <>
                                {Object.keys(groupedIssues).length > 0 && (
                                    <>
                                        {subNodeIssues.length > 0 && <SectionTitle title={`Sub-nodes issues (${filteredSubNodeIssues.length})`} />}
                                        <div>
                                            {Object.keys(groupedIssues).map(targetId => (
                                                <IssueCard
                                                    key={targetId}
                                                    node={nodeChildren.find(node => sameTarget(node.target, groupedIssues[targetId][0].target))}
                                                    issues={groupedIssues[targetId]}
                                                    selectedSurveyLang={selectedSurveyLang}
                                                    jumpToNode={jumpToNode}
                                                />
                                            ))}
                                        </div>
                                    </>
                                )}
                            </>
                        </>
                    )
                },
                ...(subComments.length > 0
                    ? [
                          {
                              key: "subcomments",
                              icon: MessageIcon,
                              title: `Sub-nodes comments (${subComments.length})`,
                              children: (
                                  <SubNodesComments
                                      nodeChildren={nodeChildren}
                                      targetId={targetId}
                                      user={user}
                                      selectedSurveyLang={selectedSurveyLang}
                                      comments={subComments}
                                      jumpToNode={jumpToNode}
                                  />
                              )
                          }
                      ]
                    : [])
            ]}
        />
    );
}

import { FullAudit } from "@vaultinum/vaultinum-api";
import { DownloadIcon } from "@vaultinum/vaultinum-sdk";
import { AsyncActionButton } from ".";
import { downloadCodeScanReport } from "../services/fullAuditService";

export default function DownloadReportButton({ fullAudit }: { fullAudit: FullAudit }): JSX.Element {
    async function downloadReport(): Promise<string> {
        try {
            await downloadCodeScanReport(fullAudit);
            return "Analysis report downloaded";
        } catch (err) {
            throw new Error("An error occured while downloading the report");
        }
    }
    return <AsyncActionButton onClick={downloadReport} icon={DownloadIcon} children="Download reports" />;
}

import { Grade, GRADES, Survey } from "@vaultinum/vaultinum-api";

export function updateScoreRange(scoreRange: Survey.GradeScoreRange, grade: Grade, range: [number, number]): Survey.GradeScoreRange {
    const gradeIndex = GRADES.indexOf(grade);
    if (gradeIndex > -1) {
        const newScoreRange: Survey.GradeScoreRange = [...scoreRange];
        newScoreRange[gradeIndex] = range[1];
        newScoreRange[gradeIndex + 1] = range[0];
        return newScoreRange;
    }
    return scoreRange;
}

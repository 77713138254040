import { Survey } from "@vaultinum/vaultinum-api";
import { CopyIcon, DeleteTwoToneIcon, EditTwoToneIcon, Input, openNotificationWithIcon, Spin, Tooltip } from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import ReactMarkdown from "react-markdown";
import { sameTarget } from "../../../../common/AccountTools";
import "./EditableCell.css";

export enum EditableCellActionType {
    DELETE = "DELETE",
    EDIT = "EDIT",
    APPLY_SUGGESTION = "APPLY_SUGGESTION",
    DISCARD_SUGGESTION = "DISCARD_SUGGESTION",
    ICON = "ICON",
    NEW_OPTION = "NEW_OPTION",
    SET_VARIANT = "SET_VARIANT",
    ADD_QUESTION = "ADD_QUESTION",
    ADD_QUESTION_DESCRIPTION = "ADD_QUESTION_DESCRIPTION",
    ADD_NEW_QUESTION = "ADD_NEW_QUESTION",
    WHY_CONTEXT = "WHY_CONTEXT",
    FIX_SUGGESTION = "FIX_SUGGESTION",
    SET_SECTION_AS_PREAMBLE = "SET_SECTION_AS_PREAMBLE",
    ADD_SECTION_DESCRIPTION = "ADD_SECTION_DESCRIPTION",
    ADD_NEW_SUB_SECTION = "ADD_NEW_SUB_SECTION",
    COPY_QUESTION_ID = "COPY_QUESTION_ID"
}

export type EditableCellActionProps = {
    key: EditableCellActionType;
    icon: ReactNode;
    onDoubleClick?: () => Promise<void> | void;
    tooltip?: string;
    hide?: boolean;
};

export type EditableCellProps = {
    value?: string;
    onChange?: (value: string) => Promise<void>;
    onDelete?: () => Promise<void> | void;
    search?: string;
    caseSensitive?: boolean;
    displayValue?: ReactNode;
    disabled?: boolean;
    extraActions?: EditableCellActionProps[];
    editIcon?: ReactNode;
    editedTarget?: Survey.NodeTarget;
    setEditedTarget?: (target: Survey.NodeTarget | undefined) => void;
    target: Survey.NodeTarget;
    surveyKey: string;
};

export default function EditableCell({
    value,
    onChange,
    onDelete,
    search,
    caseSensitive,
    displayValue,
    disabled,
    extraActions,
    editIcon = <EditTwoToneIcon size="xs" isTwoToneColor />,
    setEditedTarget,
    editedTarget,
    target,
    surveyKey
}: EditableCellProps): JSX.Element {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [working, setWorking] = useState(false);
    const [pendingAction, setPendingAction] = useState<EditableCellActionProps | null>(null);
    const [text, setText] = useState<string | undefined>(value);

    useEffect(() => {
        if (editedTarget && sameTarget(editedTarget, target)) {
            inputRef.current?.focus();
        }
    }, [editedTarget, target]);

    const toggleEdit = () => {
        if (setEditedTarget) {
            setEditedTarget(editedTarget ? undefined : target);
        }
    };

    const save = async () => {
        if (onChange) {
            try {
                setWorking(true);
                await onChange(text || "");
            } finally {
                setWorking(false);
            }
        }
        toggleEdit();
    };
    const onEditKeyPressed = async (key: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (key.key === "Escape") {
            toggleEdit();
        }
        if (key.key === "Enter" && !key.shiftKey) {
            await save();
        }
    };

    const highlightValue = useMemo(() => {
        return (
            <Highlighter
                highlightClassName="search-match"
                searchWords={search ? [search] : []}
                autoEscape
                caseSensitive={caseSensitive}
                textToHighlight={value ?? ""}
            />
        );
    }, [caseSensitive, search, value]);

    const handleDoubleClick = async (action: EditableCellActionProps) => {
        if (action.onDoubleClick) {
            setPendingAction(action);
            try {
                await action.onDoubleClick();
            } catch (e) {
                openNotificationWithIcon({ type: "error", description: "Error while performing action" });
            } finally {
                setPendingAction(null);
            }
        }
    };

    const DisplayValue = useMemo(
        () => (): JSX.Element | null => {
            if (displayValue) {
                return <>{displayValue}</>;
            }
            if (search) {
                return highlightValue;
            }
            if (value) {
                return (
                    <div className="prose prose-sm">
                        <ReactMarkdown className="markdown">{value}</ReactMarkdown>
                    </div>
                );
            }
            return null;
        },
        [displayValue, value, search, highlightValue]
    );

    if (disabled) {
        return <span className="editable-cell">{displayValue || highlightValue}</span>;
    }
    if (!editedTarget || !sameTarget(editedTarget, target)) {
        const actions: EditableCellActionProps[] = [
            ...(target.type === Survey.NodeType.QUESTION
                ? [
                      {
                          key: EditableCellActionType.COPY_QUESTION_ID,
                          icon: <CopyIcon size="xs" color="blue" />,
                          tooltip: "Copy question path to clipboard",
                          onDoubleClick: async () => {
                              await navigator.clipboard.writeText(`surveys.${surveyKey}.${target.nodeId}`);
                              openNotificationWithIcon({ type: "success", description: "Question path saved to clipboard" });
                          }
                      }
                  ]
                : []),
            {
                key: EditableCellActionType.EDIT,
                icon: editIcon,
                onDoubleClick: toggleEdit,
                tooltip: "Double-click text to edit"
            }
        ];
        if (onDelete) {
            actions.push({
                key: EditableCellActionType.DELETE,
                tooltip: "Double-click to delete",
                icon: <DeleteTwoToneIcon size="xs" isTwoToneColor />,
                onDoubleClick: async () => {
                    await onDelete();
                }
            });
        }
        if (extraActions) {
            actions.push(...extraActions);
        }

        return (
            <span className="editable-cell">
                <div className="editable-cell-value-wrap" onDoubleClick={toggleEdit}>
                    <DisplayValue />
                </div>
                <div className="actions">
                    {actions
                        .filter(action => !action.hide)
                        .map((action, i) => (
                            <Tooltip key={i} title={action.tooltip}>
                                <button
                                    disabled={!!pendingAction}
                                    className={classNames("action-icon", {
                                        "opacity-50": !!pendingAction
                                    })}
                                    onDoubleClick={() => handleDoubleClick(action)}
                                >
                                    {pendingAction && pendingAction.key === action.key ? <Spin size="small" /> : action.icon}
                                </button>
                            </Tooltip>
                        ))}
                </div>
            </span>
        );
    }
    return (
        <Input.TextArea
            className="editable-cell-value-wrap editing w-full"
            onBlur={save}
            forwardedRef={inputRef}
            onChange={e => setText(e.target.value)}
            defaultValue={value}
            disabled={working}
            onKeyDown={onEditKeyPressed}
        />
    );
}

import { Button, LockIcon } from "@vaultinum/vaultinum-sdk";
import { useNavigate } from "react-router-dom";
import { LOGIN_PAGE } from "../../services/routing.service";

export default function LandingPage() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center justify-center text-center">
            <div className="flex items-center justify-center gap-2 p-4">
                <LockIcon />
                <h2>Restricted access</h2>
            </div>
            <Button isLoading={false} onClick={() => navigate(LOGIN_PAGE)} children="Login" />
        </div>
    );
}

import { WhiteLabelDomain, DOMAIN_COLLECTION } from "@vaultinum/vaultinum-api";
import { addDoc, collection, getFirestore, serverTimestamp, updateDoc, doc } from "@vaultinum/vaultinum-sdk";
import { pickBy, isUndefined } from "lodash";

export function addWhiteLabelDomain(domain: Omit<WhiteLabelDomain, "id">, staffUserId: string): Promise<WhiteLabelDomain> {
    return addDoc(collection(getFirestore(), DOMAIN_COLLECTION), {
        ...pickBy(domain, field => !isUndefined(field)),
        creationDate: serverTimestamp(),
        createdByUID: staffUserId
    }) as unknown as Promise<WhiteLabelDomain>;
}

export function updateWhiteLabelDomain(domainId: string, domain: Partial<WhiteLabelDomain>): Promise<void> {
    return updateDoc(
        doc(collection(getFirestore(), DOMAIN_COLLECTION), domainId),
        pickBy(domain, field => !isUndefined(field))
    );
}

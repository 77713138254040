import { UserProfile } from "@vaultinum/vaultinum-api";
import { addQueryParamsToUrl, BreadcrumbItems, formatDisplayName, Spin, Tabs, useUrlSearch } from "@vaultinum/vaultinum-sdk";
import { kebabCase } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ModelItemPageHeader, TitleWithBadge } from "../../../components";
import { getUserProfileNotificationsCount } from "../../../services/notificationService";
import { USER_PROFILES_PAGE, UserProfileTab } from "../../../services/routing.service";
import { getUserProfile } from "../../../services/userProfileService";
import { UserProfileAccountsCard } from "./UserProfileAccountsCard";
import { UserProfileInfoCard } from "./UserProfileInfoCard";
import { UserProfileNotifications } from "./UserProfileNotifications";

export default function UserProfilePage() {
    const { userProfileId } = useParams<{ userProfileId: string }>();
    const searchUrlParams = useUrlSearch() as { tab?: string };
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const activeTab = searchUrlParams.tab || kebabCase(UserProfileTab.userProfile);
    const [notificationsCount, setNotificationsCount] = useState<number>(0);

    function onTabChange(newTab: string) {
        navigate(addQueryParamsToUrl(location.pathname, { ...searchUrlParams, tab: kebabCase(newTab) }));
    }

    useEffect(() => getUserProfile(userProfileId, setUserProfile), [userProfileId]);
    useEffect(() => {
        if (userProfileId) {
            return getUserProfileNotificationsCount(userProfileId, setNotificationsCount);
        }
        return () => {};
    }, [userProfileId]);

    if (!userProfile) {
        return <Spin />;
    }

    const breadcrumbItems: BreadcrumbItems = [
        {
            label: "User Profiles",
            href: USER_PROFILES_PAGE
        },
        {
            label: formatDisplayName(userProfile)
        }
    ];

    return (
        <div className="flex-1 space-y-1">
            <ModelItemPageHeader breadcrumbItems={breadcrumbItems} />
            <Tabs activeKey={activeTab} onChange={onTabChange} className="h-full flex-1">
                <Tabs.TabPane className="icon space-y-2" tab={UserProfileTab.userProfile} key={kebabCase(UserProfileTab.userProfile)}>
                    <UserProfileInfoCard userProfile={userProfile} />
                    <UserProfileAccountsCard userProfile={userProfile} />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<TitleWithBadge title={UserProfileTab.notifications} content={notificationsCount} />}
                    key={kebabCase(UserProfileTab.notifications)}
                >
                    <UserProfileNotifications userProfile={userProfile} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
}

import { SupportedLanguageCode, Survey } from "@vaultinum/vaultinum-api";
import { Switch } from "@vaultinum/vaultinum-sdk";
import LangSelector from "./LangSelector";

export default function TranslateSurvey({
    surveyVersion,
    sourceLang,
    surveyLangs,
    onSelectedLangChange,
    selectedTargetLang,
    onCreateSuggestionCommentsChange,
    createSuggestionComments = false
}: {
    surveyVersion: Survey.Version;
    sourceLang: SupportedLanguageCode;
    surveyLangs: Survey.Lang[];
    onSelectedLangChange: (selectedLang: SupportedLanguageCode) => void;
    selectedTargetLang?: SupportedLanguageCode;
    onCreateSuggestionCommentsChange?: (checked: boolean) => void;
    createSuggestionComments?: boolean;
}): JSX.Element {
    return (
        <div className="flex flex-col gap-4 p-2">
            <div className="flex">
                <div className="w-1/2">
                    <h4 className="font-bold">Survey key</h4>
                    <p>{surveyVersion.surveyKey}</p>
                </div>
                <div className="w-1/2">
                    <h4 className="font-bold">Survey version</h4>
                    <p>{surveyVersion.version}</p>
                </div>
            </div>
            <div className="flex flex-row justify-between">
                <div className="w-1/2">
                    <h4 className="font-bold">Source lang</h4>
                    <p className="uppercase">{sourceLang}</p>
                </div>
                <div className="w-1/2">
                    <h4 className="font-bold">Target lang</h4>
                    <LangSelector
                        selectedSurveyLang={selectedTargetLang}
                        surveyLangs={surveyLangs.filter(surveyLang => surveyLang.lang !== sourceLang)}
                        onSelected={onSelectedLangChange}
                    />
                </div>
            </div>
            <div className="flex w-1/2 flex-row items-center">
                <div className="w-1/2">
                    <h4 className="font-bold">Create suggestions comments</h4>
                </div>
                <Switch
                    onClick={() => onCreateSuggestionCommentsChange && onCreateSuggestionCommentsChange(!createSuggestionComments)}
                    isChecked={createSuggestionComments}
                />
            </div>
        </div>
    );
}

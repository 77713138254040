import { diagnosticCount } from "@codemirror/lint";
import { ViewUpdate } from "@codemirror/view";
import { CodeAnalysisReport } from "@vaultinum/vaultinum-kys-api";
import { BaseLang, Modal, Spin, useLang } from "@vaultinum/vaultinum-sdk";
import { JSONSchema7 } from "json-schema";
import { useCallback, useEffect, useState } from "react";
import { JsonEditor } from "../../../../../components";
import { getConfiguration } from "../../../../../services/analyserService";

const auditConfigSchema: JSONSchema7 = {
    type: "object",
    additionalProperties: false,
    required: ["name", "endDate", "auditId", "reportId", "reports"],
    properties: {
        name: { type: "string" },
        companyNames: { type: "array", items: { type: "string" } },
        repos: { type: "array", items: { type: "string" } },
        repositories: {
            type: "object",
            patternProperties: {
                ".*": {
                    type: "object",
                    required: ["refBranch"],
                    additionalProperties: false,
                    properties: {
                        refBranch: { type: "string" }
                    }
                }
            }
        },
        products: {
            type: "object",
            patternProperties: {
                ".*": {
                    type: "object",
                    additionalProperties: false,
                    required: ["rootPaths"],
                    properties: {
                        rootPaths: { type: "array", items: { type: "string" } },
                        type: { type: "string" }
                    }
                }
            }
        },
        auditDate: { type: "string" },
        endDate: { type: "string" },
        auditId: { type: "string" },
        domainId: { type: ["string", "null"] },
        reportId: { type: "string" },
        reports: {
            type: "object",
            additionalProperties: false,
            properties: { fullAuditDataFileId: { type: "string" }, insightFolderId: { type: "string" } }
        },
        ignorePatterns: { type: "array", items: { type: "string" } },
        plugins: { type: "array", items: { type: "string" } },
        pluginConfig: {
            type: "object",
            additionalProperties: true
        },
        lang: { type: "string" },
        muteInsights: { type: "array", items: { type: "string" } },
        scopes: { type: "array", items: { type: "string" } },
        anonymize: {
            anyOf: [
                { type: "boolean" },
                { type: "object", additionalProperties: false, properties: { newCompanyName: { type: "string" }, forbiddenWords: { type: "string" } } }
            ]
        }
    }
};

export default function ConfigurationModal({
    isModalVisible,
    onConfirm,
    closeModal,
    report
}: {
    report: CodeAnalysisReport | null | undefined;
    isModalVisible: boolean;
    onConfirm: (configuration: string | undefined) => void;
    closeModal: () => void;
}): JSX.Element {
    const lang = useLang<BaseLang>();
    const [configuration, setConfiguration] = useState<string | undefined>();
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        if (!report || !isModalVisible) {
            return;
        }
        void (async () => {
            const currentConfiguration = await getConfiguration(report);
            setConfiguration(JSON.stringify(currentConfiguration, null, 2) ?? "");
        })();
    }, [report, isModalVisible]);

    const onChange = useCallback((val: string) => {
        setConfiguration(val);
    }, []);

    function onUpdate(viewUpdate: ViewUpdate) {
        setHasErrors(diagnosticCount(viewUpdate.state) > 0);
    }

    const modalContent = (
        <div className="space-y-4">
            <div className="py-2 font-semibold">Edit the analysis configuration</div>
            {!configuration ? <Spin /> : <JsonEditor value={configuration} schema={auditConfigSchema} onChange={onChange} onUpdate={onUpdate} />}
            <div className="text-xs italic">Note that you will still be able to re-launch the analysis with different configuration.</div>
        </div>
    );

    return (
        <Modal
            isOpen={isModalVisible}
            children={modalContent}
            onConfirm={() => onConfirm(configuration)}
            onClose={closeModal}
            lang={lang}
            isSubmitKeyPressDisabled
            size="lg"
            isDisabled={hasErrors}
            maxHeight="90vh"
        />
    );
}

import { WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { getLogoByDomainId } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";

export function DomainLogo({ whiteLabelDomain }: { whiteLabelDomain: WhiteLabelDomain }): JSX.Element | null {
    const [logo, setLogo] = useState<string | undefined>();
    useEffect(() => {
        void (async function getLogo() {
            if (whiteLabelDomain) {
                const domainLogo = await getLogoByDomainId(whiteLabelDomain.id);
                if (domainLogo) {
                    setLogo(domainLogo);
                }
            }
        })();
    }, [whiteLabelDomain]);

    if (!logo) {
        return null;
    }
    return <img src={logo} alt={whiteLabelDomain.fqdn} className="h-14" />;
}

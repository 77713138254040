import {
    Overwrite,
    SupportedLanguageCode,
    Survey,
    SURVEY_COLLECTION,
    SURVEY_VERSION_COLLECTION,
    SURVEY_VERSION_LANG_COLLECTION,
    SURVEY_VERSION_LANG_COMMENT_COLLECTION
} from "@vaultinum/vaultinum-api";
import {
    addDoc,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    DocumentData,
    DocumentSnapshot,
    FieldValue,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    QueryConstraint,
    serverTimestamp,
    surveyLangDoc,
    Timestamp,
    updateDoc,
    where,
    writeBatch
} from "@vaultinum/vaultinum-sdk";
import { chunk, isUndefined, pickBy } from "lodash";
import { nodeTypeToGroupKey } from "./surveyService";

const convertDocToSurveyComment = (doc: DocumentSnapshot<DocumentData>): Survey.Comment => {
    const data = doc.data() as Omit<Survey.Comment, "id" | "creationDate" | "resolutionDate" | "updatedDate" | "replies"> & {
        creationDate: Timestamp;
        resolutionDate: Timestamp;
        updatedDate: Timestamp;
        replies: Overwrite<Survey.Comment.Reply, { creationDate: Timestamp }>[];
    };
    return {
        ...data,
        id: doc.id,
        creationDate: data?.creationDate?.toDate(),
        updatedDate: data?.updatedDate?.toDate(),
        resolutionDate: data?.resolutionDate?.toDate(),
        replies: data?.replies.map(reply => ({
            ...reply,
            creationDate: reply.creationDate.toDate()
        }))
    };
};

const commentCollectionPath = (surveyKey: string | undefined, surveyVersion: number, lang: string): string => {
    return `${SURVEY_COLLECTION}/${surveyKey}/${SURVEY_VERSION_COLLECTION}/${surveyVersion}/${SURVEY_VERSION_LANG_COLLECTION}/${lang}/${SURVEY_VERSION_LANG_COMMENT_COLLECTION}`;
};

const commentDocPath = (comment: Survey.Comment): string => {
    return `${commentCollectionPath(comment.surveyKey, comment.surveyVersion, comment.lang)}/${comment.id}`;
};

async function getSurveyCommentByAuthorAndTarget(
    surveyKey: string,
    surveyVersion: number,
    lang: SupportedLanguageCode,
    authorUID: string,
    target: Survey.NodeTarget,
    includeResolved = false
) {
    const constraints: QueryConstraint[] = [
        where("authorUID", "==", authorUID),
        where("target.type", "==", target.type),
        where("target.nodeId", "==", target.nodeId),
        where("target.property", "==", target.property),
        where("isResolved", "==", includeResolved),
        ...(target.subNodeId ? [where("target.subNodeId", "==", target.subNodeId)] : [])
    ];
    const { docs } = await getDocs(query(collection(getFirestore(), commentCollectionPath(surveyKey, surveyVersion, lang)), ...constraints));
    if (docs.length !== 1) {
        return null;
    }
    return convertDocToSurveyComment(docs[0]);
}

export function addSurveyComment(comment: Omit<Survey.Comment, "id" | "creationDate" | "isResolved" | "resolvedBy" | "resolutionDate" | "replies">) {
    const surveyComment: Omit<Survey.Comment, "id" | "creationDate"> & { creationDate: FieldValue } = {
        ...comment,
        isResolved: false,
        replies: [],
        creationDate: serverTimestamp()
    };
    return addDoc(collection(getFirestore(), commentCollectionPath(comment.surveyKey, comment.surveyVersion, comment.lang)), surveyComment);
}

export async function addOrUpdateSurveyComment(
    comment: Omit<Survey.Comment, "id" | "creationDate" | "isResolved" | "resolvedBy" | "resolutionDate" | "replies">
) {
    const { surveyKey, surveyVersion, lang, authorUID, target } = comment;
    const existingComment = await getSurveyCommentByAuthorAndTarget(surveyKey, surveyVersion, lang, authorUID, target);
    if (existingComment) {
        const surveyComment: Omit<Survey.Comment, "id" | "creationDate"> = {
            ...existingComment,
            comment: `${existingComment.comment}\n\n----\n\n${comment.comment}`
        };
        return updateDoc(
            doc(getFirestore(), commentDocPath(existingComment)),
            pickBy(surveyComment, field => !isUndefined(field))
        );
    }
    return addSurveyComment(comment);
}

export function addSurveyCommentReply(comment: Survey.Comment, authorUID: string, reply: string) {
    const commentReply: Omit<Survey.Comment.Reply, "creationDate"> & { creationDate: FieldValue } = {
        authorUID,
        comment: reply,
        creationDate: Timestamp.now()
    };
    return updateDoc(doc(getFirestore(), commentDocPath(comment)), { replies: arrayUnion(commentReply) });
}

export function getSurveyVersionComments(surveyKey: string | undefined, version: number, lang: string, includeResolved: boolean): Promise<Survey.Comment[]>;
export function getSurveyVersionComments(
    surveyKey: string | undefined,
    version: number,
    lang: string,
    includeResolved: boolean,
    onUpdate: (comments: Survey.Comment[]) => void
): () => void;
export function getSurveyVersionComments(
    surveyKey: string | undefined,
    version: number,
    lang: string,
    includeResolved: boolean,
    onUpdate?: (comments: Survey.Comment[]) => void
): Promise<Survey.Comment[]> | (() => void) {
    if (includeResolved) {
        if (onUpdate) {
            return onSnapshot(collection(getFirestore(), commentCollectionPath(surveyKey, version, lang)), querySnapshot =>
                onUpdate(querySnapshot.docs.map(convertDocToSurveyComment))
            );
        }
        return getDocs(collection(getFirestore(), commentCollectionPath(surveyKey, version, lang))).then(response =>
            response.docs.map(convertDocToSurveyComment)
        );
    }
    const resolvedQuery = query(collection(getFirestore(), commentCollectionPath(surveyKey, version, lang)), where("isResolved", "==", false));
    if (onUpdate) {
        return onSnapshot(resolvedQuery, querySnapshot => onUpdate(querySnapshot.docs.map(convertDocToSurveyComment)));
    }
    return getDocs(resolvedQuery).then(response => response.docs.map(convertDocToSurveyComment));
}

export function resolveComment(comment: Survey.Comment, resolvedByUID: string) {
    return updateDoc(doc(getFirestore(), commentDocPath(comment)), {
        isResolved: true,
        resolvedByUID,
        resolutionDate: serverTimestamp()
    });
}

export function updateCommentSuggestion(comment: Survey.Comment, changeSuggestion: string) {
    return updateDoc(doc(getFirestore(), commentDocPath(comment)), {
        changeSuggestion,
        updatedDate: serverTimestamp()
    });
}

export function deleteSurveyComment(comment: Survey.Comment) {
    return deleteDoc(doc(getFirestore(), commentDocPath(comment)));
}

export async function resolveComments(comments: Survey.Comment[], userUID: string): Promise<void> {
    const batch = writeBatch(getFirestore());
    comments.forEach(comment => {
        batch.update(doc(getFirestore(), commentDocPath(comment)), {
            isResolved: true,
            resolvedByUID: userUID,
            resolutionDate: serverTimestamp()
        });
    });
    return batch.commit();
}

export async function applyCommentSuggestions(surveyLang: Survey.Lang, comments: Survey.Comment[], userUID: string) {
    // limit of batch is 500
    const chunkComments = chunk(comments, 500);
    const batchArray = chunkComments.map(element => {
        const batch = writeBatch(getFirestore());

        element.forEach(comment => {
            if (comment.changeSuggestion) {
                let nodeKey = `${nodeTypeToGroupKey(comment.target.type)}.${comment.target.nodeId}.${comment.target.property}`;
                if (comment.target.property === Survey.NodeTargetProperty.FIXES && comment.target.subNodeId) {
                    nodeKey = `${nodeKey}.${comment.target.subNodeId}`;
                }
                batch.update(surveyLangDoc(surveyLang.surveyKey, surveyLang.surveyVersion, surveyLang.lang), {
                    updatedByUID: userUID,
                    updatedDate: serverTimestamp(),
                    [nodeKey]: comment.changeSuggestion
                });
                batch.update(doc(getFirestore(), commentDocPath(comment)), {
                    isResolved: true,
                    resolvedByUID: userUID,
                    resolutionDate: serverTimestamp()
                });
            }
        });

        return batch;
    });

    for (const batch of batchArray) {
        await batch.commit();
    }
}

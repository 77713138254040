import { StaffUser, StaffUserRole, Survey } from "@vaultinum/vaultinum-api";
import { Alert, BaseLang, message, Modal, Select, Space, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { StaffUserName } from "../../../../components";
import { addSurveyAccess } from "../../../../services/surveyAccessService";

export default function AddSurveyAccess({
    isOpen,
    onClose,
    survey,
    staffUsers,
    surveyAccesses
}: {
    isOpen: boolean;
    onClose: () => void;
    survey: Survey;
    staffUsers: StaffUser[];
    surveyAccesses: Survey.Access[];
}) {
    const lang = useLang<BaseLang>();
    const [selectedStaffUserId, setSelectedStaffUserId] = useState<string>();
    const [working, setWorking] = useState(false);

    const reviewersStaffUsers = staffUsers
        .filter(({ roles }) => roles.includes(StaffUserRole.KYS1_REVIEWER) && !roles.includes(StaffUserRole.ADMIN))
        .filter(({ id }) => !surveyAccesses.map(({ staffUserId }) => staffUserId).includes(id));

    const onOk = async () => {
        setWorking(true);
        try {
            if (selectedStaffUserId) {
                await addSurveyAccess(survey.key, selectedStaffUserId)
                    .then(() => setSelectedStaffUserId(undefined))
                    .catch(e => message.error(e.message));
            }
            onClose();
        } catch (e) {
            void message.error(e.message);
        } finally {
            setWorking(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onOk}
            title="Add access to survey"
            okText="Add"
            isLoading={working}
            isDisabled={!selectedStaffUserId}
            lang={lang}
        >
            <Space direction="vertical">
                <Select.Search
                    placeholder="Select staff user"
                    className="mr-4 w-72"
                    // filterOption={(input, option) => option?.props.key.toLowerCase().includes(input.toLowerCase())}
                    value={selectedStaffUserId}
                    onChange={setSelectedStaffUserId}
                    options={reviewersStaffUsers.map(staffUser => ({
                        value: staffUser.id,
                        label: <StaffUserName staffUserId={staffUser.id} />
                    }))}
                />
                <Alert.Info message="Note: Users with ADMIN right are not listed since they have access by default." />
            </Space>
        </Modal>
    );
}

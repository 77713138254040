import { Account, ACCOUNT_COLLECTION, ACCOUNT_EVENT_COLLECTION } from "@vaultinum/vaultinum-api";
import { collection, collectionGroup, converter, getFirestore, onSnapshot, query } from "@vaultinum/vaultinum-sdk";

export function getEventsCount(onUpdate: (eventsCount: number) => void, accountId?: string): () => void {
    const eventsCollection = accountId
        ? collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_EVENT_COLLECTION)
        : collectionGroup(getFirestore(), ACCOUNT_EVENT_COLLECTION);
    return onSnapshot(query(eventsCollection), querySnapshot => {
        onUpdate(querySnapshot.size);
    });
}

export function getEvents(accountId: string | undefined, onUpdate: (events: Account.Event[]) => void): () => void {
    const eventsCollection = accountId
        ? collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_EVENT_COLLECTION).withConverter(converter<Account.Event>())
        : collectionGroup(getFirestore(), ACCOUNT_EVENT_COLLECTION).withConverter(converter<Account.Event>());
    return onSnapshot(eventsCollection, querySnapshot => {
        onUpdate(querySnapshot.docs.map(doc => doc.data()));
    });
}

import { WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { Link } from "react-router-dom";
import { DEFAULT_DOMAIN } from "../../../common/Global";

export function Domain({
    domainId,
    domains = [],
    redirectTo
}: {
    domainId: string;
    domains: WhiteLabelDomain[] | undefined;
    redirectTo?: (domainId: string) => string;
}): JSX.Element {
    const domain = domains.find(({ id }) => id === domainId);
    const formattedDomain = domain?.fqdn.replace(`.${DEFAULT_DOMAIN}`, "");

    if (!domain) {
        return <span className="italic text-grey-light">N/A</span>;
    }
    if (redirectTo) {
        return <Link to={redirectTo(domainId)} className="cursor-pointer truncate text-accent hover:underline" children={formattedDomain} />;
    }
    return <>{formattedDomain}</>;
}

import { ACCOUNT_COLLECTION, ACCOUNT_NOTES_COLLECTION, Account, Note } from "@vaultinum/vaultinum-api";
import {
    DocumentData,
    DocumentReference,
    DocumentSnapshot,
    FieldValue,
    QueryConstraint,
    addDoc,
    collection,
    doc,
    getFirestore,
    onSnapshot,
    query,
    serverTimestamp,
    updateDoc,
    where
} from "@vaultinum/vaultinum-sdk";

const docToNote = (snapshot: DocumentSnapshot): Account.EscrowNote => ({
    ...(snapshot.data() as Omit<Account.EscrowNote, "creationDate" | "lastUpdated">),
    id: snapshot.id,
    creationDate: snapshot.data()?.creationDate?.toDate(),
    lastUpdated: snapshot.data()?.lastUpdated?.toDate()
});

export function getEscrowActiveNotes(accountId: string | undefined, escrowId: string | undefined, onUpdate: (notes: Account.EscrowNote[]) => void): () => void {
    if (accountId) {
        let queries: QueryConstraint[] = [where("archived", "==", false)];
        if (escrowId) {
            queries = queries.concat(where("target.escrowId", "==", escrowId));
        }
        return onSnapshot(
            query(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_NOTES_COLLECTION), ...queries),
            querySnapshot => onUpdate(querySnapshot.docs.map(docToNote)),
            () => onUpdate([])
        );
    }
    return () => [];
}

export async function addNewEscrowNote(
    accountId: string,
    createdByUID: string,
    text: string,
    escrowId: string,
    visibility: Note.Visibility
): Promise<DocumentReference<DocumentData>> {
    const creationDate = serverTimestamp();
    const note: Omit<Account.EscrowNote, "id" | "creationDate" | "lastUpdated"> & { creationDate: FieldValue; lastUpdated: FieldValue } = {
        accountId,
        creationDate,
        lastUpdated: creationDate,
        createdByUID,
        updatedByUID: createdByUID,
        target: {
            type: Account.Note.TargetType.ESCROW,
            escrowId
        },
        visibility,
        text,
        archived: false
    };
    return addDoc(collection(getFirestore(), `${ACCOUNT_COLLECTION}/${accountId}/${ACCOUNT_NOTES_COLLECTION}`), note);
}

export async function updateEscrowNote(accountId: string, updatedByUID: string, text: string, noteId: string): Promise<void> {
    await updateDoc(doc(collection(getFirestore(), `${ACCOUNT_COLLECTION}/${accountId}/${ACCOUNT_NOTES_COLLECTION}`), noteId), {
        lastUpdated: serverTimestamp(),
        updatedByUID,
        text
    });
}

export async function archiveEscrowNote(accountId: string, updatedByUID: string, noteId: string): Promise<void> {
    await updateDoc(doc(collection(getFirestore(), `${ACCOUNT_COLLECTION}/${accountId}/${ACCOUNT_NOTES_COLLECTION}`), noteId), {
        lastUpdated: serverTimestamp(),
        updatedByUID,
        archived: true
    });
}

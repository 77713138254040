import { Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, Controller, Form, Input, Modal, Select, useForm, useLang, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useEffect, useMemo } from "react";
import { removeVariantValueFromSections } from "./variant.helper";

export const AddOrEditSurveyVariantModal = ({
    onClose,
    surveyVersion,
    setSurveyVersion,
    variantKey
}: {
    onClose: () => void;
    surveyVersion: Survey.Version;
    setSurveyVersion: (surveyVersion: Survey.Version) => void;
    variantKey?: string;
}): JSX.Element => {
    const lang = useLang<BaseLang>();
    const schema = yup.object({
        key: useRequiredString().test("unique-key", "A key with the same name already exists", function (value) {
            return value === variantKey || !surveyVersion.variants?.some(variant => variant.key === value);
        }),
        values: yup.array().of(useRequiredString()).required()
    });
    const isEditing = !!variantKey;

    const existingVariant = useMemo(() => surveyVersion.variants?.find(variant => variant.key === variantKey), [surveyVersion, variantKey]);
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors, isValid }
    } = useForm<{ key: string; values: string[]; defaultValue?: string }>({
        schema,
        defaultValues: {
            key: variantKey ?? "",
            values: existingVariant?.values ?? [],
            defaultValue: existingVariant?.defaultValue ?? ""
        }
    });
    const formValues = watch("values");
    const formDefaultValue = watch("defaultValue");

    useEffect(() => {
        if (formDefaultValue && !formValues?.includes(formDefaultValue)) {
            setValue("defaultValue", "");
        }
    }, [formValues, formDefaultValue, setValue]);

    const updateSurveyVariant = ({ key, values, defaultValue }: { key: string; values: string[]; defaultValue?: string }) => {
        const updatedVariant: Survey.Variant = {
            ...existingVariant,
            key,
            values
        };
        if (defaultValue) {
            updatedVariant.defaultValue = defaultValue;
        } else if (updatedVariant.defaultValue) {
            delete updatedVariant.defaultValue;
        }
        const valuesToRemove = (existingVariant?.values ?? []).filter(value => !values.includes(value));
        const updatedVersion: Survey.Version = {
            ...surveyVersion,
            sections: removeVariantValueFromSections(surveyVersion.sections, key, valuesToRemove),
            variants: [...(surveyVersion.variants ?? []).filter(variant => variant.key !== variantKey), updatedVariant]
        };

        setSurveyVersion(updatedVersion);
        onClose();
    };

    return (
        <Modal
            isOpen
            title={isEditing ? "Edit survey variant" : "Add survey variant"}
            onConfirm={handleSubmit(updateSurveyVariant)}
            okText={isEditing ? "Update" : "Add"}
            onClose={onClose}
            isDisabled={!isValid}
            lang={lang}
        >
            <Form>
                <Controller
                    name="key"
                    control={control}
                    render={({ field }) => (
                        <Input.Text {...field} label="Variant property key" disabled={isEditing} errorMessage={errors.key?.message} required />
                    )}
                />
                <Controller
                    name="values"
                    control={control}
                    render={({ field }) => (
                        <Select.Creatable {...field} className="w-full" placeholder="Values" label="Possible values" errorMessage={errors.values?.message} />
                    )}
                />
                <Controller
                    name="defaultValue"
                    control={control}
                    render={({ field }) => (
                        <Select.Basic
                            {...field}
                            label="Default value"
                            options={[{ value: "", label: "(None - Variant is optional)" }, ...(formValues ?? []).map(value => ({ value, label: value }))]}
                        />
                    )}
                />
            </Form>
        </Modal>
    );
};

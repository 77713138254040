/* eslint-disable @typescript-eslint/no-var-requires */
import { Question, Section, Survey } from "@vaultinum/vaultinum-api";
import cytoscape, { CytoscapeOptions } from "cytoscape";
import { useEffect, useRef } from "react";
import { getQuestionOptionIcons } from "./SurveyBuilderTools";
import "./SurveyGraphView.css";

const klay = require("cytoscape-klay");
const dagre = require("cytoscape-dagre");

cytoscape.use(klay);
cytoscape.use(dagre);

interface NodeElement {
    data: {
        id?: string;
        label?: string;
        source?: string;
        target?: string;
    };
    classes?: string;
}

const optionToElements = (option: Question.Option, surveyLang: Survey.Lang): NodeElement[] => {
    let nodes: NodeElement[] = [
        { data: { id: option.id, label: `${surveyLang.options[option.id]?.text} ${getQuestionOptionIcons(option)}` }, classes: "option" }
    ];
    if (option.questions) {
        nodes = [
            ...nodes,
            ...option.questions.map(question => questionToElements(question, surveyLang)).flat(),
            ...option.questions.map(question => ({ data: { source: option.id, target: question.id } }))
        ];
    }
    return nodes;
};

const questionToElements = (question: Question, surveyLang: Survey.Lang): NodeElement[] => {
    return [
        { data: { id: question.id, label: surveyLang.questions[question.id]?.text }, classes: "question" },
        ...question.options.map(option => optionToElements(option, surveyLang)).flat(),
        ...question.options.map(option => ({ data: { source: question.id, target: option.id } }))
    ];
};

const sectionToElements = (section: Section, surveyLang: Survey.Lang, indexPath: string[]): NodeElement[] => {
    const subSections = section.sections ?? [];
    const subQuestions = section.questions ?? [];
    return [
        { data: { id: section.id, label: `${indexPath.join("")} ${surveyLang.sections[section.id]?.title}` }, classes: "section" },
        ...subSections.map((subSection, i) => sectionToElements(subSection, surveyLang, [...indexPath, `${i + 1}`])).flat(),
        ...subSections.map(subSection => ({ data: { source: section.id, target: subSection.id } })),
        ...subQuestions.map(question => questionToElements(question, surveyLang)).flat(),
        ...subQuestions.map(question => ({ data: { source: section.id, target: question.id } }))
    ];
};

const surveyToElements = (surveyVersion: Survey.Version, surveyLang: Survey.Lang): NodeElement[] => {
    const sectionElements = surveyVersion.sections.map((section, i) => sectionToElements(section, surveyLang, [`${i + 1}.`])).flat();
    return [
        ...sectionElements
        // { data: { id: "root", label: survey.title }},
        // ...survey.sections.map(section => ({ data: { source: "root", target: section.id } })),
    ];
};

export default function SurveyGraphView({ surveyVersion, surveyLang }: { surveyVersion: Survey.Version; surveyLang: Survey.Lang }): JSX.Element {
    const graphElement = useRef(null);

    useEffect(() => {
        const elements = surveyToElements(surveyVersion, surveyLang);
        cytoscape({
            container: graphElement.current,
            elements,
            layout: {
                name: "dagre",
                rankDir: "LR"
            },
            wheelSensitivity: 0.1,
            style: [
                {
                    selector: "node",
                    style: {
                        label: "data(label)",
                        shape: "rectangle",
                        height: 15,
                        "text-halign": "center",
                        "text-valign": "center",
                        width: 100,
                        "padding-left": "10px"
                    }
                },
                {
                    selector: "edge",
                    style: {
                        "curve-style": "unbundled-bezier",
                        "target-arrow-shape": "triangle"
                    }
                },
                { selector: "edge[label]", style: { label: "data(label)", width: 3 } },
                { selector: ".section", style: { color: "#fff", "text-outline-color": "#888", "text-outline-width": 1, "font-size": 12 } },
                {
                    selector: ".question",
                    style: {
                        "background-color": "#7BE141",
                        "font-size": 8,
                        width: 150,
                        "text-max-width": "150px",
                        "text-wrap": "wrap"
                    }
                },
                {
                    selector: ".option",
                    style: {
                        shape: "round-rectangle",
                        "background-opacity": 0.1,
                        "font-size": 6,
                        width: "label",
                        height: 5,
                        "text-max-width": "80px",
                        "text-wrap": "wrap"
                    }
                }
            ]
        } as CytoscapeOptions);
    }, [surveyVersion, surveyLang]);

    return (
        <div className="survey-graph-view">
            <div className="graph-container" ref={graphElement} />
        </div>
    );
}

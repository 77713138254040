import { message } from "@vaultinum/vaultinum-sdk";

export async function handleAsyncActionWithMessages(action: () => Promise<string>) {
    try {
        const result = await action();
        void message.success(result);
    } catch (error) {
        if (error?.message === "Unauthorized") {
            return message.error(error?.message);
        } else {
            return message.error(`${error.response?.status || ""} ${error.response?.data?.error || error.response?.data || error}`);
        }
    }
    return null;
}

import { Survey } from "@vaultinum/vaultinum-api";
import { updateLang } from "../../../../services/surveyLangService";

export function applyCommentChangeSuggestion(comment: Survey.Comment, surveyLang: Survey.Lang, uid: string): void {
    if (!comment.changeSuggestion) {
        return;
    }
    const { type, nodeId, property, subNodeId } = comment.target;
    if (type === Survey.NodeType.SECTION) {
        if (property === Survey.NodeTargetProperty.TITLE || property === Survey.NodeTargetProperty.DESCRIPTION) {
            updateLang(surveyLang, uid).section(nodeId)[property](comment.changeSuggestion);
        }
    }
    if (type === Survey.NodeType.QUESTION) {
        if (property === Survey.NodeTargetProperty.TEXT || property === Survey.NodeTargetProperty.DESCRIPTION) {
            updateLang(surveyLang, uid).question(nodeId)[property](comment.changeSuggestion);
        }
    }
    if (type === Survey.NodeType.OPTION) {
        if (property === Survey.NodeTargetProperty.TEXT || property === Survey.NodeTargetProperty.WHY) {
            updateLang(surveyLang, uid).option(nodeId)[property](comment.changeSuggestion);
        }
        if (property === Survey.NodeTargetProperty.FIXES && subNodeId) {
            updateLang(surveyLang, uid).option(nodeId).fix(subNodeId, comment.changeSuggestion);
        }
    }
}

import { Referential, Survey } from "@vaultinum/vaultinum-api";
import { Column, Table } from "@vaultinum/vaultinum-sdk";
import { groupBy, sum } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AsyncActionButton } from "../../../components";
import { GradeScoreSummary, buildCategoriesColumn, buildSectionsColumn, calculateMedianScore } from "../../../helpers";
import { getIndustryStandardForSurvey, updateIndustryStandardForSurvey } from "../../../services/referentialService";

type SurveyReferential = {
    scope: string;
    categories: Record<string, unknown>;
    overall: {
        average: number;
        median: number;
        min: number;
        max: number;
    };
    sections: Record<string, unknown>;
};

const INDUSTRY_AVERAGE_COLUMNS: Column<SurveyReferential>[] = [
    {
        header: "Scope",
        accessorKey: "scope"
    },
    {
        header: "Average Score",
        accessorFn: row => row.overall.average || "-"
    },
    {
        header: "Median Score",
        accessorFn: row => row.overall.median || "-"
    },
    {
        header: "Lowest Score",
        accessorFn: row => row.overall.min || "-"
    },
    {
        header: "Highest Score",
        accessorFn: row => row.overall.max || "-"
    }
];

export function IndustryAverage({
    surveyKey,
    gradeScoreSummary,
    latestSurveyVersion,
    surveyLang
}: {
    surveyKey: string;
    gradeScoreSummary: GradeScoreSummary[];
    latestSurveyVersion: Survey.Version;
    surveyLang: Survey.Lang;
}): JSX.Element | null {
    const [referential, setReferential] = useState<Referential.SurveyStandard>();

    useEffect(() => getIndustryStandardForSurvey(surveyKey, setReferential), [surveyKey]);

    const getScoreDetails = useCallback((scores: number[] = [0]) => {
        const sortedScores = [...scores].sort((a, b) => a - b);
        return {
            average: Math.ceil(sum(sortedScores) / sortedScores.length),
            median: Math.ceil(calculateMedianScore(sortedScores)),
            min: sortedScores[0],
            max: sortedScores[scores.length - 1]
        };
    }, []);

    const getScoreDetailFromArray = useCallback(
        (data: { [id: string]: { label: string; score: number }[] }) => {
            return Object.keys(data).reduce((previous, id) => {
                return {
                    ...previous,
                    [id]: getScoreDetails(data[id].map(element => element.score))
                };
            }, {});
        },
        [getScoreDetails]
    );

    const surveyReferential = useMemo(() => {
        const tags = groupBy(
            gradeScoreSummary.flatMap(element => element.tags),
            "label"
        );
        const sections = groupBy(
            gradeScoreSummary.flatMap(element => element.sections),
            "id"
        );
        return [
            {
                scope: "Calculated",
                categories: getScoreDetailFromArray(tags),
                overall: getScoreDetails(gradeScoreSummary.map(element => element.score).sort((a, b) => a - b)),
                sections: getScoreDetailFromArray(sections)
            },
            {
                scope: "Published",
                categories: referential?.categories || {},
                overall: referential?.overall || getScoreDetails(),
                sections: referential?.sections || {}
            }
        ];
    }, [gradeScoreSummary, referential, getScoreDetailFromArray, getScoreDetails]);

    async function updateIndustryAverage(surveyKey: string, referential: Referential.SurveyStandard): Promise<string> {
        await updateIndustryStandardForSurvey(surveyKey, referential);
        return "Industry average was successfully updated";
    }

    if (surveyReferential) {
        return (
            <>
                <h3>Industry average</h3>
                <div className="flex justify-between">
                    <p className="font-bold">Survey record: {gradeScoreSummary.length}</p>
                    <AsyncActionButton onClick={async () => updateIndustryAverage(surveyKey, surveyReferential[0])}>Update Industry Average</AsyncActionButton>
                </div>
                <Table<SurveyReferential>
                    data={surveyReferential}
                    columns={[
                        ...INDUSTRY_AVERAGE_COLUMNS,
                        ...buildCategoriesColumn<SurveyReferential>(latestSurveyVersion, "Referential"),
                        ...buildSectionsColumn<SurveyReferential>(latestSurveyVersion.sections, surveyLang, "Referential")
                    ]}
                />
            </>
        );
    }

    return null;
}

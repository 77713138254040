import { Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, getSurveys, message, Modal, Select, useLang } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { copySurveyVersion } from "../../../services/surveyService";

export default function CloneSurveyModal({ isOpen, onClose, surveyVersion }: { isOpen: boolean; onClose: () => void; surveyVersion: Survey.Version | null }) {
    const lang = useLang<BaseLang>();
    const [working, setWorking] = useState(false);
    const [surveys, setSurveys] = useState<Survey[]>([]);
    const [selectedSurvey, setSelectedSurvey] = useState<string>();

    useEffect(() => getSurveys(setSurveys), []);

    const cloneSurveyVersion = async () => {
        if (surveyVersion && selectedSurvey) {
            setWorking(true);
            try {
                await copySurveyVersion(surveyVersion.surveyKey, surveyVersion.version, selectedSurvey);
                void message.success(`${surveyVersion.surveyKey} - ${surveyVersion.version} has been copied to ${selectedSurvey}`);
                onClose();
            } catch (e) {
                void message.error(e.message);
            } finally {
                setWorking(false);
            }
        }
    };

    const selectTarget = (value: string) => {
        setSelectedSurvey(value);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={cloneSurveyVersion}
            title={`Clone Survey Version - ${surveyVersion?.surveyKey} - ${surveyVersion?.version}`}
            okText="Clone"
            isLoading={working}
            lang={lang}
            isDisabled={!selectedSurvey}
        >
            <p className="mb-2 text-sm">Please, choose a target survey</p>
            <Select.Basic
                onChange={selectTarget}
                data-id="company-size-input"
                placeholder="Target survey"
                value={selectedSurvey}
                options={surveys.filter(survey => survey.key !== surveyVersion?.surveyKey).map(survey => ({ label: survey.name, value: survey.key }))}
            />
        </Modal>
    );
}

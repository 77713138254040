import { DOMAIN_COLLECTION, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { collection, convertDocWhiteLabelDomain, DataEventSource, getFirestore, onSnapshot } from "@vaultinum/vaultinum-sdk";

const domains = new DataEventSource<WhiteLabelDomain[]>(onUpdate => {
    return onSnapshot(collection(getFirestore(), DOMAIN_COLLECTION), querySnapshot => {
        const domains = querySnapshot.docs.map(convertDocWhiteLabelDomain);
        onUpdate(domains);
    });
});
domains.cacheClearDelay = 60 * 60 * 1000; // 1h

export function getDomains(onUpdate: (domains: WhiteLabelDomain[]) => void): () => void {
    return domains.addListener(onUpdate);
}

import { LANG_EN, SupportedLanguageCode, Survey } from "@vaultinum/vaultinum-api";
import {
    Alert,
    BaseLang,
    Breadcrumb,
    BreadcrumbItems,
    Button,
    FormIcon,
    getSurveyLangs,
    getSurveyVersion,
    HistoryIcon,
    ModalButton,
    openNotificationWithIcon,
    ProfileIcon,
    Segment,
    ShareAltIcon,
    SlidersIcon,
    Spin,
    Tabs,
    ThunderboltIcon,
    useLang,
    User,
    useUrlSearch
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { LangSelector, TranslateSurveyModalWithButton } from "../../../../components";
import { LANGS } from "../../../../constants";
import { ABSOLUTE_KYS1_PAGE, getKYS1SurveyLink } from "../../../../services/routing.service";
import { getSurveyVersionComments } from "../../../../services/surveyCommentService";
import { publishSurveyVersionLang, updateSurveyVersion } from "../../../../services/surveyService";
import AddOrRemoveSurveyLangModal from "./AddOrRemoveSurveyLangModal";
import SurveyTableView from "./editor/SurveyTableView";
import SurveyGeneralEditView from "./general/SurveyGeneralEditView";
import { getSurveyMissingTextIssues, getSurveyVersionIssues } from "./HealthCheckTools";
import { SurveyBuilderContext } from "./SurveyBuilderContext";
import SurveyGraphView from "./SurveyGraphView";
import SurveyHistoryView from "./SurveyHistoryView";
import SurveyRecommendationView from "./SurveyRecommendationView";
import SurveyScoringOverview from "./SurveyScoringOverview";

export default function SurveyBuilderView({ isAdmin, user }: { isAdmin: boolean; user: User }) {
    const lang = useLang<BaseLang>();
    const { surveyKey, version } = useParams<{ surveyKey: string; version: string }>();
    const [activeTab, setActiveTab] = useState("survey");
    const { tab } = useUrlSearch() as { tab?: string };

    const [surveyVersion, setSurveyVersion] = useState<Survey.Version | null>(null);
    const [surveyLangs, setSurveyLangs] = useState<Survey.Lang[]>([]);

    const [selectedSurveyLang, setSelectedSurveyLang] = useState<SupportedLanguageCode>(LANG_EN);
    const [suggestionMode, setSuggestionMode] = useState(false);
    const [showLangModal, setShowLangModal] = useState(false);
    const [comments, setComments] = useState<Survey.Comment[]>([]);
    const [isWorking, setIsWorking] = useState<boolean>(false);

    useEffect(() => {
        setActiveTab(tab || "survey");
    }, [tab]);

    useEffect(() => getSurveyVersion(surveyKey, Number(version), setSurveyVersion), [surveyKey, version]);
    useEffect(() => getSurveyLangs(surveyKey, Number(version), setSurveyLangs), [surveyKey, version]);
    useEffect(() => getSurveyVersionComments(surveyKey, Number(version), selectedSurveyLang, false, setComments), [surveyKey, version, selectedSurveyLang]);
    useEffect(() => {
        setIsWorking(surveyLangs.some(lang => lang.status === Survey.Status.WORKING));
    }, [surveyLangs]);

    const surveyLang = surveyLangs.find(surveyLang => surveyLang.lang === selectedSurveyLang);
    const defaultSurveyLang = surveyLangs.find(surveyLang => surveyLang.lang === LANG_EN);

    // Undefined check required for NodeTargetValue as result could be ""
    const validComments = surveyLang ? comments : [];

    async function doPublishSurveyLang() {
        if (surveyVersion) {
            setIsWorking(true);
            try {
                await publishSurveyVersionLang(surveyVersion, user.uid, selectedSurveyLang);
            } catch {
                openNotificationWithIcon({ type: "error", description: "Failed to publish lang" });
            } finally {
                setIsWorking(false);
            }
        }
        return undefined;
    }
    const saveSurveyVersion = (updatedSurveyVersion: Survey.Version) => updateSurveyVersion(updatedSurveyVersion, user.uid);
    const surveyIssues = useMemo(
        () => (surveyVersion && surveyLang ? getSurveyVersionIssues(surveyVersion, surveyLang) : undefined),
        [surveyVersion, surveyLang]
    );
    const noPreambleForVariant = !!surveyVersion?.variants?.length && !surveyVersion.preambleSectionId;
    const hasGlobalSurveyLangIssues = useMemo(
        () =>
            surveyVersion &&
            surveyLang &&
            (!surveyLang.survey.title ||
                !surveyLang.survey.description ||
                !surveyVersion.evaluationTags.every(tag => !!surveyLang.evaluationTag[tag]?.description && surveyLang.evaluationTag[tag]?.name)),
        [surveyVersion, surveyLang]
    );

    const surveyLangIssues = useMemo(
        () => (surveyVersion && surveyLang ? getSurveyMissingTextIssues(surveyVersion, surveyLang) : undefined),
        [surveyVersion, surveyLang]
    );
    const healthCheckIssues = useMemo(
        () => (surveyVersion && surveyLang ? getSurveyVersionIssues(surveyVersion, surveyLang) : undefined),
        [surveyVersion, surveyLang]
    );

    const selectedSurveyLangLabel = LANGS.find(lang => lang.code === selectedSurveyLang)?.name;

    let publishButtonLabel;
    if (surveyLangIssues?.length) {
        publishButtonLabel = "Some texts are missing, please fix them before publishing the survey";
    } else if (surveyIssues?.length) {
        publishButtonLabel = "Please fix the issues before publishing the survey";
    } else if (hasGlobalSurveyLangIssues) {
        publishButtonLabel = "Some texts are missing, please check the survey title, description and tags";
    }
    const canPublish =
        !surveyVersion?.publishedLangs?.includes(selectedSurveyLang) && !surveyLangIssues?.length && !hasGlobalSurveyLangIssues && !healthCheckIssues?.length;

    const onSelectedLangChange = (selectedLang: SupportedLanguageCode) => {
        setSelectedSurveyLang(selectedLang);
    };

    if (!surveyVersion || !surveyKey) {
        return <Spin />;
    }

    const breadcrumbItems: BreadcrumbItems = [
        {
            label: "Surveys",
            href: ABSOLUTE_KYS1_PAGE
        },
        {
            label: surveyKey,
            href: getKYS1SurveyLink(surveyKey)
        },
        {
            label: surveyVersion.version.toString()
        }
    ];

    return (
        <div className="h-full">
            {surveyVersion && (
                <AddOrRemoveSurveyLangModal
                    onClose={() => setShowLangModal(false)}
                    isOpen={showLangModal}
                    user={user}
                    surveyVersion={surveyVersion}
                    surveyLangs={surveyLangs}
                />
            )}
            <Breadcrumb items={breadcrumbItems} />
            <h2>
                Survey builder for <strong>{surveyVersion?.surveyKey}</strong> version <strong>{surveyVersion?.version}</strong>
                {surveyVersion?.publishedLangs?.includes(selectedSurveyLang) && <span className="pl-2">[{selectedSurveyLangLabel} PUBLISHED]</span>}
            </h2>
            {(!surveyVersion || surveyLangs.length === 0) && <Spin />}
            {surveyVersion && surveyLang && (
                <SurveyBuilderContext.Provider
                    value={{
                        surveyVersion,
                        surveyLang,
                        defaultSurveyLang,
                        user,
                        suggestionMode,
                        comments: validComments,
                        isWorking,
                        setIsWorking
                    }}
                >
                    <Tabs
                        className="h-full"
                        activeKey={activeTab}
                        destroyInactiveTabPane
                        onChange={setActiveTab}
                        tabBarExtraContent={
                            <div className="flex items-center gap-2">
                                <Segment.Root
                                    onValueChange={newValue => setSuggestionMode(newValue === "Suggesting")}
                                    value={suggestionMode ? "Suggesting" : "Editing"}
                                >
                                    <Segment.Item
                                        currentValue={{
                                            label: "Editing",
                                            value: "Editing"
                                        }}
                                        selectedValue={suggestionMode ? "" : "Editing"}
                                    />
                                    <Segment.Item
                                        currentValue={{
                                            label: "Suggesting",
                                            value: "Suggesting"
                                        }}
                                        selectedValue={suggestionMode ? "Suggesting" : ""}
                                    />
                                </Segment.Root>
                                <LangSelector
                                    selectedSurveyLang={selectedSurveyLang}
                                    working={isWorking}
                                    surveyLangs={surveyLangs}
                                    onSelected={onSelectedLangChange}
                                />
                                <Button onClick={() => setShowLangModal(true)} isLoading={false}>
                                    Add / Edit Translations
                                </Button>
                                {surveyVersion && surveyLangs.length && (
                                    <TranslateSurveyModalWithButton surveyVersion={surveyVersion} surveyLangs={surveyLangs} sourceLang={selectedSurveyLang} />
                                )}
                                {isAdmin && (
                                    <ModalButton
                                        isDisabled={!canPublish}
                                        title={`Publish survey (${selectedSurveyLangLabel} version)${surveyIssues?.length ? " with issues" : ""}?`}
                                        children={
                                            <div className="space-y-4">
                                                <p>
                                                    This will publish and set as default the current version. Once published, only typo related changes can be
                                                    made.
                                                </p>
                                                {publishButtonLabel && <p>{publishButtonLabel}</p>}
                                                {!!surveyIssues?.length && (
                                                    <Alert.Warning message="You have issues that you won't be able to fix after the publish" />
                                                )}
                                                {noPreambleForVariant && <Alert.Danger message="A variant is defined but there is no preamble section" />}
                                            </div>
                                        }
                                        onConfirm={doPublishSurveyLang}
                                        buttonProps={{
                                            children: surveyVersion?.publishedLangs?.includes(selectedSurveyLang)
                                                ? `${selectedSurveyLangLabel} version is published`
                                                : `Publish ${selectedSurveyLangLabel} version`,
                                            isDisabled: !canPublish || isWorking,
                                            isLoading: false
                                        }}
                                        lang={lang}
                                    />
                                )}
                            </div>
                        }
                    >
                        <Tabs.TabPane
                            key="survey"
                            tab={
                                <>
                                    <ProfileIcon {...(activeTab === "survey" && { color: "blue" })} /> Survey
                                </>
                            }
                        >
                            <SurveyGeneralEditView surveyVersion={surveyVersion} setSurveyVersion={saveSurveyVersion} surveyLang={surveyLang} user={user} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="table"
                            tab={
                                <>
                                    <FormIcon {...(activeTab === "table" && { color: "blue" })} /> Editor
                                </>
                            }
                        >
                            <SurveyTableView surveyVersion={surveyVersion} setSurveyVersion={saveSurveyVersion} surveyLangs={surveyLangs} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="recommendations"
                            tab={
                                <>
                                    <ThunderboltIcon {...(activeTab === "recommendations" && { color: "blue" })} /> Fix & recommendations
                                </>
                            }
                        >
                            <SurveyRecommendationView surveyVersion={surveyVersion} surveyLang={surveyLang} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="score"
                            tab={
                                <>
                                    <SlidersIcon {...(activeTab === "score" && { color: "blue" })} /> Score Overview
                                </>
                            }
                        >
                            <SurveyScoringOverview surveyVersion={surveyVersion} surveyLang={surveyLang} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="graph"
                            tab={
                                <>
                                    <ShareAltIcon {...(activeTab === "graph" && { color: "blue" })} /> Graph View
                                </>
                            }
                        >
                            <SurveyGraphView surveyVersion={surveyVersion} surveyLang={surveyLang} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="history"
                            tab={
                                <>
                                    <HistoryIcon {...(activeTab === "history" && { color: "blue" })} /> History
                                </>
                            }
                        >
                            <SurveyHistoryView surveyVersion={surveyVersion} />
                        </Tabs.TabPane>
                    </Tabs>
                </SurveyBuilderContext.Provider>
            )}
        </div>
    );
}

import { Claims, StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { Content, User, en, getAuth, getLangContext } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import HeaderBar from "./HeaderBar";
import AppRouter from "./Router";
import { getStaffUser } from "./services/staffUserService";

export default function App() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<User | null>();
    const [roles, setRoles] = useState<StaffUserRole[]>([]);
    const [staffUser, setStaffUser] = useState<StaffUser | null>(null);

    // Even if there is no language files, we have to use this context to use the languages into the SDK
    const LangContext = getLangContext();

    useEffect(() => {
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                const tokenResult = await user.getIdTokenResult(true);
                const claims = tokenResult.claims as Claims;
                if (claims.staffRoles?.length) {
                    setRoles(claims.staffRoles);
                }
            }
            setUser(user);
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        if (user) {
            return getStaffUser(user.uid, setStaffUser);
        }
        setStaffUser(null);
        return () => {};
    }, [user]);

    return (
        <BrowserRouter>
            <LangContext.Provider value={en}>
                <div className="flex h-full flex-col">
                    <HeaderBar user={user} roles={roles} staffUser={staffUser} loading={loading} />
                    <Content className="flex-1 overflow-auto">
                        {loading && <div>Loading..</div>}
                        {!loading && <AppRouter user={user} roles={roles} staffUser={staffUser} />}
                    </Content>
                </div>
            </LangContext.Provider>
        </BrowserRouter>
    );
}

import { Question, Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, List, ModalHelper, RowCards, Tag } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";

const OptionAttributeSelector = ({
    option,
    setOption,
    surveyVersion,
    readOnly
}: {
    option: Question.Option;
    setOption: (option: Question.Option) => void;
    surveyVersion: Survey.Version;
    readOnly: boolean;
}) => {
    const [updatedOption, setUpdatedOption] = useState(option);
    const updateOptionAttributes = (attributeKey: string, value?: string) => {
        const newOption = {
            ...updatedOption,
            attributes: {
                ...(updatedOption.attributes || {}),
                [attributeKey]: value || ""
            }
        };
        if (!value) {
            delete newOption.attributes[attributeKey];
        }
        setUpdatedOption(newOption);
        setOption(newOption);
    };
    return (
        <>
            <p>Select that variant attributes for this option</p>
            {surveyVersion.variants?.map(variant => {
                const value = updatedOption.attributes?.[variant.key] || "";
                let options = variant.values.map(value => ({ value, label: variant.defaultValue === value ? `${value} (default)` : value }));
                if (!variant.defaultValue) {
                    options = [{ value: "", label: "[any]" }, ...options];
                } else {
                    options = [...options, { value: "", label: "[none]" }];
                }
                return (
                    <div key={variant.key} className="mt-2 space-y-2 border p-2">
                        <Tag message={variant.key} />
                        <List
                            list={options}
                            render={item => (
                                <RowCards.WithRadio
                                    children={item.label}
                                    radio={{
                                        id: item.value,
                                        selectedId: value,
                                        onValueChange: (newValue: string) => updateOptionAttributes(variant.key, newValue)
                                    }}
                                    isDisabled={readOnly}
                                />
                            )}
                        />
                    </div>
                );
            })}
        </>
    );
};

const openOptionAttributeModal = (
    lang: BaseLang,
    option: Question.Option,
    surveyVersion: Survey.Version,
    setOption: (option: Question.Option) => void,
    readOnly: boolean
) => {
    ModalHelper.Modal({
        title: "Set option attribute",
        children: <OptionAttributeSelector option={option} surveyVersion={surveyVersion} setOption={setOption} readOnly={readOnly} />,
        okText: "Close",
        lang
    });
};

export default openOptionAttributeModal;

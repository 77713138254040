import { Grade, Referential, Section, Survey } from "@vaultinum/vaultinum-api";
import { Column } from "@vaultinum/vaultinum-sdk";

export type GradeScoreSummary = {
    accountId: string;
    recordCreationDate: Date;
    numberOfDaySinceCompletion: number;
    companyName: string;
    industry: string;
    version: number;
    score: number;
    tags: {
        label: string;
        score: number;
    }[];
    sections: {
        id: string;
        label: string;
        score: number;
    }[];
    grade?: Grade | null;
    size?: string;
    country?: string;
    productName?: string;
};

export function calculateMedianScore(sortedScores: number[]): number {
    const middleScore = Math.floor(sortedScores.length / 2);
    if (sortedScores.length % 2 === 0) {
        return (sortedScores[middleScore - 1] + sortedScores[middleScore]) / 2;
    }
    return sortedScores[middleScore];
}

export function buildSectionsColumn<T extends object>(sections: Section[], surveyLang: Survey.Lang, type: "Summary" | "Referential" = "Summary"): Column<T>[] {
    return [
        {
            header: "Sections",
            id: "sections",
            columns: sections.map(section => {
                const title = surveyLang?.sections[section.id]?.title;
                return {
                    header: title,
                    id: section.id,
                    ...(type === "Referential"
                        ? {
                              accessorFn: (row: Referential.SurveyStandard) => row.sections[section.id]?.average || 0
                          }
                        : {
                              accessorFn: (row: GradeScoreSummary) => {
                                  const foundSection = row.sections.find(element => element.id === section.id);
                                  return foundSection?.score || "-";
                              }
                          })
                };
            })
        }
    ];
}

export function buildCategoriesColumn<T extends object>(surveyVersion: Survey.Version, type: "Summary" | "Referential" = "Summary"): Column<T>[] {
    return [
        {
            header: "Tags",
            columns: surveyVersion.evaluationTags.map(tag => {
                return {
                    header: tag,
                    id: tag,
                    ...(type === "Referential"
                        ? {
                              accessorFn: (row: Referential.SurveyStandard) => row.categories[tag]?.average || 0
                          }
                        : {
                              accessorFn: (row: GradeScoreSummary) => {
                                  const foundTag = row.tags.find(element => element.label === tag);
                                  return foundTag?.score || "-";
                              }
                          })
                };
            })
        }
    ];
}

import { USER_PROFILE_COLLECTION, UserProfile } from "@vaultinum/vaultinum-api";
import { DocumentData, DocumentSnapshot, collection, doc, getDocs, getFirestore, onSnapshot, query, where } from "@vaultinum/vaultinum-sdk";

export function getUserProfile(uid: string | undefined, onUpdate: (userProfile: UserProfile | null) => void): () => void {
    if (!uid) {
        onUpdate(null);
        return () => {};
    } else {
        return onSnapshot(doc(collection(getFirestore(), USER_PROFILE_COLLECTION), uid), querySnapshot => onUpdate(convertDocToUserProfile(querySnapshot)));
    }
}

export function getUserProfiles(onUpdate: (userProfiles: UserProfile[]) => void): () => void {
    return onSnapshot(collection(getFirestore(), USER_PROFILE_COLLECTION), snapshot => {
        const userProfiles = snapshot.docs.map(convertDocToUserProfile).filter(userProfile => !!userProfile.creationDate);
        onUpdate(userProfiles);
    });
}

const convertDocToUserProfile = (docSnapshot: DocumentSnapshot<DocumentData>): UserProfile => ({
    ...(docSnapshot.data() as Omit<UserProfile, "id">),
    id: docSnapshot.id,
    creationDate: docSnapshot.data()?.creationDate?.toDate(),
    birthDate: docSnapshot.data()?.birthDate?.toDate()
});

export async function getUserProfileByEmail(email: string): Promise<UserProfile | null> {
    const userProfileDocs = await getDocs(query(collection(getFirestore(), USER_PROFILE_COLLECTION), where("email", "==", email)));
    if (userProfileDocs.docs.length === 1) {
        return convertDocToUserProfile(userProfileDocs.docs[0]);
    }
    return null;
}

import { Account, LANG_EN } from "@vaultinum/vaultinum-api";
import { BitbucketIcon, Column, formatDateTime, GithubIcon, GitlabIcon, Tag } from "@vaultinum/vaultinum-sdk";
import { TableLayout } from "../../../components";

function gitProviderToIcon(provider: Account.GitConnectionProvider) {
    switch (provider) {
        case Account.GitConnectionProvider.GITHUB:
            return <Tag icon={GithubIcon} message={provider} />;
        case Account.GitConnectionProvider.GITLAB:
            return <Tag icon={GitlabIcon} message={provider} />;
        case Account.GitConnectionProvider.BITBUCKET:
            return <Tag icon={BitbucketIcon} message={provider} />;
        default:
            return <Tag message={provider} />;
    }
}

const COLUMNS: Column<Account.GitConnection>[] = [
    {
        header: "Installation date",
        accessorKey: "installationDate",
        cell: cell => formatDateTime(cell.getValue<Date>(), LANG_EN)
    },
    {
        header: "Login",
        accessorKey: "accountLogin"
    },
    {
        header: "Installation Id",
        accessorKey: "installationId"
    },
    {
        header: "Provider",
        accessorKey: "provider",
        cell: cell => {
            const provider = cell.getValue<Account.GitConnectionProvider>();
            return gitProviderToIcon(provider);
        }
    },
    {
        header: "Last update date",
        accessorKey: "lastUpdateDate",
        cell: cell => formatDateTime(cell.getValue<Date>(), LANG_EN)
    },
    {
        header: "Last connection date",
        accessorKey: "lastConnectionDate",
        cell: cell => {
            const lastConnectionDate = cell.getValue<Date>();
            return lastConnectionDate ? formatDateTime(lastConnectionDate, LANG_EN) : "-";
        }
    }
];

export default function GitConnections({ gitConnections }: { gitConnections: Account.GitConnection[] | undefined }): JSX.Element {
    return <TableLayout<Account.GitConnection> title="Git connections" items={gitConnections} columns={COLUMNS} />;
}
